import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { useNavigate } from "react-router-dom";
import uploadImg from "../../assets/images/upload-image.svg";
import editImg from "../../assets/images/edit.svg";
import Select from "react-select";
import deleteImg from "../../assets/images/deleteicon.png";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { notify } from "../../features/notification/notify";
import { useDispatch, useSelector } from "react-redux";
import {
  addUpdateItem,
  getCustomer,
  getItemCommonData,
  getSoCommonData,
} from "../../features/soPo/action";
import { resetSoPo } from "../../features/soPo/slice";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { transformArrayOption } from "./soDataOption";
import { getUserByRoleName } from "../../features/userDetail/action";
import { resetUserDetail } from "../../features/userDetail/slice";
import { resetCommon } from "../../features/common/slice";
import { getSellingAccountSubTypeId } from "../../features/soPo/action";
import { getPurchaseAccountSubTypeId } from "../../features/soPo/action";
import { createBuyerIfNotExist } from "../../features/soPo/action";
import { createManufacturerIfNotExist } from "../../features/soPo/action";
import {
  getCategory,
  getSubCategoryByCategoryId,
} from "../../features/common/action";
import crossImage from "../../assets/images/icons8-close-30.png";
export const AddUpdateItem = (props) => {
  const {
    open,
    setOpen,
    setManufacturerOptions,
    manufacturerOptions,
    categoryOption,
    setCategoryOption,
    customerOption,
    setCustomerOption,
    setCreatedItem
  } = props;
  const dispatch = useDispatch();
  const [allCustomer, setAllCustomer] = useState([]);
  const [inventoryAccountDisabled, setInventoryAccountDisable] = useState(true);
  const [attachImage, setAttachImage] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [dimensionOptions, setDimensionOptions] = useState([]);
  const [taxPreferenceOptions, setTaxPreferenceOptions] = useState([]);
  const [weightOptions, setWeightOptions] = useState([]);
  const [subCategoryOption, setSubCategoryOption] = useState([]);
  const [preferredVendorOption, setPreferredVendorOption] = useState([]);
  const [sellingAccountOptions, setSellingAccountOptions] = useState([]);
  const [sellingSubAccountOptions, setSellingSubAccountOptions] = useState([]);
  const [purchaseAccountOptions, setPurchaseAccountOptions] = useState([]);
  const [purchaseSubAccountOptions, setPurchaseSubAccountOptions] = useState(
    []
  );
  const btnRef = useRef(null);
  const loggedInUser = localStorage.getItem("userId");

  const itemBaseDetail = {
    type: "Goods",
    name: "",
    sku: null,
    unit: null,
    hsnCode: null,
    taxPreference: "",
    dimensions: "cm",
    dimensionsValue: null,
    weight: "Kg",
    weightValue: null,
    manufacturer: null,
    brand: null,
    UPC: null,
    MPN: null,
    EAN: null,
    ISBN: null,
    MRP: null,
    itemType: null,
    itemSubType: null,
    sellingPrice: "",
    sellingAccount: "",
    sellingAccountSubType: "",
    sellingDescription: null,
    costPrice: "",
    purchaseAccount: "",
    purchaseAccountSubType: "",
    purchaseDescription: null,
    preferredVendor: null,
    inventoryAccount: null,
    digitalService: null,
    sac: null,
    intraStateTax: "5",
    interStateTax: "15",
    openingStock: null,
    openingStockRate: null,
    reorderPoint: null,
    advancedInventoryTracking: "None",
    loggedUser: loggedInUser,
    trackInventory:false
  }

  const [itemDetail, setItemDetail] = useState(itemBaseDetail);
  const soPoResponse = useSelector((state) => state.soPo);
  const userResponse = useSelector((state) => state.userDetail);
  const commonResponse = useSelector((state) => state.common);

  useEffect(() => {
    //dispatch(getCustomer());
    dispatch(getItemCommonData());
    dispatch(getSoCommonData());
    dispatch(getCategory());
  }, []);

  const itemSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    unit: Yup.string().required("Required"),

    taxPreference: Yup.string().required("Required"),
    sellingPrice: Yup.number().required("Required"),
    sellingAccount: Yup.string().required("Required"),
    //itemType: Yup.string().nullable().required("Required"),
    costPrice: Yup.number().required("Required"),
    purchaseAccount: Yup.string().required("Required"),

    inventoryAccount:
      inventoryAccountDisabled == false
        ? Yup.string().required("Required")
        : "",
  });

  const handleEditButton = (e) => {
    let intraInfo = document.getElementById("intraStateTax");
    let interInfo = document.getElementById("interStateTax");
    if (intraInfo.disabled == true) intraInfo.disabled = false;

    if (interInfo.disabled == true) interInfo.disabled = false;
    //setOpen(true);
  };

  useEffect(() => {
    if (soPoResponse?.actionName == "getCustomer") {
      let arr = [];
      if (soPoResponse.customers.length > 0) {
        soPoResponse.customers.map((item) => {
          let list = {
            name: "brand",
            value: item.contact_name,
            label: item.contact_name,
          };
          arr.push(list);
        });
      }
      setAllCustomer(arr.sort((a, b) => a.label.localeCompare(b.label)));
    } else if (soPoResponse.success == true && soPoResponse?.actionName == "addUpdateItem") {
      notify(soPoResponse.message, "success");
      setItemDetail(itemBaseDetail);
      dispatch(getCustomer());
      setCreatedItem(soPoResponse.createdItem)
      setOpen(false)
      
    }
    if (
      soPoResponse.actionName === "getItemCommonData" &&
      soPoResponse.success
    ) {
      let itemCommonData = soPoResponse.itemCommonData;
      setUnitOptions(transformArrayOption(itemCommonData.units, "id", "name"));
      setDimensionOptions(
        transformArrayOption(itemCommonData.dimensions, "id", "name")
      );
      setTaxPreferenceOptions(
        transformArrayOption(itemCommonData.taxPreferences, "id", "name")
      );
      setWeightOptions(
        transformArrayOption(itemCommonData.weights, "id", "name")
      );
      setSellingAccountOptions(
        transformArrayOption(itemCommonData.salesAccountTypes, "id", "name")
      );
      setPurchaseAccountOptions(
        transformArrayOption(itemCommonData.purchaseAccountTypes, "id", "name")
      );
    }
    if (soPoResponse.actionName === "getSoCommonData" && soPoResponse.success) {
      let soCommonData = soPoResponse.soCommonData;
      //setManufacturerOptions(transformArrayOption(soCommonData.branches, 'id', 'name'))
      setPreferredVendorOption(
        transformArrayOption(soCommonData.branches, "id", "name")
      );
    }

    if (
      soPoResponse.actionName === "getSellingAccountSubTypeId" &&
      soPoResponse.success
    ) {
      setSellingSubAccountOptions(
        transformArrayOption(soPoResponse.sellingAccountSubType, "id", "name")
      );
    }

    if (
      soPoResponse.actionName === "getPurchaseAccountSubTypeId" &&
      soPoResponse.success
    ) {
      setPurchaseSubAccountOptions(
        transformArrayOption(soPoResponse.purchaseAccountSubType, "id", "name")
      );
    }

    if (
        soPoResponse.actionName === "createBuyerIfNotExist" && soPoResponse.buyerId  &&  soPoResponse.success
      ) {
        setItemDetail({
           ...itemDetail,
           brand:soPoResponse.buyerId 
        });
      }

      if (
        soPoResponse.actionName === "createManufacturerIfNotExist" && soPoResponse.supplierId  &&  soPoResponse.success
      ) {
        setItemDetail({
           ...itemDetail,
           manufacturer:soPoResponse.supplierId 
        });
      }

    dispatch(resetSoPo());
  }, [soPoResponse]);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/sample-list");
  };

  const handleBrandSelect = (e, Name) => {
    if (e != null) {
        let id=e.value
        let name=e.label
        dispatch(createBuyerIfNotExist({name,id}));
    }
     else {
      setItemDetail({
        ...itemDetail,
        [Name]: "",
      });
    }
  };

  const handlemanufacturerSelect = (e, Name) => {
    if (e != null) {
        let id=e.value
        let name=e.label
        dispatch(createManufacturerIfNotExist({name,id}));
    }
     else {
      setItemDetail({
        ...itemDetail,
        [Name]: "",
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.name == "weight" || e.target.name == "dimensions") {
      setItemDetail({
        ...itemDetail,
        [e.target.name]: e.target.innerText,
      });
    } else {
      setItemDetail({
        ...itemDetail,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOption = (e, name) => {
    if (e != null) {
      setItemDetail({
        ...itemDetail,
        [name]: e.value,
      });
    } else {
      setItemDetail({
        ...itemDetail,
        [name]: "",
      });
    }
  };

  useEffect(() => {
    itemDetail.itemType && dispatch(getSubCategoryByCategoryId(itemDetail.itemType));
  }, [itemDetail.itemType]);

  useEffect(() => {
    itemDetail.sellingAccount && dispatch(getSellingAccountSubTypeId(itemDetail.sellingAccount));
  }, [itemDetail.sellingAccount]);

  useEffect(() => {
    itemDetail.purchaseAccount && dispatch(getPurchaseAccountSubTypeId(itemDetail.purchaseAccount));
  }, [itemDetail.purchaseAccount]);

  useEffect(() => {
    if (
      commonResponse.actionName === "getSubCategory" &&
      commonResponse.success
    ) {
      //setSubCat(commonResponse.subCategory);
      let subCatArr = [];
      if (commonResponse.subCategory.length > 0)
        commonResponse.subCategory.map((item) => {
          let list = {
            value: item.id,
            label: item.subCategoryName,
          };
          subCatArr.push(list);
        });
      setSubCategoryOption(
        subCatArr.sort((a, b) => a.label.localeCompare(b.label))
      );
      dispatch(resetCommon());
    }
  }, [commonResponse]);

  const handleClick = (e) => {
    setItemDetail({
      ...itemDetail,
      [e.target.name]: e.target.value,
    });
  };

  console.log("addItemDetail", itemDetail);

  const inventoryAccount = [
    { name: "inventoryAccount", value: "Inventory", label: "Inventory" },
    {
      name: "inventoryAccount",
      value: "Inventory Asset",
      label: "Inventory Asset",
    },
  ];

  const handleTrackInventory = (e) => {
      setItemDetail({
        ...itemDetail,
        [e.target.name]:e.target.checked
      })
    if (inventoryAccountDisabled == false) setInventoryAccountDisable(true);
    else setInventoryAccountDisable(false);
  };

  const handleCheckBox = (e) => {
    const { id, name, value, type, checked } = e.target;
    if (type == "radio") {
      setItemDetail({
        ...itemDetail,
        [name]: value,
      });
    } else {
      setItemDetail({
        ...itemDetail,
        [name]: checked,
      });
    }
  };

  const onCloseModal = () => setOpen(false);
  //console.log("manufacturerOptions", weightOptions);

  const removeFile = (e, index, type) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure want to delete this file?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleDelete(e, index, type);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDelete = (e, index, type) => {
    if (type == "image") {
      const temp = [...attachImage];
      temp.splice(index, 1);
      setAttachImage(temp);
    }
  };
  const getFile = (image, fileExtension) => {
    let newImageName, index;
    const blob = image.slice(0, image.size);
    if (
      fileExtension == "jpeg" ||
      fileExtension == "jpg" ||
      fileExtension == "png"
    ) {
      if (image?.name.indexOf("AM") != -1) index = image?.name.indexOf("AM");
      else if (image?.name.indexOf("PM") != -1)
        index = image?.name.indexOf("PM");

      if (index) {
        newImageName =
          image?.name.slice(0, index - 1) + "_" + image?.name.slice(index);
      } else newImageName = image?.name;
    } else newImageName = image?.name;
    const file = new File([blob], newImageName);
    return { file, newImageName };
  };
  const preventNegativeValues = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleImageAttach = (e, type) => {
    if (e.target.files.length > 15) {
      notify("Only 15 files can be added at a time", "error");
      e.target.value = "";
      return;
    }
    Array.from(e.target.files).forEach((item) => {
      const fileExtension = item?.name.split(".").pop().toLowerCase();
      var acceptedFormats = ["jpeg", "jpg", "png"];
      if (!acceptedFormats.includes(fileExtension)) {
        notify("File type not supported", "error");
        e.target.value = "";
        return;
      } else {
        const { file, newImageName } = getFile(item, fileExtension);
        if (type === "image" && attachImage.length < 15) {
          setAttachImage((attachImage) => [
            ...attachImage,
            { id: 0, file: file, fileName: newImageName, isDeleted: false },
          ]);
        } else {
          notify("Only 15 files can be added at a time", "info");
        }
      }
    });
  };

  const handleSubmit = (e) => {
    let formData = new FormData();
    const fileNames = [];

    attachImage.map((fileSet) => {
      formData.append("upload_file", fileSet.file);
      fileNames.push({
        id: fileSet.id,
        fileType: "attach image",
        fileName: fileSet.fileName,
        isDeleted: false,
      });
    });
    formData.append("content", JSON.stringify(itemDetail));
    formData.append("fileList", JSON.stringify(fileNames));
    dispatch(addUpdateItem(formData));
  };

  return (
    <Modal show={open} className="custom-modal" centered size="xl" ref={btnRef}>
      <div className="body-wrap">
        <Formik
          initialValues={itemDetail}
          validationSchema={itemSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ errors, touched, isValid, handleBlur }) => (
            <>
              <Form>
                <Modal.Header style={{ height: "17px" }}>
                  <div className="top-heading">
                    <div className="d-flex">
                      <a
                        className="back"
                        onClick={(e) => {
                          setOpen(false);
                        }}
                      >
                        &#8249;
                      </a>
                      <h2 style={{ marginTop: "5px" }}>Add Item</h2>
                    </div>
                    <div className="close-btn position-relative">
                        <a  onClick={(e) => {
                          setOpen(false);
                        }}><img src={crossImage}></img></a>
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="add-user-wrap">
                    <div className="mb-3">
                      <label className="form-label">
                        <b>Item Details</b>
                        <span className="required-text"></span>
                      </label>
                    </div>

                    <div className="row">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6 addItem-Type">
                            <label className="form-label">Type</label>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                defaultValue="Goods"
                                onClick={handleCheckBox}
                                id="Goods"
                                defaultChecked={itemDetail.type == "Goods"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                              >
                                Goods
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="type"
                                onClick={handleCheckBox}
                                defaultValue="Service"
                                id="Service"
                                defaultChecked={itemDetail.type == "Service"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault2"
                              >
                                Service
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Name
                                <span className="star-color"> *</span>
                              </label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.name && touched.name ? (
                                <div className="invalid-feedback d-block">
                                  {errors.name}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">SKU</label>
                              <input
                                type="text"
                                name="sku"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                //onKeyDown={preventNegativeValues}
                              />
                              {errors.sku && touched.sku ? (
                                <div className="invalid-feedback d-block">
                                  {errors.sku}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Unit<span className="star-color"> *</span>
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="unit"
                                placeholder="-- Select --"
                                //value={itemDetail.unit}
                                onChange={(e) => handleOption(e, "unit")}
                                options={unitOptions}
                                isClearable="true"
                                isSearchable="true"
                              />
                              {errors.unit && touched.unit ? (
                                <div className="invalid-feedback d-block">
                                  {errors.unit}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {itemDetail.type == "Goods" && (
                            <div className="col-xl-6 col-lg-6 col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">HSN Code</label>
                                <input
                                  type="number"
                                  name="hsnCode"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  // onKeyDown={preventNegativeValues}
                                />
                                {errors.hsnCode && touched.hsnCode ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.hsnCode}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}

                          {itemDetail.type == "Service" && (
                            <div className="col-xl-6 col-lg-6 col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  It is a digital service
                                </label>
                                <div className="form-check form-switch item-form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="digitalService"
                                    onClick={handleCheckBox}
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                  />
                                </div>
                                {errors.digitalService &&
                                touched.digitalService ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.digitalService}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Tax Preference
                                <span className="star-color"> *</span>
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="taxPreference"
                                //value={itemDetail.taxPreference}
                                onChange={(e) =>
                                  handleOption(e, "taxPreference")
                                }
                                options={taxPreferenceOptions}
                                placeholder="-- Taxable --"
                                isClearable="true"
                              />
                              {errors.taxPreference && touched.taxPreference ? (
                                <div className="invalid-feedback d-block">
                                  {errors.taxPreference}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Dimensions</label>
                              <div>
                                <div className="input-group mb-3">
                                  <input
                                    name="dimensionsValue"
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control"
                                    aria-label="Text input with dropdown button"
                                  />

                                  <button
                                    className="btn btn-outline-secondary dropdown-toggle"
                                    style={{ zIndex: "0" }}
                                    name="dimensions"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                  >
                                    {itemDetail.dimensions}
                                  </button>

                                  <ul className="dropdown-menu dropdown-menu-end">
                                    {dimensionOptions.map((item, i) =>
                                      i > 0 ? (
                                          <li key={i}>
                                            <a
                                              className="dropdown-item"
                                              name="dimensions"
                                              value={item.label}
                                              onClick={handleChange}
                                            >
                                              {item.label}
                                            </a>
                                          </li>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Weight</label>
                              <div>
                                <div className="input-group mb-3">
                                  <input
                                    name="weightValue"
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control"
                                    aria-label="Text input with dropdown button"
                                  />

                                  <button
                                    className="btn btn-outline-secondary dropdown-toggle"
                                    style={{ zIndex: "0" }}
                                    name="weight"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                  >
                                    {itemDetail.weight}
                                  </button>

                                  <ul className="dropdown-menu dropdown-menu-end">
                                    {weightOptions.map((item, i) =>
                                      i > 0 ? (
                                          <li key={i}>
                                            <a
                                              className="dropdown-item"
                                              name="weight"
                                              value={item.label}
                                              onClick={handleChange}
                                            >
                                              {item.label}
                                            </a>
                                          </li>
                                      ) : (
                                        ""
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>

                              {/* <Select
                                                                classNamePrefix="form-select"
                                                                name="weight"
                                                                //value={itemDetail.taxPreference}
                                                                onChange={(e) => handleOption(e, "weight")}
                                                                options={weightOptions}
                                                                placeholder="-- Weight --"
                                                                isClearable="true"
                                                            /> */}
                              {/* {errors.season && touched.season ? (
                                <div className="invalid-feedback d-block">
                                  {errors.season}
                                </div>
                              ) : null} */}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Manufacturer</label>
                              <Select
                                classNamePrefix="form-select"
                                name="manufacturer"
                                //value={itemDetail.manufacturer}
                                onChange={(e) => handlemanufacturerSelect(e, "manufacturer")}
                                options={manufacturerOptions}
                                placeholder="-- Select --"
                                isClearable="true"
                              />
                              {/* {errors.fit && touched.fit ? (
                                <div className="invalid-feedback d-block">
                                  {errors.fit}
                                </div>
                              ) : null} */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Brand</label>
                              <Select
                                classNamePrefix="form-select"
                                name="brand"
                                //value={itemDetail.brand}
                                onChange={(e) => handleBrandSelect(e, "brand")}
                                options={customerOption}
                                placeholder={
                                  itemDetail.brand
                                    ? itemDetail.brand
                                    : "-- Select --"
                                }
                                isClearable="true"
                              />
                              {/* {errors.trimsAndFinishes &&
                                touched.trimsAndFinishes ? (
                                <div className="invalid-feedback d-block">
                                  {errors.trimsAndFinishes}
                                </div>
                              ) : null} */}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">UPC</label>
                              <input
                                type="text"
                                name="UPC"
                                className="form-control"
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                // onKeyDown={preventNegativeValues}
                              />
                              {/* {errors.MOQForProduction &&
                                touched.MOQForProduction ? (
                                <div className="invalid-feedback d-block">
                                  {errors.MOQForProduction}
                                </div>
                              ) : null} */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">MPN</label>
                              <input
                                type="text"
                                name="MPN"
                                className="form-control"
                                onChange={handleChange}
                                // onBlur={handleBlur}
                              />
                              {/* {errors.size && touched.size ? (
                                <div className="invalid-feedback d-block">
                                  {errors.size}
                                </div>
                              ) : null} */}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">EAN</label>
                              <input
                                type="text"
                                name="EAN"
                                className="form-control"
                                onChange={handleChange}
                                // onBlur={handleBlur}
                              />
                              {/* {errors.color && touched.color ? (
                                <div className="invalid-feedback d-block">
                                  {errors.color}
                                </div>
                              ) : null} */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">ISBN</label>
                              <input
                                type="text"
                                name="ISBN"
                                className="form-control"
                                // onClick={handleDateClick}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                              />
                              {/* {errors.developmentDate &&
                                touched.developmentDate ? (
                                <div className="invalid-feedback d-block">
                                  {errors.developmentDate}
                                </div>
                              ) : null} */}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">MRP</label>
                              <input
                                type="number"
                                name="MRP"
                                className="form-control"
                                // onClick={handleDateClick}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                              />
                              {/* {errors.category && touched.category ? (
                                <div className="invalid-feedback d-block">
                                  {errors.category}
                                </div>
                              ) : null} */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Item Type
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="itemType"
                                // value={selectedOptionBuyer || ""}
                                onChange={(e) => handleOption(e, "itemType")}
                                options={categoryOption}
                                placeholder="-- Select type --"
                                isClearable="true"
                              />
                              {/* {errors.itemType && touched.itemType ? (
                                <div className="invalid-feedback d-block">
                                  {errors.itemType} 
                                </div>
                              ) : null} */}
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Item Sub Type
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="itemSubType"
                                // value={selectedOptionBuyer || ""}
                                onChange={(e) => handleOption(e, "itemSubType")}
                                options={subCategoryOption}
                                placeholder="-- Select type --"
                                isClearable="true"
                              />
                              {/* {errors.buyer && touched.buyer ? (
                                <div className="invalid-feedback d-block">
                                  {errors.buyer} subCategoryOption
                                </div>
                              ) : null} */}
                            </div>
                          </div>

                          {itemDetail.type == "Service" && (
                            <div className="col-xl-6 col-lg-6 col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">SAC</label>
                                <input
                                  type="number"
                                  name="sac"
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onKeyDown={preventNegativeValues}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckChecked"
                          defaultChecked={true}
                        />{" "}
                        <b>Sales Information</b>
                        <div className="row mt-2">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Selling Price
                                <span className="star-color"> *</span>
                              </label>
                              <input
                                type="number"
                                name="sellingPrice"
                                step="any"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.sellingPrice && touched.sellingPrice ? (
                                <div className="invalid-feedback d-block">
                                  {errors.sellingPrice}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Account
                                <span className="star-color"> *</span>
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="sellingAccount"
                                // value={selectedOptionBuyer || ""}
                                onChange={(e) =>
                                  handleOption(e, "sellingAccount")
                                }
                                options={sellingAccountOptions}
                                placeholder="-- Select type --"
                                isClearable="true"
                              />
                              {errors.sellingAccount &&
                              touched.sellingAccount ? (
                                <div className="invalid-feedback d-block">
                                  {errors.sellingAccount}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Account Sub Type
                                <span className="star-color"> *</span>
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="sellingAccountSubType"
                                // value={selectedOptionBuyer || ""}
                                onChange={(e) =>
                                  handleOption(e, "sellingAccountSubType")
                                }
                                options={sellingSubAccountOptions}
                                placeholder="-- Select type --"
                                isClearable="true"
                              />
                              {errors.sellingAccount &&
                              touched.sellingAccount ? (
                                <div className="invalid-feedback d-block">
                                  {errors.sellingAccount}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <input
                                type="text"
                                name="sellingDescription"
                                className="form-control"
                                onChange={handleChange}
                                // onBlur={handleBlur}
                              />
                              {/* {errors.styleName && touched.styleName ? (
                                                <div className="invalid-feedback d-block">
                                                    {errors.styleName}
                                                </div>
                                            ) : null} */}
                            </div>
                          </div>
                        </div>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckChecked"
                          defaultChecked={true}
                        />{" "}
                        <b>Purchase Information</b>
                        <div className="row mt-2">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Cost Price
                                <span className="star-color"> *</span>
                              </label>
                              <input
                                type="number"
                                name="costPrice"
                                step="any"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.costPrice && touched.costPrice ? (
                                <div className="invalid-feedback d-block">
                                  {errors.costPrice}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Account
                                <span className="star-color"> *</span>
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="purchaseAccount"
                                // value={selectedOptionBuyer || ""}
                                onChange={(e) =>
                                  handleOption(e, "purchaseAccount")
                                }
                                options={purchaseAccountOptions}
                                placeholder="-- Select type --"
                                isClearable="true"
                              />
                              {errors.purchaseAccount &&
                              touched.purchaseAccount ? (
                                <div className="invalid-feedback d-block">
                                  {errors.purchaseAccount}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Account Sub Type
                                <span className="star-color"> *</span>
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="purchaseAccountSubType"
                                // value={selectedOptionBuyer || ""}
                                onChange={(e) =>
                                  handleOption(e, "purchaseAccountSubType")
                                }
                                options={purchaseSubAccountOptions}
                                placeholder="-- Select type --"
                                isClearable={true}
                              />
                              {errors.sellingAccount &&
                              touched.sellingAccount ? (
                                <div className="invalid-feedback d-block">
                                  {errors.sellingAccount}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <input
                                type="text"
                                name="purchaseDescription"
                                className="form-control"
                                onChange={handleChange}
                                // onBlur={handleBlur}
                              />
                              {/* {errors.styleName && touched.styleName ? (
                                                <div className="invalid-feedback d-block">
                                                    {errors.styleName}
                                                </div>
                                            ) : null} */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Preferred Vendor
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="preferredVendor"
                                className="form-select"
                                //value={itemDetail.preferredVendor}
                                onChange={(e) =>
                                  handleOption(e, "preferredVendor")
                                }
                                options={manufacturerOptions}
                                placeholder="-- Select --"
                                isClearable="true"
                              />
                              {/* {errors.styleName && touched.styleName ? (
                                                <div className="invalid-feedback d-block">
                                                    {errors.styleName}
                                                </div>
                                            ) : null} */}
                            </div>
                          </div>
                        </div>
                        <b>Default Tax Rates</b>
                        <img
                          src={editImg}
                          onClick={handleEditButton}
                          alt="edit"
                        />
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6 mt-2">
                            <div className="mb-3 addItem-Type">
                              <div className="fs-6">Intra State Tax</div>
                              {/* <input onChange={handleChange} disabled id="intraStateTax" name="intraStateTax" value={itemDetail?.intraStateTax} className="form-control" />
                               */}
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={handleChange}
                                  disabled
                                  id="intraStateTax"
                                  name="intraStateTax"
                                  value={itemDetail?.intraStateTax}
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                />
                                <span
                                  className="input-group-text"
                                  id="basic-addon2"
                                >
                                  GST(%)
                                </span>
                              </div>
                              {/* {errors.styleName && touched.styleName ? (
                                                <div className="invalid-feedback d-block">
                                                    {errors.styleName}
                                                </div>
                                            ) : null} */}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6 mt-2">
                            <div className="mb-3 addItem-Type">
                              <div className="fs-6">Inter State Tax Rate</div>
                              {/* <input onChange={handleChange} disabled id="interStateTax" name="interStateTax" value={itemDetail?.interStateTax} className="form-control" />
                               */}
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={handleChange}
                                  disabled
                                  id="interStateTax"
                                  name="interStateTax"
                                  value={itemDetail?.interStateTax}
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                />
                                <span
                                  className="input-group-text"
                                  id="basic-addon2"
                                >
                                  IGST(%)
                                </span>
                              </div>

                              {/* {errors.styleName && touched.styleName ? (
                                                <div className="invalid-feedback d-block">
                                                    {errors.styleName}
                                                </div>
                                            ) : null} */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6 mt-2">
                            <div className="mb-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="trackInventory"
                                  value=""
                                  onClick={handleTrackInventory}
                                  id="flexCheckChecked"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked"
                                >
                                  <span
                                    className="form-label"
                                    style={{ fontSize: "16px" }}
                                  >
                                    {" "}
                                    <b>Track Inventory for this item </b>
                                  </span>
                                  <div className="form-label addItem_track-inventory">
                                    You cannot enable/disable inventory tracking
                                    once you've created transactions for this
                                    item.
                                  </div>
                                </label>
                              </div>
                              {/* {errors.styleName && touched.styleName ? (
                                                <div className="invalid-feedback d-block">
                                                    {errors.styleName}
                                                </div>
                                            ) : null} */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6 mt-2">
                            <div className="mb-3">
                              <div className="addItem-note-form-control mt-2">
                                Note:You can configure the opening stock and
                                stock tracking for this item under the items
                                module
                              </div>
                              {/* {errors.styleName && touched.styleName ? (
                                                <div className="invalid-feedback d-block">
                                                    {errors.styleName}
                                                </div>
                                            ) : null} */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6 mt-2">
                            <div className="mb-3">
                              <label className="form-label">
                                Inventory Account
                                <span className="star-color"> *</span>
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="inventoryAccount"
                                id="inventoryAccount"
                                disabled={inventoryAccountDisabled ? true : false}
                                // value={selectedOptionsamplingFactory || ""}
                                onChange={(e) =>
                                  handleOption(e, "inventoryAccount")
                                }
                                options={inventoryAccount}
                                placeholder="-- Select --"
                                isClearable="true"
                              />
                              {errors.inventoryAccount &&
                              touched.inventoryAccount ? (
                                <div className="invalid-feedback d-block">
                                  {errors.inventoryAccount}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-sm-6 mt-2">
                            <div className="mb-3">
                              <label className="form-label">
                                Opening Stock
                              </label>
                              <input
                                type="text"
                                name="openingStock"
                                id="inventoryAccount"
                                disabled={inventoryAccountDisabled ? true : false}
                                className="form-control"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6 mt-2">
                            <div className="mb-3">
                              <label className="form-label">
                                Opening Stock Rate Per Unit
                              </label>
                              <input
                                type="text"
                                name="openingStockRate"
                                className="form-control"
                                onChange={handleChange}
                                id="inventoryAccount"
                                disabled={inventoryAccountDisabled ? true : false}
                                onBlur={handleBlur}
                              />
                              {/* {errors.styleName && touched.styleName ? (
                                                                <div className="invalid-feedback d-block">
                                                                    {errors.styleName}
                                                                </div>
                                                            ) : null} */}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6 mt-2">
                            <div className="mb-3">
                              <label className="form-label">
                                Reorder Point
                              </label>
                              <input
                                type="text"
                                name="reorderPoint"
                                className="form-control"
                                id="inventoryAccount"
                                disabled={inventoryAccountDisabled  ? true : false}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {/* {errors.styleName && touched.styleName ? (
                                                                <div className="invalid-feedback d-block">
                                                                    {errors.styleName}
                                                                </div>
                                                            ) : null} */}
                            </div>
                          </div>
                        </div>
                        {/* ---------- start Advance tracking -------------------- */}
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6 mt-2">
                            <div className="mb-3">
                              <label
                                className="form-label"
                                style={{ fontSize: "16px" }}
                              >
                                <b>Advanced Inventory Tracking</b>
                              </label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="advancedInventoryTracking"
                                  defaultValue="None"
                                  onClick={handleCheckBox}
                                  id="flexRadioDefault1"
                                  defaultChecked={
                                    itemDetail.advancedInventoryTracking ==
                                    "None"
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault1"
                                >
                                  None
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="advancedInventoryTracking"
                                  defaultValue="Track Batches"
                                  onClick={handleCheckBox}
                                  id="flexRadioDefault2"
                                  defaultChecked={
                                    itemDetail.advancedInventoryTracking ==
                                    "Track Batches"
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault2"
                                >
                                  Track Batches
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ---------- End Advance tracking -------------------- */}
                      </div>

                      <div className="col-md-4">
                        <div className="finance-upload-doc-wrap ml">
                          <div className="finance-upload-doc-inner ">
                            <label className="form-label">Upload Image</label>
                            <div className="dv-file-lst row">
                              {attachImage.length > 0 &&
                                attachImage.map(
                                  (item, index) =>
                                    !item.isDeleted && (
                                      <div className="col-md-4"  key={index}>
                                        <span className="spn-fle">
                                          Sheet {index + 1}
                                        </span>{" "}
                                        &nbsp;
                                        <img
                                          onClick={(e) => {
                                            removeFile(e, index, "image");
                                          }}
                                          src={deleteImg}
                                          className="smll-dlte"
                                        />
                                      </div>
                                    )
                                )}
                            </div>
                            <div className="position-relative ">
                              <div className="upload uploadimg-outer">
                                <img
                                  src={uploadImg}
                                  alt="upload"
                                  className=" upload uploadimg"
                                />
                                <div className="upload-detail">
                                  <div>
                                    Drop file here or{" "}
                                    <span className="blue-txt">Browse</span>
                                  </div>
                                  <div>
                                    You can add upto 15 images. Max file size:
                                    5Mb Each
                                  </div>
                                  <div>File supported: jpeg, jpg,png</div>
                                </div>
                              </div>
                              <input
                                id="fpCostSheet"
                                type="file"
                                className="custom-file"
                                multiple="multiple"
                                accept=".jpg, .png, .jpeg"
                                onChange={(event) => {
                                  handleImageAttach(event, "image");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer style={{ height: "10px" }}>
                  <a
                    type="button"
                    className="btn inner-blue-border-btn"
                    onClick={(e) => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </a>
                  <button type="submit" className="btn inner-blue-btn">
                    Save
                  </button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
