import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/api.urls";


export const getSampleItem = createAsyncThunk(
    "getSampleItem",
    async (data,{ rejectWithValue }) => {
      try {
        let url = API_URLS.SO_PO.GET_ALL_SAMPLE_ITEM;
        const response = await fetch(
          url,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

export const getItemCommonData = createAsyncThunk(
    "getItemCommonData",
    async (data,{ rejectWithValue }) => {
      try {
        let url = API_URLS.SO_PO.ITEM_COMMON_DATA;
        const response = await fetch(
          url,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

export const getSoCommonData = createAsyncThunk(
    "getSoCommonData",
    async (data,{ rejectWithValue }) => {
      try {
        let url = API_URLS.SO_PO.SO_COMMON_DATA;
        const response = await fetch(
          url,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );


export const getCustomer = createAsyncThunk(
    "getCustomer",
    async (data,{ rejectWithValue }) => {
      try {
        let url = API_URLS.SO_PO.GET_ALL_CUSTOMER;
        const response = await fetch(
          url,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            //body: JSON.stringify(data),
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const getSupplier = createAsyncThunk(
    "getSupplier",
    async (data,{ rejectWithValue }) => {
      try {
        let url = API_URLS.SO_PO.GET_ALL_SUPPLIER;
        const response = await fetch(
          url,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            //body: JSON.stringify(data),
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const addUpdateSO = createAsyncThunk(
    'addUpdateSO',
    async (payload, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.SO_PO.ADD_UPDATE_SO,
           {
            method: "POST",
            body: payload,
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )
  export const addUpdatePO = createAsyncThunk(
    'addUpdatePO',
    async (payload, { rejectWithValue }) => {
      try {
  
        const response = await fetch(
          API_URLS.SO_PO.ADD_UPDATE_PO,
           {
            method: "POST",
            body: payload,
           }
         );
        if (!response.ok) return rejectWithValue(response.statusText)
        const data = await response.json()
        return data
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  )

  export const addUpdateItem = createAsyncThunk(
    "addUpdateItem",
    async (data, { rejectWithValue }) => {
      try {
        const response = await fetch(
          API_URLS.SO_PO.ADD_UPDATE_ITEM,
          {
            method: "POST",
            body: data,
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  export const getAllSO = createAsyncThunk(
    "getAllSO",
    async (data,{ rejectWithValue }) => {
      try {
        let url = API_URLS.SO_PO.GET_SO_LIST;
        url = url + "?page="+ data.page + "&limit="+ data.limit
        const response = await fetch(
          url,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const getSellingAccountSubTypeId = createAsyncThunk(
    "getSellingAccountSubTypeId", async (Id, { rejectWithValue }) => {
      try {
        const response = await fetch(API_URLS.SO_PO.GET_SELLING_ACCOUNT_SUBTYPE_ID + '/' + Id);
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const getPurchaseAccountSubTypeId = createAsyncThunk(
    "getPurchaseAccountSubTypeId", async (Id, { rejectWithValue }) => {
      try {
        const response = await fetch(API_URLS.SO_PO.GET_PURCHASE_ACCOUNT_SUBTYPE_ID + '/' + Id);
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const createBuyerIfNotExist = createAsyncThunk(
    "createBuyerIfNotExist", async (data, { rejectWithValue }) => {
      try {
        const response = await fetch(API_URLS.SO_PO.CREATE_BUYER_IFNOTEXIST+ "?name=" + data.name + "&id=" + data.id);
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const createManufacturerIfNotExist = createAsyncThunk(
    "createManufacturerIfNotExist", async (data, { rejectWithValue }) => {
      try {
        const response = await fetch(API_URLS.SO_PO.CREATE_MANUFACTURER_IFNOTEXIST+ "?name=" + data.name + "&id=" + data.id);
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const getSoById = createAsyncThunk(
    "getSoById",
    async (soId, { rejectWithValue }) => {
  
      try {
        const response = await fetch(
          API_URLS.SO_PO.GET_SO_BY_ID +"?soId=" + soId,
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json()
        return result;
  
      } catch (err) {
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue(err)
      }
    }
  );

  export const deleteSo = createAsyncThunk(
    "deleteSo",
    async (id, { rejectWithValue }) => {
      try {
        const response = await fetch(
          API_URLS.SO_PO.DELETE_SO +"?id=" + id,
          {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              //body: JSON.stringify(data),
            }
        );
        if (!response.ok) return rejectWithValue(response.statusText)
        const result = await response.json();
        return result;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
