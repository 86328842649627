import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { deleteRfqFile, getRfqById } from "../../features/rfq/action";
import trashImg from "../../assets/images/trash.svg"
import importImg from "../../assets/images/import.svg"
import moment from 'moment';
import { resetRfq } from "../../features/rfq/slice";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "react-tooltip";
import { getUserById } from "../../features/user_management/action";
import { resetUserManagememnt, userManagememnt } from "../../features/user_management/slice";
import editImg from "../../assets/images/edit.svg"
import { useNavigate } from "react-router-dom";
import { ModalForPreviewOfFile } from "./modalForPreviewOfFile";

export const BasicInfo = (props) => {
  const { rfqId, setAssigneeId, setRfqName, setAttachImagesOfRfq } = props

  const dispatch = useDispatch();
  const [rfqDetail, setRfqDetail] = useState();
  const roleType = localStorage.getItem("roleType");
  const roleId = localStorage.getItem("roleId");
  const [brand, setBrand] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (rfqId)
      dispatch(getRfqById(rfqId));
  }, [rfqId]);
  const rfqResponse = useSelector((state) => state.rfq);
  const usrMngmntResponse = useSelector((state) => state.userManagememnt);


  useEffect(() => {
    if (rfqResponse.actionName == "getRfqById" && rfqResponse.success) {
      setRfqDetail(rfqResponse.rfqData);
      setAttachImagesOfRfq(rfqResponse.rfqData);
      setRfqName(rfqResponse.rfqData.rFQName)
      dispatch(getUserById(rfqResponse.rfqData.brand))
      setAssigneeId(rfqResponse.rfqData.assigneeUser != undefined ? rfqResponse.rfqData.assigneeUser.id : 0);
      dispatch(resetRfq())
    }
    if (rfqResponse.actionName == "deleteRfqFile" && rfqResponse.success) {
      dispatch(getRfqById(rfqId));
    }
  }, [rfqResponse]);

  useEffect(() => {
    if (usrMngmntResponse.actionName == "getUserById" && usrMngmntResponse.success) {
      setBrand(usrMngmntResponse.userDetail)
      dispatch(resetUserManagememnt())
    }
  }, [usrMngmntResponse]);
 
  const handleDelete = (data) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteRfqFile(data));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleEdit = () => {
    navigate('/add-update-rfq', {
      replace: true,
      state: { rfqId: rfqId },
    })
  }

  const [state, setState] = useState({
    rows: [],
    cols: []
  });

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const[openModalForPreview, setOpenModalForPreview]=useState(false);

  const [pdfUrl, setPdfUrl] = useState(null);
  const[fileType,setFileType]=useState(null);
  const PdfPreview = (pdfUrl) => {
    const fileExtension = pdfUrl?.split(".").pop().toLowerCase();
    setOpenModalForPreview(true);
     setFileType(fileExtension);
    setPdfUrl(pdfUrl);
  };

  return (
    <>
      <div className="tab-pane fade show active" id="basic-tab-pane" role="tabpanel" aria-labelledby="basic-tab" tabIndex="0">
        

        <div className="row row-gap-2 mt-3">
          <div className="col-xxl-8 col-xl-9 col-lg-8 col-sm-12">
            <b className="dvrfaName">RFQ Name</b> : <b className="rfavalue">{rfqDetail?.rFQName} </b>
            <button style={{ marginLeft: '200px' }} className="btn" onClick={handleEdit}><img src={editImg} alt="edit" /></button>
            <div className="row row-gap-2">

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showPOCEmail && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||
                rfqDetail?.showRfqFields[1]?.showPOCEmail && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">POC Email</label>
                  <input type="text" readOnly value={rfqDetail?.pOCEmail} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showSales && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||
                rfqDetail?.showRfqFields[1]?.showSales && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Sales</label>
                  <input type="text" readOnly value={rfqDetail?.sales} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showType && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||
                rfqDetail?.showRfqFields[1]?.showType && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Type</label>
                  <input type="text" readOnly value={rfqDetail?.type} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showAssignee && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||
                rfqDetail?.showRfqFields[1]?.showAssignee && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Assignee</label>
                  <input type="text" readOnly data-tooltip-id="my-tooltip-inline" value={rfqDetail?.Users ? rfqDetail.Users.map((innerItem, index) => (
                    innerItem.name
                  )) : ""}
                    data-tooltip-content={rfqDetail?.Users ? rfqDetail.Users.map((innerItem, index) => (
                      innerItem.name
                    )) : ""} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showBrand && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||
                rfqDetail?.showRfqFields[1]?.showBrand && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Buyer</label>
                  <input type="text" readOnly value={brand?.name} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showCategoryId && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||

                rfqDetail?.showRfqFields[1]?.showCategoryId && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Category</label>
                  <input type="text" readOnly value={rfqDetail?.category?.categoryName} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showSubCategoryId && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||

                rfqDetail?.showRfqFields[1]?.showSubCategoryId && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Sub-Category</label>
                  <input type="text" readOnly value={rfqDetail?.subCategory?.subCategoryName} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showStyleNo && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||

                rfqDetail?.showRfqFields[1]?.showStyleNo && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Style No.</label>
                  <input type="text" readOnly value={rfqDetail?.styleNo || "-"} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showStyleName && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||

                rfqDetail?.showRfqFields[1]?.showStyleName && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Style Name</label>
                  <input type="text" readOnly value={rfqDetail?.styleName || "-"} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showProductDescription && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||
                rfqDetail?.showRfqFields[1]?.showProductDescription && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Product Discription</label>
                  <input type="text" readOnly value={rfqDetail?.productDescription} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showQuantity && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||
                rfqDetail?.showRfqFields[1]?.showQuantity && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Quantity</label>
                  <input type="text" readOnly value={rfqDetail?.quantity || "-"} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showOrderValueProjection && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||

                rfqDetail?.showRfqFields[1]?.showOrderValueProjection && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Order Value Projection (INR)</label>
                  <input type="text" readOnly value={rfqDetail?.orderValueProjection} className="form-control" />
                </div> : ""}


              {roleType == "Internal" ?
                <>
                  {/* <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                <label className="form-label">Groyyo Target/Factory Cost (INR)</label>
                <input type="text" readOnly value={rfqDetail?.internalCost} className="form-control" />
              </div> */}
                  <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                    <label className="form-label">Internal Sampling Cost (INR)</label>
                    <input type="text" readOnly value={rfqDetail?.internalSamplingCost || "-"} className="form-control" />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                    <label className="form-label">Internal Sampling Time (Man days)</label>
                    <input type="text" readOnly value={rfqDetail?.internalSamplingTime || "-"} className="form-control" />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                    <label className="form-label">Internal Production Cost (INR)</label>
                    <input type="text" readOnly value={rfqDetail?.internalProductionCost || "-"} className="form-control" />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                    <label className="form-label">Internal Production Time (Man days)</label>
                    <input type="text" readOnly value={rfqDetail?.internalProductionTime || "-"} className="form-control" />
                  </div></>
                : ""
              }

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showRfqReceivedDate && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||

                rfqDetail?.showRfqFields[1]?.showRfqReceivedDate && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">RFQ Received Date</label>
                  <input type="text" readOnly value={moment(rfqDetail?.rfqRecievedDate).format("YYYY-MM-DD")} className="form-control" />
                </div> : ""}

              {rfqDetail?.showRfqFields.length > 0 && rfqDetail?.showRfqFields[0]?.showFabricDetails && rfqDetail?.showRfqFields[0]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 0 ||

                rfqDetail?.showRfqFields[1]?.showFabricDetails && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ||
                roleType == "Internal" ?
                <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                  <label className="form-label">Fabric Detail</label>
                  <input type="text" readOnly value={rfqDetail?.fabricDetails ?? "-"} className="form-control" />
                </div> : ""}

              {/* <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Product</label>
                        <input type="text" readOnly value={rfqDetail.} className="form-control" />
                      </div> */}
            </div>
          </div>

          <h3 className="my-3">Documents Uploaded</h3>

        </div>
        <div className="row row-gap-4">
          {rfqDetail?.rfqFiles &&
            rfqDetail.rfqFiles
              .filter((file) => file.fileType != "image")
              .map((file, i) =>
              (

                roleType == "Internal" || roleType == "External" && rfqDetail?.showRfqFields.length == 0 || rfqDetail?.showRfqFields.length == 1 && rfqDetail?.showRfqFields[0]?.userType != roleId ?

                  <>
                    {file?.fileType != "attach image" ?
                      <div key={i} className="col-lg-3 col-md-4 col-sm-6">
                        <div className="document-detail">

                          <> <a className="rounded rfqBasicInfoFile" onClick={(e) => PdfPreview(file?.filePath)} target="_blank">{file.fileName.split(".").pop().toLowerCase()}</a>
                            {/* <div>
                              <input type="file" />
                              <table>
                                <tbody>
                                  {state?.rows.map((row, index) => (
                                    <tr key={index}>
                                      {row.map((cell, index) => (
                                        <td key={index}>{cell}</td>
                                      ))}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div> */}

                            <div className="document-row">
                              <div>Name: <span>{file.fileName}</span></div>
                              <a target="_blank" href={file?.filePath}><img src={importImg} alt="image" /></a>
                            </div>

                            <div className="document-row">
                              <div>Description: <span>{file.fileType}</span></div>
                              {localStorage.getItem("roleType") == "Internal" &&
                                <a onClick={(e) => handleDelete(file.id)} ><img src={trashImg} alt="trash" /></a>
                              }
                            </div> </>

                        </div>
                      </div>
                      : ""}
                  </>
                  :

                  rfqDetail?.showRfqFields[0]?.userType == roleId || rfqDetail?.showRfqFields[1]?.userType == roleId ?
                    // file?.fileType == "attach image" && rfqDetail?.showRfqFields[0]?.showGarment && rfqDetail?.showRfqFields[0]?.userType == roleId 
                    // || file?.fileType == "attach image" && rfqDetail?.showRfqFields[1]?.showGarment && rfqDetail?.showRfqFields[1]?.userType == roleId   ?

                    //   <>
                    //     <div  className="col-lg-3 col-md-4 col-sm-6">
                    //       <div className="document-detail">
                    //         <a target="_blank" >
                    //           <img src={file?.filePath} alt="import" className="thumbnail rounded rfqBasicInfoImage" /></a>
                    //         <div className="document-row">
                    //           <div>Name: <span>{file.fileName}</span></div>
                    //           <a target="_blank" href={file?.filePath}><img src={importImg} alt="image" /></a>
                    //         </div>

                    //         <div className="document-row">
                    //           <div>Description: <span>{file.fileType}</span></div>
                    //           {localStorage.getItem("roleType") == "Internal" &&
                    //             <a onClick={(e) => handleDelete(file.id)} ><img src={trashImg} alt="trash" /></a>
                    //           }
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </>
                    //   :
                    file?.fileType == "tech pack" && rfqDetail?.showRfqFields[0]?.showTechPack && rfqDetail?.showRfqFields[0]?.userType == roleId || file?.fileType == "tech pack" && rfqDetail?.showRfqFields[1]?.showTechPack && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                      file?.fileType == "spec sheet" && rfqDetail?.showRfqFields[0]?.showSpecSheet && rfqDetail?.showRfqFields[0]?.userType == roleId || file?.fileType == "spec sheet" && rfqDetail?.showRfqFields[1]?.showSpecSheet && rfqDetail?.showRfqFields[1]?.userType == roleId ||
                      file?.fileType == "cost sheet" && rfqDetail?.showRfqFields[0]?.showCostSheet && rfqDetail?.showRfqFields[0]?.userType == roleId || file?.fileType == "cost sheet" && rfqDetail?.showRfqFields[1]?.showCostSheet && rfqDetail?.showRfqFields[1]?.userType == roleId ?
                      <>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                          <div className="document-detail" >
                            <a className="rounded rfqBasicInfoFile" onClick={(e) => PdfPreview(file?.filePath)}>{file.fileName.split(".").pop().toLowerCase()}</a>

                            <div className="document-row">
                              <div >Name: <span>{file.fileName}</span></div>
                              <a target="_blank" href={file?.filePath}><img src={importImg} alt="image" /></a>
                            </div>

                            <div className="document-row">
                              <div>Description: <span>{file.fileType}</span></div>
                              {localStorage.getItem("roleType") == "Internal" &&
                                <a onClick={(e) => handleDelete(file.id)} ><img src={trashImg} alt="trash" /></a>
                              }
                            </div>
                          </div>
                        </div>
                      </>
                      : ""

                    : ""

              ))}
        </div>
      </div>
      <Tooltip
        id="my-tooltip-inline"
        style={{
          backgroundColor: "#FE4900",
          color: "white",
          fontWeight: "bolder",
        }}
      />
      {openModalForPreview ?  <ModalForPreviewOfFile  openModalForPreview={openModalForPreview}  setOpenModalForPreview={setOpenModalForPreview}  setPdfUrl={setPdfUrl}  pdfUrl={pdfUrl} fileType={fileType} setFileType={setFileType} /> :""}
    </>
  )
}