import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { CONSTANT } from "../../utils/constant";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import {
    deleteTNATemplate,
    
} from "../../features/Tna/action";
import { notify } from "../../features/notification/notify";
import { resetSoPo } from "../../features/soPo/slice";
import { getAllSO } from "../../features/soPo/action";
import { deleteSo } from "../../features/soPo/action";
import moment from "moment";

export const SOList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageCount, setPageCount] = useState(0);
    const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
    const [currentPage, setcurrentPage] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [allSo, setAllSO] = useState([]);

    const sopoResponse = useSelector((state) => state.soPo);


    useEffect(() => {
        dispatch(getAllSO({ page: currentPage + 1, limit: itemCount }));
    }, [currentPage, itemCount]);

    useEffect(() => {
        if (sopoResponse.actionName == "getAllSO") {
            setAllSO(sopoResponse.soList[0]);
            setPageCount(Math.ceil(sopoResponse.soList[1] / itemCount));
            dispatch(resetSoPo());
        }

        if(sopoResponse.actionName == "deleteSo"){
            notify('So deleted successfully','success');
            dispatch(resetSoPo());
            dispatch(getAllSO({ page: currentPage + 1, limit: itemCount }));
        }
      
    }, [sopoResponse]);

    const goToEditPage = (item) => {
        navigate("/create-tna", { state: { item } });
    };

    const handleDeleteSo = (id) => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(deleteSo(id ));
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    const handleItemsChange = (event) => {
        setItemCount(event.target.value);
        setcurrentPage(0);
    };

    const handlePageChange = (selectedObject) => {
        setcurrentPage(selectedObject.selected);
    };

    const convertToPO = (soData) => {
        navigate("/create-po", { state: { soData } });
    };

    const soDetails = (item) => {
        navigate("/create-so", {
          replace: true,
          state: { Id: item.id },
        });
      };
    
    return (
        <>
            <LeftMenu />
            <div className="right-column">
                <Header />
                <div className="body-wrap">
                    <div className="top-heading">
                        <div className="d-flex">
                            <h2>SO List</h2>
                        </div>
                        <div className="d-flex gap-2">

                            <div className="dropdown">
                                <button
                                    onClick={(e)=>{navigate('/create-so')}}
                                    className="btn ornge-btn"
                                >
                                     Create SO
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="rfq-status-table tab-content">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Sales Order#</th>
                                        <th>SO Date</th>
                                        <th>Reference</th>
                                        <th>Vendor Name</th>
                                        <th>Status</th>
                                        <th>Amount</th>
                                        <th>Expected Delivery Date</th>
                                        <th>PO number</th>
                                        <th>Convert</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {allSo && allSo?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.salesOrder}</td>
                                            <td>{moment(item.salesOrderDate).format("DD-MM-yyyy")}</td>
                                            <td>{item.reference}</td>
                                            <td>{item.customerName}</td>
                                            <td>{item.status ? 'Submitted' : 'Draft'}</td>
                                            <td>{item.total}</td>
                                            <td>{moment(item.expectedShipmentDate).format("DD-MM-yyyy")}</td>
                                           <td>{item.purchaseOrder?.id && <a onClick={(e)=>convertToPO(item)} style={{color: '#fd4900'}}>{item.purchaseOrder?.PONumber}</a> } </td>
                                            <td>{!item.purchaseOrder?.id && <a onClick={(e)=>convertToPO(item)} style={{color: '#fd4900'}}>Convert to PO</a> } 
                                            </td>
                                            <td>
                                               
                                                <a title="Edit SO">
                                                    <i className="fa-regular fa-pen-to-square mr-2" onClick={(e)=>soDetails(item)} ></i>
                                                </a>
                                                <a
                                                   
                                                    title="Delete SO"
                                                >
                                                    <i
                                                        onClick={(e)=>handleDeleteSo(item.id)}
                                                        className="fa fa-trash px-3"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            forcePage={currentPage}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination justify-content-center mt-4"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                        <div className="customSelect">
                            <label htmlFor="">Items per page :</label>
                            <div className="selectBox">
                                <select name="" id="" onChange={handleItemsChange}>
                                    <option value={CONSTANT.PAGE_LIMIT}>
                                        {CONSTANT.PAGE_LIMIT}
                                    </option>
                                    <option value="15">15</option>

                                    <option value="20">20</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
