import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { CONSTANT } from "../../utils/constant";
import { confirmAlert } from "react-confirm-alert";
import ReactPaginate from "react-paginate";
import {
    deleteTNATemplate,
    getAllTnaTemplate,
} from "../../features/Tna/action";
import { resetTna } from "../../features/Tna/slice";
import { notify } from "../../features/notification/notify";

export const AllTnaTemplate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const [pageCount, setPageCount] = useState(0);
    const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
    const [currentPage, setcurrentPage] = useState(0);



    const [firstLoad, setFirstLoad] = useState(true);
    const [allTna, setAllTna] = useState([]);

    const tnaResponse = useSelector((state) => state.Tna);

    useEffect(() => {
        dispatch(getAllTnaTemplate({ page: currentPage + 1, limit: itemCount }));
    }, [currentPage, itemCount]);

    useEffect(() => {
        if (tnaResponse.actionName == "getAllTnaTemplate") {
            setAllTna(tnaResponse.tnaTemplateData.tnaTemplates);
            setPageCount(Math.ceil(tnaResponse.tnaTemplateData.tnaTemplates[1] / itemCount));
            dispatch(resetTna());
        }
        if (tnaResponse.actionName == "deleteTNATemplate") {
            notify(tnaResponse.message, "success");
            dispatch(getAllTnaTemplate({ page: currentPage + 1, limit: itemCount }));
            dispatch(resetTna());
        }
        if (tnaResponse.actionName == "addBulkTemplate" && tnaResponse.success) {
            notify("All Templates Created Successfully.", "success");
            dispatch(resetTna());
          }
    }, [tnaResponse]);

    const goToEditPage = (item) => {
        navigate("/create-tna", { state: { item } });
    };

    const handleDeleteTNA = (id) => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    dispatch(deleteTNATemplate({ id: id }));
                }
              },
              {
                label: 'No'
              }
            ]
          });
    };

    const handleItemsChange = (event) => {
        setItemCount(event.target.value);
        setcurrentPage(0);
    };

    const handlePageChange = (selectedObject) => {
        setcurrentPage(selectedObject.selected);
    };
    const handleAddTemplate = () => {
        navigate("/create-tna");
      };
    console.log("current page", currentPage)
    return (
        <>
            <LeftMenu />
            <div className="right-column">
                <Header />
                <div className="body-wrap">
                    <div className="top-heading">
                        <div className="d-flex">
                            <h2>TNA Templates</h2>
                        </div>
                        <div className="d-flex gap-2">
                            <a className="btn grey-brdr-btn" href="/milestone-list">
                                <span className="align-middle">Milestone Listing</span>
                            </a>
                            <div className="dropdown">
                <button
                  className="btn ornge-btn"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  &#43; Add New Template
                </button>

                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" onClick={handleAddTemplate}>
                      Add Single Template
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/add-bulk-template">
                      Add Bulk Template
                    </a>
                  </li>
                </ul>
              </div>
                        </div>
                    </div>
                    <div className="rfq-status-table tab-content">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {allTna[0]?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                            <td>{item.type}</td>
                                            <td>
                                                <a onClick={() => goToEditPage(item)} title="Edit TNA">
                                                    <i className="fa-regular fa-pen-to-square mr-2"></i>
                                                </a>
                                                <a
                                                    onClick={() => handleDeleteTNA(item.id)}
                                                    title="Delete TNA"
                                                >
                                                    <i
                                                        className="fa fa-trash px-3"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            forcePage={currentPage}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination justify-content-center mt-4"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                        <div className="customSelect">
                            <label htmlFor="">Items per page :</label>
                            <div className="selectBox">
                                <select name="" id="" onChange={handleItemsChange}>
                                    <option value={CONSTANT.PAGE_LIMIT}>
                                        {CONSTANT.PAGE_LIMIT}
                                    </option>
                                    <option value="15">15</option>

                                    <option value="20">20</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
