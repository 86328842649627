import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSampleById } from "../../features/sample/action";
import { Form, Formik } from "formik";
import uploadImg from "../../assets/images/upload-image.svg";
import deleteImg from "../../assets/images/deleteicon.png";
import { v1 as uuidv1 } from "uuid";
import { handleDateClick } from "../../utils/datePickerHelper";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import Select from "react-select";
import { addUpdateSample } from "../../features/sample/action";
import { notify } from "../../features/notification/notify";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { resetSample } from "../../features/sample/slice";
import { getUserByRoleName } from "../../features/userDetail/action";
import { resetUserDetail } from "../../features/userDetail/slice";
import { resetCommon } from "../../features/common/slice";
import {
  getCategory,
  getSeason,
  getSubCategoryByCategoryId,
} from "../../features/common/action";

export const EditSample = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [sampleId, setSampleId] = useState();
  const [costSheet, setCostSheet] = useState([]);
  const [specSheet, setSpecSheet] = useState([]);
  const [techPack, setTechPack] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [sampleDetail, setSampleDetail] = useState();
  const [seasons, setSeasons] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [newSample, setNewSample] = useState({
    id: "",
    sampleName: "",
    samplingCostFOB: "",
    samplingCostCMT: "",
    samplingFactory: "",
    samplingMill: "",
    tat: "",
    season: "",
    fit: "",
    trimsAndFinishes: "",
    MOQForProduction: "",
    size: "",
    color: "",
    developmentDate: "",
    category: "",
    buyer: "",
    subCategory: "",
    fabricContents: "",
    styleNumber: "",
    styleName: "",
    product: "",
  });

  const sampleResponse = useSelector((state) => state.sample);
  const userResponse = useSelector((state) => state.userDetail);
  const commonResponse = useSelector((state) => state.common);

  useEffect(() => {
    dispatch(getUserByRoleName("Supplier,Buyer"));
    dispatch(getSeason());
    dispatch(getCategory());
  }, []);

  useEffect(() => {
    if (userResponse.actionName === "userByRoleName") {
      setFactoryList(
        userResponse.roleDetails.filter((x) => x.Roles[0].role == "Supplier")
      );
      setBuyerList(
        userResponse.roleDetails.filter((x) => x.Roles[0].role == "Buyer")
      );
      dispatch(resetUserDetail());
    }
  }, [userResponse]);
  useEffect(() => {
    if (state && state.sampleId) {
      dispatch(getSampleById(state.sampleId));
      setSampleId(state.sampleId);
    }
  }, []);

  const handleChange = (e) => {
    setNewSample({
      ...newSample,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "category") {
      dispatch(getSubCategoryByCategoryId(e.target.value));
    }
  };

  useEffect(() => {
    if (commonResponse.actionName === "getCategory" && commonResponse.success) {
      setCategoryList(commonResponse.category);
      dispatch(resetCommon());
    }
    if (commonResponse.actionName === "getSeason" && commonResponse.success) {
      setSeasons(commonResponse.season);
      dispatch(resetCommon());
    }
    if (
      commonResponse.actionName === "getSubCategory" &&
      commonResponse.success
    ) {
      setSubCat(commonResponse.subCategory);
      dispatch(resetCommon());
    }
  }, [commonResponse]);

  const sampleSchema = Yup.object().shape({
    sampleName: Yup.string().required("Required"),
    season: Yup.string().required("Required"),
    fit: Yup.string().required("Required"),
    trimsAndFinishes: Yup.string().required("Required"),
    size: Yup.string().required("Required"),
    color: Yup.string().required("Required"),
    developmentDate: Yup.date().nullable().required("Required"),
    fabricContents: Yup.string().required("Required"),
    styleNumber: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (sampleResponse.actionName == "getSampleById") {
      const dateString = sampleResponse.sample.developmentDate;
      const formattedDate = moment(dateString).format("YYYY-MM-DD");
      setSampleDetail(sampleResponse.sample);
    }
  }, [sampleResponse.sample]);
  useEffect(() => {
    if (sampleDetail) {
      setNewSample({
        ...newSample,
        id: sampleDetail.id,
        sampleName: sampleDetail.sampleName,
        samplingCostFOB: sampleDetail.samplingCostFOB,
        samplingCostCMT: sampleDetail.samplingCostCMT,
        samplingFactory: sampleDetail.samplingFactory,
        samplingMill: sampleDetail.samplingMill,
        tat: sampleDetail.TAT,
        season: sampleDetail.season.id,
        fit: sampleDetail.fit,
        trimsAndFinishes: sampleDetail.trimsAndFinishes,
        MOQForProduction: sampleDetail.MOQForProduction,
        size: sampleDetail.size,
        color: sampleDetail.color,
        developmentDate: moment(sampleDetail.formattedDate).format("YYYY-MM-DD"),
        category: sampleDetail.category?.id,
        buyer: sampleDetail.buyer,
        subCategory: sampleDetail.subCategory?.id,
        fabricContents: sampleDetail.fabricContents,
        styleNumber: sampleDetail.styleNumber,
        styleName: sampleDetail.styleName,
        product: sampleDetail.product,
      });
      dispatch(getSubCategoryByCategoryId(sampleResponse?.sample?.category?.id));
    }
    if (sampleDetail?.sampleFiles && sampleDetail.sampleFiles.length > 0) {
      const specFile = [];
      const costFile = [];
      const techFile = [];
      sampleDetail.sampleFiles.map((item) => {
        if (item.fileType == "spec sheet")
          specFile.push({
            id: item.id,
            fileType: "spec sheet",
            fileName: item.fileName,
            isDeleted: false,
          });
        if (item.fileType == "cost sheet")
          costFile.push({
            id: item.id,
            fileType: "cost sheet",
            fileName: item.fileName,
            isDeleted: false,
          });
        if (item.fileType == "tech pack")
          techFile.push({
            id: item.id,
            fileType: "tech pack",
            fileName: item.fileName,
            isDeleted: false,
          });
      });
      setSpecSheet(specFile);
      setCostSheet(costFile);
      setTechPack(techFile);
    }
  }, [sampleDetail]);
  const handleSubmit = (values) => {
    if (
      costSheet.filter(x => x.isDeleted == false).length == 0 &&
      specSheet.filter(x => x.isDeleted == false).length == 0 &&
      techPack.filter(x => x.isDeleted == false).length == 0
    ) {
      notify("Please add atleast one file", "info");
    } else {
      let formData = new FormData();
      const fileNames = [];
      costSheet.map((fileSet) => {
        formData.append("upload_file", fileSet.file);
        fileNames.push({
          id: fileSet.id,
          fileType: "cost sheet",
          fileName: fileSet.fileName,
          isDeleted: fileSet.isDeleted,
        });
      });
      specSheet.map((fileSet) => {
        formData.append("upload_file", fileSet.file);
        fileNames.push({
          id: fileSet.id,
          fileType: "spec sheet",
          fileName: fileSet.fileName,
          isDeleted: fileSet.isDeleted,
        });
      });
      techPack.map((fileSet) => {
        formData.append("upload_file", fileSet.file);
        fileNames.push({
          id: fileSet.id,
          fileType: "tech pack",
          fileName: fileSet.fileName,
          isDeleted: fileSet.isDeleted,
        });
      });
      formData.append("content", JSON.stringify(newSample));
      formData.append("fileList", JSON.stringify(fileNames));
      dispatch(addUpdateSample(formData));
    }
  };

  const getFile = (image, fileExtension) => {
    let originalFileName, index;
    const blob = image.slice(0, image.size);
    if (image.name.indexOf("AM") != -1)
      index = image?.name.indexOf("AM");

    else if (image?.name.indexOf("PM") != -1)
      index = image?.name.indexOf("PM");
    if (index) {
      originalFileName =
        image?.name.slice(0, index - 1) + "_" + image?.name.slice(index);
    }
    else
      originalFileName = image?.name;
    const lastDotIndex = originalFileName.lastIndexOf(".");
    const filenameWithoutExtension = originalFileName.substring(
      0,
      lastDotIndex
    );
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .replace(/:/g, "")
      .replace(/\..+/, "")
      .replace(/-/g, "");
    const newImageName =
      filenameWithoutExtension + "_" + formattedDate + "." + fileExtension;
    const file = new File([blob], newImageName);
    return { file, newImageName };
  };

  const handleFileSheetUpload = (e, type) => {
    if (e.target.files.length > 5) {
      notify("Only 5 files can be added at a time", "error");
      e.target.value = "";
      return;
    }
    Array.from(e.target.files).forEach(item => {
      const fileExtension = item?.name
        .split(".")
        .pop()
        .toLowerCase();
      var acceptedFormats = ["pdf", "xlsx", "docx", "csv", "xls", "doc"];
      if (!acceptedFormats.includes(fileExtension)) {
        notify("File type not supported", "error");
        e.target.value = "";
        return;
      }
      const { file, newImageName } = getFile(item, fileExtension);
      if (type === "cost" && costSheet.length != 5) {
        setCostSheet((costSheet) => [
          ...costSheet,
          { file: file, fileName: newImageName },
        ]);
      } else if (type === "spec" && specSheet.length != 5) {
        setSpecSheet((specSheet) => [
          ...specSheet,
          { file: file, fileName: newImageName },
        ]);
      } else if (type === "techpack" && techPack.length != 5) {
        setTechPack((techPack) => [
          ...techPack,
          { file: file, fileName: newImageName },
        ]);
      } else {
        notify("Only 5 files can be added at a time", "info");
      }
    });
  };

  const handleDelete = (e, index, type) => {
    if (type === "cost") {
      const cost = [...costSheet];
      cost[index].isDeleted = true;
      setCostSheet(cost);
    } else if (type == "spec") {
      const spec = [...specSheet];
      spec[index].isDeleted = true;
      setSpecSheet(spec);
    } else if (type == "tech") {
      const tech = [...techPack];
      tech[index].isDeleted = true;
      setTechPack(tech);
    }
  };

  const removeFile = (e, index, type) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure want to delete this file?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleDelete(e, index, type);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  useEffect(() => {
    if (sampleResponse.actionName == "addUpdateSample") {
      notify(sampleResponse.message, "success");
      dispatch(resetSample());
    }
  }, [sampleResponse]);

  const handleBack = () => {
    navigate("/sample-detail", {
      replace: true,
      state: { sampleId: sampleId },
    });
  };






  const options = factoryList.map((item) => ({
    value: item.id,
    label: item.name ? item.name : item.emailId,
  }));

  // Find the selected option
  const selectedOptionsamplingFactory = options.find(
    (option) => option.value === newSample.samplingFactory
  );

  // Handle change for react-select
  const handleSelectChangeSamplingFactory = (selectedOptionsamplingFactory) => {
    handleChange({
      target: {
        name: "samplingFactory",
        value: selectedOptionsamplingFactory
          ? selectedOptionsamplingFactory.value
          : "",
      },
    });
  };

  const selectedOptionsamplingMill = options.find(
    (option) => option.value === newSample.samplingMill
  );

  // Handle change for react-select
  const handleSelectChangeSamplingMill = (selectedOptionsamplingMill) => {
    handleChange({
      target: {
        name: "samplingMill",
        value: selectedOptionsamplingMill
          ? selectedOptionsamplingMill.value
          : "",
      },
    });
  };

  const seasonOptions = seasons.map((item) => ({
    value: item.id,
    label: item.seasonName,
  }));

  // Handle change for react-select
  const handleSelectChangeSeason = (seasonOptions) => {
    handleChange({
      target: {
        name: "season",
        value: seasonOptions ? seasonOptions.value : "",
      },
    });
  };

  const categoryOptions = categoryList.map((item) => ({
    value: item.id,
    label: item.categoryName,
  }));

  // Find the selected option
  const selectedOptionCategory = categoryOptions.find(
    (categoryOptions) => categoryOptions.value === newSample.category
  );

  // Handle change for react-select
  const handleSelectChangeCategory = (selectedOptionCategory) => {
    handleChange({
      target: {
        name: "category",
        value: selectedOptionCategory ? selectedOptionCategory.value : "",
      },
    });
  };

  const buyerOptions = buyerList.map((item) => ({
    value: item.id,
    label: item.name ? item.name : item.emailId,
  }));

  // Find the selected option
  const selectedOptionBuyer = buyerOptions.find(
    (buyerOptions) => buyerOptions.value === newSample.buyer
  );

  // Handle change for react-select
  const handleSelectChangeBuyer = (selectedOptionBuyer) => {
    handleChange({
      target: {
        name: "buyer",
        value: selectedOptionBuyer ? selectedOptionBuyer.value : "",
      },
    });
  };

  const subCategoryOptions = subCat.map((item) => ({
    value: item.id,
    label: item.subCategoryName,
  }));

  // Handle change for react-select
  const handleSelectChangeSubCategory = (subCategoryOptions) => {
    handleChange({
      target: {
        name: "subCategory",
        value: subCategoryOptions ? subCategoryOptions.value : "",
      },
    });
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <Formik
            initialValues={newSample}
            validationSchema={sampleSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, isValid, handleBlur }) => (
              <>
                <Form>
                  <div className="top-heading">
                    <div onClick={handleBack} className="d-flex">
                      <a href="#" className="back">
                        &#8249;
                      </a>
                      <h2>Edit Sample</h2>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn grey-brdr-btn mr-20"
                        onClick={handleBack}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn ornge-brdr-btn">
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="add-user-wrap">
                    <div className="sample-detail-wrap">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="basic-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#basic-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="basic-tab-pane"
                            aria-selected="true"
                          >
                            Sample Details
                          </button>
                        </li>
                      </ul>
                    </div>
                    <br />
                    <div className="row ">
                      <div className="col-md-8">
                        <div className="row">

                          <input
                            value={newSample.id ?? ""}
                            onChange={handleChange}
                            type="hidden"
                            readOnly={true}
                            name="id"
                            className="form-control"
                            onBlur={handleBlur}
                          />
                          {errors.id && touched.id ? (
                            <div className="invalid-feedback d-block">
                              {errors.id}
                            </div>
                          ) : null}

                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Sample Name<span className="star-color"> *</span>
                              </label>
                              <input
                                value={newSample.sampleName ?? ""}
                                onChange={handleChange}
                                type="text"
                                name="sampleName"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.sampleName && touched.sampleName ? (
                                <div className="invalid-feedback d-block">
                                  {errors.sampleName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Sampling Cost FOB<span></span>
                              </label>
                              <input
                                value={newSample.samplingCostFOB ?? ""}
                                onChange={handleChange}
                                type="number"
                                min="0"
                                name="samplingCostFOB"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.samplingCostFOB &&
                                touched.samplingCostFOB ? (
                                <div className="invalid-feedback d-block">
                                  {errors.samplingCostFOB}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Sampling Cost CMT<span></span>
                              </label>
                              <input
                                value={newSample.samplingCostCMT ?? ""}
                                onChange={handleChange}
                                type="number"
                                min="0"
                                name="samplingCostCMT"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.samplingCostCMT &&
                                touched.samplingCostCMT ? (
                                <div className="invalid-feedback d-block">
                                  {errors.samplingCostCMT}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Sampling Factory<span></span>
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="samplingFactory"
                                value={newSample?.samplingFactory}
                                onChange={handleSelectChangeSamplingFactory}
                                options={options}
                                placeholder={newSample?.samplingFactory == "" ? 'Sampling factory : All' : options.find(x => x.value == newSample?.samplingFactory)?.label}
                                isClearable
                              />
                              {errors.samplingFactory &&
                                touched.samplingFactory ? (
                                <div className="invalid-feedback d-block">
                                  {errors.samplingFactory}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Sampling Mill<span></span>
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="samplingMill"
                                value={newSample?.samplingMill}
                                onChange={handleSelectChangeSamplingMill}
                                options={options}
                                placeholder={newSample?.samplingMill == "" ? 'Sampling Mill : All' : options.find(x => x.value == newSample?.samplingMill)?.label}
                                isClearable
                              />
                              {errors.samplingMill && touched.samplingMill ? (
                                <div className="invalid-feedback d-block">
                                  {errors.samplingMill}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                TAT<span></span>
                              </label>
                              <input
                                value={newSample.tat ?? ""}
                                onChange={handleChange}
                                type="number"
                                min="0"
                                name="tat"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.tat && touched.tat ? (
                                <div className="invalid-feedback d-block">
                                  {errors.tat}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Season<span className="star-color"> *</span>
                              </label>
                              <Select
                                name="season"
                                classNamePrefix="form-control"
                                value={newSample?.season}
                                onChange={handleSelectChangeSeason}
                                onBlur={handleBlur}
                                options={seasonOptions}
                                placeholder={newSample?.season == "" ? 'Season : All' : seasonOptions.find(x => x.value == newSample?.season)?.label}
                                isClearable
                              />
                              {errors.season && touched.season ? (
                                <div className="invalid-feedback d-block">
                                  {errors.season}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Fit<span className="star-color"> *</span>
                              </label>
                              <input
                                value={newSample.fit ?? ""}
                                onChange={handleChange}
                                type="text"
                                name="fit"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.fit && touched.fit ? (
                                <div className="invalid-feedback d-block">
                                  {errors.fit}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Trims & Finishes<span className="star-color"> *</span>
                              </label>
                              <input
                                value={newSample.trimsAndFinishes ?? ""}
                                onChange={handleChange}
                                type="text"
                                name="trimsAndFinishes"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.trimsAndFinishes &&
                                touched.trimsAndFinishes ? (
                                <div className="invalid-feedback d-block">
                                  {errors.trimsAndFinishes}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                MOQ for Production<span></span>
                              </label>
                              <input
                                value={newSample.MOQForProduction ?? ""}
                                onChange={handleChange}
                                type="number"
                                name="MOQForProduction"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.MOQForProduction &&
                                touched.MOQForProduction ? (
                                <div className="invalid-feedback d-block">
                                  {errors.MOQForProduction}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Size<span className="star-color"> *</span>
                              </label>
                              <input
                                value={newSample.size ?? ""}
                                onChange={handleChange}
                                type="text"
                                min="0"
                                name="size"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.size && touched.size ? (
                                <div className="invalid-feedback d-block">
                                  {errors.size}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Color<span className="star-color"> *</span>
                              </label>
                              <input
                                value={newSample.color ?? ""}
                                onChange={handleChange}
                                type="text"
                                name="color"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.color && touched.color ? (
                                <div className="invalid-feedback d-block">
                                  {errors.color}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Development Date <span className="star-color"> *</span>
                              </label>
                              <input
                                value={newSample.developmentDate ?? ""}
                                onChange={handleChange}
                                onClick={handleDateClick}
                                type="date"
                                name="developmentDate"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.developmentDate &&
                                touched.developmentDate ? (
                                <div className="invalid-feedback d-block">
                                  {errors.developmentDate}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Category<span></span>
                              </label>
                              <Select
                                name="category"
                                classNamePrefix="form-control"
                                value={newSample?.category}
                                onChange={handleSelectChangeCategory}
                                onBlur={handleBlur}
                                options={categoryOptions}
                                placeholder={newSample?.category == "" ? 'Category : All' : categoryOptions.find(x => x.value == newSample?.category)?.label}
                                isClearable
                              />
                              {errors.category && touched.category ? (
                                <div className="invalid-feedback d-block">
                                  {errors.category}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Buyer<span></span>
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="buyer"
                                value={newSample?.buyer}
                                onChange={handleSelectChangeBuyer}
                                options={buyerOptions}
                                placeholder={newSample?.buyer == "" ? 'Buyer : All' : buyerOptions.find(x => x.value == newSample?.buyer)?.label}
                                isClearable
                              />
                              {errors.buyer && touched.buyer ? (
                                <div className="invalid-feedback d-block">
                                  {errors.buyer}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Sub-Category<span></span>
                              </label>
                              <Select
                                name="subCategory"
                                classNamePrefix="form-control"
                                onChange={handleSelectChangeSubCategory}
                                onBlur={handleBlur}
                                options={subCategoryOptions}
                                placeholder={newSample?.subCategory == "" ? 'Subcategory : All' : subCategoryOptions.find(x => x.value == newSample?.subCategory)?.label}
                                isClearable
                              />
                              {errors.subCategory && touched.subCategory ? (
                                <div className="invalid-feedback d-block">
                                  {errors.subCategory}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Fabric Content<span className="star-color"> *</span>
                              </label>
                              <input
                                value={newSample.fabricContents ?? ""}
                                onChange={handleChange}
                                type="text"
                                name="fabricContents"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.fabricContents &&
                                touched.fabricContents ? (
                                <div className="invalid-feedback d-block">
                                  {errors.fabricContents}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Style number<span className="star-color"> *</span>
                              </label>
                              <input
                                value={newSample.styleNumber ?? ""}
                                onChange={handleChange}
                                type="text"
                                name="styleNumber"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.styleNumber && touched.styleNumber ? (
                                <div className="invalid-feedback d-block">
                                  {errors.styleNumber}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Style Name<span></span>
                              </label>
                              <input
                                value={newSample.styleName ?? ""}
                                onChange={handleChange}
                                type="text"
                                name="styleName"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.styleName && touched.styleName ? (
                                <div className="invalid-feedback d-block">
                                  {errors.styleName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Product<span></span>
                              </label>
                              <input
                                value={newSample.product ?? ""}
                                onChange={handleChange}
                                type="text"
                                name="product"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                              {errors.product && touched.product ? (
                                <div className="invalid-feedback d-block">
                                  {errors.product}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="finance-upload-doc-wrap ml">
                          <div className="finance-upload-doc-inner ">
                            <label className="form-label">Cost sheet</label>
                            <div className="dv-file-lst row">
                              {costSheet.length > 0 &&
                                costSheet.map(
                                  (item, index) =>
                                    !item.isDeleted && (
                                      <div className="col-md-4">
                                        <span className="spn-fle">
                                          Sheet {index + 1}
                                        </span>{" "}
                                        &nbsp;
                                        <img
                                          onClick={(e) => {
                                            removeFile(e, index, "cost");
                                          }}
                                          src={deleteImg}
                                          className="smll-dlte"
                                        />
                                      </div>
                                    )
                                )}
                            </div>
                            <div className="position-relative custom-upload">
                              <div className="upload">
                                <div className="upload-img">
                                  <img src={uploadImg} alt="upload" />
                                </div>
                                <div className="upload-detail">
                                  <div>
                                    Drop your Cost Sheet here or{" "}
                                    <span className="blue-txt">Browse</span>
                                  </div>
                                  <div>Max file size: 100MB</div>
                                  <div>
                                    File supported: pdf, xlsx, xls, csv, docx and doc
                                  </div>
                                </div>
                              </div>
                              <input
                                type="file"
                                className="custom-file"
                                name="costSheetFile"
                                multiple="multiple"
                                accept=".pdf, .xlsx, .xls, .csv, .docx, .doc"
                                onChange={(event) => {
                                  handleFileSheetUpload(event, "cost");
                                }}
                              />
                              {errors.costSheetFile && touched.costSheetFile ? (
                                <div className="invalid-feedback d-block">
                                  {errors.costSheetFile}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="finance-upload-doc-wrap ml">
                          <div className="finance-upload-doc-inner ">
                            <label className="form-label">Spec sheet</label>
                            <div className="dv-file-lst row">
                              {specSheet.length > 0 &&
                                specSheet.map(
                                  (item, index) =>
                                    !item.isDeleted && (
                                      <div className="col-md-4">
                                        <span className="spn-fle">
                                          Sheet {index + 1}
                                        </span>{" "}
                                        &nbsp;
                                        <img
                                          onClick={(e) => {
                                            removeFile(e, index, "spec");
                                          }}
                                          src={deleteImg}
                                          className="smll-dlte"
                                        />
                                      </div>
                                    )
                                )}
                            </div>
                            <div className="position-relative custom-upload">
                              <div className="upload">
                                <div className="upload-img">
                                  <img src={uploadImg} alt="upload" />
                                </div>
                                <div className="upload-detail">
                                  <div>
                                    Drop your Spec Sheet here or{" "}
                                    <span className="blue-txt">Browse</span>
                                  </div>
                                  <div>Max file size: 100MB</div>
                                  <div>
                                    File supported: pdf, xlsx, xls, csv, docx and doc
                                  </div>
                                </div>

                                <input
                                  type="file"
                                  className="custom-file"
                                  multiple="multiple"
                                  accept=".pdf, .xlsx, .xls, .csv, .docx, .doc"
                                  name="specSheetFile"
                                  onChange={(event) => {
                                    handleFileSheetUpload(event, "spec");
                                  }}
                                />
                                {errors.specSheetFile &&
                                  touched.specSheetFile ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.specSheetFile}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="finance-upload-doc-wrap ml">
                          <div className="finance-upload-doc-inner ">
                            <label className="form-label">Tech pack</label>
                            <div className="dv-file-lst row">
                              {techPack.length > 0 &&
                                techPack.map(
                                  (item, index) =>
                                    !item.isDeleted && (
                                      <div className="col-md-4">
                                        <span className="spn-fle">
                                          Sheet {index + 1}
                                        </span>{" "}
                                        &nbsp;
                                        <img
                                          onClick={(e) => {
                                            removeFile(e, index, "tech");
                                          }}
                                          src={deleteImg}
                                          className="smll-dlte"
                                        />
                                      </div>
                                    )
                                )}
                            </div>
                            <div className="position-relative custom-upload">
                              <div className="upload">
                                <div className="upload-img">
                                  <img src={uploadImg} alt="upload" />
                                </div>
                                <div className="upload-detail">
                                  <div>
                                    Drop your Tech Pack here or{" "}
                                    <span className="blue-txt">Browse</span>
                                  </div>
                                  <div>Max file size: 100MB</div>
                                  <div>
                                    File supported: pdf, xlsx, xls, csv, docx, and doc
                                  </div>
                                </div>
                              </div>
                              <input
                                type="file"
                                className="custom-file"
                                name="techPackFile"
                                multiple="multiple"
                                accept=".pdf, .xlsx, .xls, .csv, .docx, .doc"
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  handleFileSheetUpload(event, "tech");
                                }}
                              />
                              {errors.techPackFile && touched.techPackFile ? (
                                <div className="invalid-feedback d-block">
                                  {errors.techPackFile}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
