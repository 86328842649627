import React, { useEffect, useRef, useState } from "react";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { handleDateClick } from "../../utils/datePickerHelper";
import { useDispatch, useSelector } from "react-redux";
import uploadImg from "../../assets/images/upload-image.svg";
import { numberFormat, SO, TERMS_CONDITIONS, transformArrayOption } from "./soDataOption";
import { addUpdatePO, addUpdateSO, getCustomer, getItemCommonData, getSampleItem, getSoCommonData, getSupplier } from "../../features/soPo/action";
import { resetSoPo } from "../../features/soPo/slice";
import { resetCommon } from "../../features/common/slice";
import { getCategory, getSubCategoryByCategoryId } from "../../features/common/action";
import { getUserByRoleName } from "../../features/userDetail/action";
import { notify } from "../../features/notification/notify";
import { confirmAlert } from "react-confirm-alert";
import deleteImg from "../../assets/images/deleteicon.png";
import { v1 as uuidv1 } from "uuid";
import { AddUpdateItem } from "./addUpdateItem";
import moment from "moment";
import { resetUserDetail } from "../../features/userDetail/slice";
import { PoItemList } from "./poComponent/poItemList";


export const CreatePO = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currencyOption, setCurrencyOption] = useState()
    const [stateOption, setStateOption] = useState(SO.STATE)
    const [branchOption, setBranchOption] = useState()
    const [paymentOption, setPaymentOption] = useState()
    const [qaListOption, setQaListOption] = useState()
    const [financeOption, setFinanceOption] = useState(SO.FINANCED)
    const [approverOption, setApproverOption] = useState(SO.APPROVER)
    const [businessLineOption, setBusinessLineOption] = useState(SO.BUSINESS_LINE)
    const [productTypeOption, setProductTypeOption] = useState(SO.PRODUCT_TYPE)
    const [customerOption, setCustomerOption] = useState([])
    const [shipmentTypeOption, setShipmentTypeOption] = useState([])
    const [itemOption, setItemOption] = useState([])
    const rfqResponse = useSelector((state) => state.rfq);
    const sopoResponse = useSelector((state) => state.soPo);
    const [itemSearch, setItemSearch] = useState()
    const [itemRows, setItemRows] = useState([]);
    const [soFiles, setSOFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [manufacturerOptions, setManufacturerOptions] = useState([])
    const [vendorOption, setVendorOption] = useState([])
    const btnRef = useRef(null)
    const [categoryOption, setCategoryOption] = useState([])
    const { state } = useLocation();
    const commonResponse = useSelector((state) => state.common);
    const [soId, setSOId] = useState(state.soData.id)
    const [taxPreferenceOptions, setTaxPreferenceOptions] = useState([])
    const [taxPercentOptions, setTaxPercentOptions] = useState([])
    const loggedInUser = localStorage.getItem("userId");
    const [createdItem, setCreatedItem] = useState()
    const [selectedSoSample, setSelectedSoSample] = useState([])


    const [soDetails, setSODetails] = useState({
        soId: 0,
        purchaseOrder: (uuidv1()).substring(0, 10),
        vendorName: '',
        currency: '',
        placeOfSupply: '',
        destinationSupply: '',
        branch: '',
        reference: '',
        purchaseOrderDate: moment(new Date()).format("yyyy-MM-DD"),
        expectedShipDate: '',
        paymentTerms: '',
        qa: "Groyyo's team",
        financed: '',
        approver: '',
        styleNumber: '',
        businessLine: '',
        productType: '',
        subTotal: 0,
        tax: 0,
        taxType: 'TDS',
        discount: 0,
        total: 0,
        customerNotes: '',
        loggedUser: loggedInUser,
        shipmentType: '',
        creditFrom: 'Groyyo',
        addressType: 'organization',
        deliveryAddress: '',
        status: 0
    })

    useEffect(() => {
        dispatch(getCustomer());
        dispatch(getSupplier());
        dispatch(getCategory());
        dispatch(getSoCommonData());
        dispatch(getItemCommonData());
        if (state) {
            setSelectedSoSample(state.soData.soSamples)
        }
    }, [])


    useEffect(() => {
        let percent = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.discount)
        setSODetails({ ...soDetails, total: parseInt(soDetails.subTotal) - (isNaN(percent) ? 0 : percent) })
    }, [soDetails.discount])

    useEffect(() => {
        let percent = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.discount)
        let finalAmount = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.tax)
        setSODetails({ ...soDetails, total: parseInt(soDetails.subTotal) - (isNaN(percent) ? 0 : percent) + (isNaN(parseInt(finalAmount)) ? 0 : parseInt(finalAmount)) + (isNaN(parseInt(soDetails.shippingCharges)) ? 0 : parseInt(soDetails.shippingCharges)) })
    }, [soDetails.tax])

    useEffect(() => {
        let percent = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.discount)
        let finalAmount = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.tax)
        setSODetails({ ...soDetails, total: parseInt(soDetails.subTotal) - (isNaN(percent) ? 0 : percent) + (isNaN(parseInt(soDetails.shippingCharges)) ? 0 : parseInt(soDetails.shippingCharges)) + (isNaN(parseInt(finalAmount)) ? 0 : parseInt(finalAmount)) })
    }, [soDetails.shippingCharges])

    useEffect(() => {
        if (parseInt(soDetails.subTotal) > 0) {
            setSODetails({ ...soDetails, total: (isNaN(parseInt(soDetails.subTotal)) ? 0 : parseInt(soDetails.subTotal)) })
        }
    }, [soDetails.subTotal])

    useEffect(() => {
        let percent = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.discount)
        let shippingcharge = parseInt(soDetails.subTotal) - (isNaN(percent) ? 0 : percent) + (isNaN(parseInt(soDetails.shippingCharges)) ? 0 : parseInt(soDetails.shippingCharges))
        setSODetails({ ...soDetails, total: shippingcharge + (isNaN(parseInt(soDetails.adjustment)) ? 0 : parseInt(soDetails.adjustment)) })
    }, [soDetails.adjustment])

    useEffect(() => {
        if (commonResponse.actionName === "getCategory" && commonResponse.success) {
            const categoryOptions = commonResponse.category.map((item) => ({
                value: item.id,
                label: item.categoryName,
            }));
            setCategoryOption(categoryOptions)
            dispatch(resetCommon());
        }

    }, [commonResponse]);
   
    useEffect(() => {
        if (sopoResponse.actionName === "getSupplier" && sopoResponse.success) {
            let arr = [];
            if (sopoResponse.suppliers.totalSuppliers.length > 0)
                sopoResponse.suppliers.totalSuppliers.map((item) => {
                    let list = {
                        value: item.contact_id,
                        label: item.contact_name,
                    };
                    arr.push(list);
                })

            setManufacturerOptions(arr.sort((a, b) => a.label?.localeCompare(b.label)));
            let arrItm = [];
            if (sopoResponse.suppliers.totalItems.length > 0)
                sopoResponse.suppliers.totalItems.map((item) => {
                    let list = {
                        value: item.item_id,
                        label: item.item_name,
                        rate: item.rate ? item.rate : 0
                    };
                    arrItm.push(list);
                })
            setItemOption(arrItm.sort((a, b) => a.label?.localeCompare(b.label)));
            dispatch(resetSoPo());
        }

        if (sopoResponse.actionName === "addUpdatePO" && sopoResponse.success) {
            notify('Converted to PO!', 'success')
            handleBack()
            dispatch(resetSoPo())
        }
        if (sopoResponse.actionName === "getSoCommonData" && sopoResponse.success) {
            let soCommonData = sopoResponse.soCommonData
            setCurrencyOption(transformArrayOption(soCommonData.currencies, 'id', 'name'))
            setBranchOption(transformArrayOption(soCommonData.branches, 'id', 'name'))
            setShipmentTypeOption(transformArrayOption(soCommonData.deliveryMethods, 'id', 'name'))
            setPaymentOption(transformArrayOption(soCommonData.paymentTerms, 'id', 'name'))
            setTaxPercentOptions(transformArrayOption(soCommonData.tdsTaxes, 'tax', 'name'))
            dispatch(resetSoPo())
        }
        if (sopoResponse.actionName === "getItemCommonData" && sopoResponse.success) {
            let itemCommonData = sopoResponse.itemCommonData
            setTaxPreferenceOptions(transformArrayOption(itemCommonData.taxPreferences, 'id', 'name'))
            dispatch(resetSoPo())
        }
    }, [sopoResponse]);

    const soSchema = Yup.object().shape({
        vendorName: Yup.string().required("Required"),
        currency: Yup.number().nullable().required("Required"),
        placeOfSupply: Yup.string().nullable().required("Required"),
        destinationSupply: Yup.string().nullable().required("Required"),
        branch: Yup.number().nullable().required("Required"),
        deliveryAddress: Yup.string().nullable().required("Required"),
        purchaseOrderDate: Yup.date().nullable().required("Required"),
        financed: Yup.string().nullable().required("Required"),
        approver: Yup.number().nullable().required("Required"),
        productType: Yup.string().required("Required")
    });
    const handleBack = () => {
        navigate('/so-list')
    }

    const handleChange = (e) => {
        setSODetails({ ...soDetails, [e.target.name]: e.target.value })
    }

    const changeDropdown = (e, name) => {
        setSODetails({ ...soDetails, [name]: e.value })
    }
    const handleSubmit = (type) => {
        let details
        if (type == 'final') {
            details = { ...soDetails, status: 1, soId: soId }
        } else
            details = { ...soDetails,soId: soId }

        let formData = new FormData();
        const fileNames = [];
        soFiles.map((fileSet) => {
            formData.append("upload_file", fileSet.file);
            fileNames.push({
                fileType: "SOFiles",
                fileName: fileSet.fileName,
                isDeleted: fileSet.isDeleted,
            });
        });
        formData.append("poDetails", JSON.stringify(details));
        formData.append("fileList", JSON.stringify(fileNames));
        formData.append("itemRows", JSON.stringify(itemRows));
        dispatch(addUpdatePO(formData))
    }

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()


    const openDropdown = () => {
        btnRef.current.click()
    }

  

    const handleFileSheetUpload = (e, type) => {
        if (e.target.files.length > 10) {
            notify("Only 10 files can be added at a time", "info")
            e.target.value = "";
            return;
        }
        Array.from(e.target.files).forEach(item => {
            const fileExtension = item?.name
                .split(".")
                .pop()
                .toLowerCase();
            var acceptedFormats = ["pdf", "xlsx", "docx", "csv", "xls", "doc", "jpeg", "jpg"];
            if (!acceptedFormats.includes(fileExtension)) {
                notify("File type not supported", "info")
                e.target.value = "";
                return;
            }
            else {
                const { file, newImageName } = getFile(item, fileExtension);
                setSOFiles((soFiles) => [
                    ...soFiles,
                    { file: file, fileName: newImageName },
                ]);
            }
        });
    };

    const getFile = (image, fileExtension) => {
        let newImageName, index;
        const blob = image.slice(0, image.size);
        if (fileExtension == "jpeg" || fileExtension == "jpg" || fileExtension == "png") {
            if (image?.name.indexOf("AM") != -1)
                index = image?.name.indexOf("AM");

            else if (image?.name.indexOf("PM") != -1)
                index = image?.name.indexOf("PM");

            if (index) {
                newImageName =
                    image?.name.slice(0, index - 1) + "_" + image?.name.slice(index);
            }

            else
                newImageName = image?.name;

        }
        else
            newImageName = image?.name;
        const file = new File([blob], newImageName);
        return { file, newImageName };
    };

    const removeFile = (e, index) => {
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure want to delete this file?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        handleDelete(e, index);
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const openAddUpdateItem = (e) => {
        setOpen(true);
    }

    const handleDelete = (e, index) => {
        let temp = [...soFiles]
        temp.splice(index, 1);
        setSOFiles(temp)
    };

    return (
        <>
            <LeftMenu />
            <div className="right-column">
                <Header />
                <div className="body-wrap">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a onClick={handleBack} >SO List </a>
                            </li>
                            <li className="breadcrumb-item">
                                <a> Add PO</a>
                            </li>
                        </ol>
                    </nav>
                    <div className="top-heading">
                        <div className="d-flex">
                            <a onClick={handleBack} className="back">
                                &#8249;
                            </a>
                            <h2> New Purchase Order</h2>
                        </div>
                    </div>
                    <Formik initialValues={soDetails}
                        validationSchema={soSchema}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            handleSubmit('final');
                            // Clear the form after submission
                            resetForm();
                        }}>
                        {({ errors, touched, isValid, handleBlur }) => (
                            <>
                                <Form>
                                    <div className="user-wrap po-section">
                                        <div className="row row-gap-4">
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Vendor Name<span className="star-color"> *</span></label>

                                                <Select
                                                    name="vendorName"
                                                    value={soDetails.vendorName}
                                                    onChange={(e) => changeDropdown(e, 'vendorName')}
                                                    options={manufacturerOptions}
                                                    placeholder={soDetails.vendorName == "" ? 'Search Vendor' : manufacturerOptions.find(x => x.value == soDetails.vendorName)?.label}
                                                />

                                                {errors.vendorName && touched.vendorName ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.vendorName}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Currency <span className="star-color"> *</span></label>
                                                <Select
                                                    name="currency"
                                                    value={soDetails.currency ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'currency')}
                                                    options={currencyOption}
                                                    placeholder={soDetails.currency == "" ? 'Select Currency' : currencyOption.find(x => x.value == soDetails.currency)?.label}
                                                />
                                                {errors.currency && touched.currency ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.currency}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Branch<span className="star-color"> *</span></label>
                                                <Select
                                                    value={soDetails.branch ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'branch')}
                                                    name="branch"
                                                    options={branchOption}
                                                    placeholder={soDetails.branch == "" ? 'Select Branch' : branchOption.find(x => x.value == soDetails.branch)?.label}
                                                />
                                                {errors.branch && touched.branch ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.branch}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Place of supply <span className="star-color"> *</span></label>
                                                <Select
                                                    value={soDetails.placeOfSupply ?? ""}
                                                    name="placeOfSupply"
                                                    onChange={(e) => changeDropdown(e, 'placeOfSupply')}
                                                    options={stateOption}
                                                    placeholder={soDetails.placeOfSupply == "" ? 'Select State' : stateOption.find(x => x.value == soDetails.placeOfSupply)?.label}
                                                />
                                                {errors.placeOfSupply && touched.placeOfSupply ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.placeOfSupply}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Destination of supply <span className="star-color"> *</span></label>
                                                <Select
                                                    value={soDetails.destinationSupply ?? ""}
                                                    name="destinationSupply"
                                                    onChange={(e) => changeDropdown(e, 'destinationSupply')}
                                                    options={stateOption}
                                                    placeholder={soDetails.destinationSupply == "" ? 'Select State' : stateOption.find(x => x.value == soDetails.destinationSupply)?.label}
                                                />
                                                {errors.destinationSupply && touched.destinationSupply ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.destinationSupply}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Delivery Address</label>
                                                <br />  <input
                                                    style={{ marginTop: '5px' }}
                                                    type="radio"
                                                    name="addressType"
                                                    className="form-check-input"
                                                    onChange={handleChange}
                                                    value="organization"
                                                    defaultChecked='true'
                                                />&nbsp; <span> Organization </span> &nbsp; &nbsp;
                                                <input
                                                    style={{ marginTop: '5px' }}
                                                    type="radio"
                                                    name="addressType"
                                                    className="form-check-input"
                                                    onChange={handleChange}
                                                    value="customer"
                                                />&nbsp; <span> Customer </span>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">{soDetails.addressType == 'customer' ? "Customer's" : "Groyyo's"} Addresses<span className="star-color"> *</span></label>
                                                <Select
                                                    value={soDetails.deliveryAddress ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'deliveryAddress')}
                                                    name="deliveryAddress"
                                                    options={soDetails.addressType == 'customer' ? stateOption : branchOption}
                                                    placeholder={soDetails.deliveryAddress == "" ? 'Select Address: All' : (soDetails.addressType == 'customer' ? stateOption : branchOption).find(x => x.value == soDetails.deliveryAddress)?.label}
                                                />
                                                {errors.deliveryAddress && touched.deliveryAddress ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.deliveryAddress}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Reference#</label>
                                                <input
                                                    type="text"
                                                    name="reference"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={soDetails.reference}
                                                />

                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Purchase Order Date<span className="star-color"> *</span></label>
                                                <input
                                                    type="date"
                                                    name="purchaseOrderDate"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onClick={handleDateClick}
                                                    value={soDetails.purchaseOrderDate}
                                                />
                                                {errors.purchaseOrderDate && touched.purchaseOrderDate ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.purchaseOrderDate}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Expected Shipment Date</label>
                                                <input
                                                    type="date"
                                                    name="expectedShipDate"
                                                    className="form-control"
                                                    onClick={handleDateClick}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={soDetails.expectedShipDate}
                                                />

                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">QA<span className="star-color"> *</span></label>

                                                <input
                                                    type="text"
                                                    disabled={true}
                                                    className="form-control"
                                                    value={"Groyyo's team"}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Payment Terms</label>

                                                <Select
                                                    value={soDetails.paymentTerms ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'paymentTerms')}
                                                    name="paymentTerms"
                                                    options={paymentOption}
                                                    placeholder={soDetails.paymentTerms == "" ? 'Select Payment Terms' : paymentOption.find(x => x.value == soDetails.paymentTerms)?.label}
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Style Number</label>
                                                <input
                                                    type="text"
                                                    name="styleNumber"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={soDetails.styleNumber}
                                                />

                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Financed<span className="star-color"> *</span></label>

                                                <Select
                                                    value={soDetails.financed ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'financed')}
                                                    name="financed"
                                                    options={financeOption}
                                                    placeholder={soDetails.financed == "" ? 'Select Financed' : financeOption.find(x => x.value == soDetails.financed)?.label}
                                                />
                                                {errors.financed && touched.financed ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.financed}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Credits From<span className="star-color"> *</span></label>

                                                <input
                                                    type="text"
                                                    disabled={true}
                                                    className="form-control"
                                                    value={'Groyyo'}
                                                />

                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Shipment Type</label>

                                                <Select
                                                    value={soDetails.shipmentType ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'shipmentType')}
                                                    name="shipmentType"
                                                    options={shipmentTypeOption}
                                                    placeholder={soDetails.shipmentType == "" ? 'Select Shipment Type' : shipmentTypeOption.find(x => x.value == soDetails.shipmentType)?.label}
                                                />

                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Business Line</label>

                                                <Select
                                                    value={soDetails.businessLine ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'businessLine')}
                                                    name="businessLine"
                                                    options={businessLineOption}
                                                    placeholder={soDetails.businessLine == "" ? 'Select Business' : businessLineOption.find(x => x.value == soDetails.businessLine)?.label}
                                                />
                                                {errors.businessLine && touched.businessLine ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.businessLine}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Product Type<span className="star-color"> *</span></label>

                                                <Select
                                                    value={soDetails.productType ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'productType')}
                                                    name="productType"
                                                    options={productTypeOption}
                                                    placeholder={soDetails.productType == "" ? 'Select Product Type' : productTypeOption.find(x => x.value == soDetails.productType)?.label}
                                                />
                                                {errors.productType && touched.productType ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.productType}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Approver<span className="star-color"> *</span></label>

                                                <Select
                                                    value={soDetails.approver ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'approver')}
                                                    name="approver"
                                                    options={approverOption}
                                                    placeholder={soDetails.approver == "" ? 'Select Approver' : approverOption.find(x => x.value == soDetails.approver)?.label}
                                                />
                                                {errors.approver && touched.approver ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.approver}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <PoItemList 
                                         openAddUpdateItem={openAddUpdateItem}
                                         itemRows={itemRows}
                                         setItemRows={setItemRows}
                                         setSODetails={setSODetails}
                                         soDetails={soDetails}
                                         setItemSearch={setItemSearch}
                                         taxPreferenceOptions={taxPreferenceOptions}
                                         categoryOption={categoryOption}
                                         handleBlur={handleBlur}
                                         itemSearch={itemSearch}
                                         itemOption={itemOption}
                                         createdItem={createdItem}
                                         selectedSoSample={selectedSoSample}
                                        />
                                        <br />
                                        <div className="row dv-bill-board">
                                            <div className="col-md-7" >
                                                <div className="dv-cstrnot-area">
                                                    <span className="dv-cstmr-nts">Customer Notes </span>
                                                    <textarea defaultValue={soDetails.customerNotes} name="customerNotes" onChange={handleChange} className="form-control txt-area" placeholder="Enter any notes to be displayed in your transaction"></textarea>
                                                </div>
                                                <div className="mt-3">
                                                    <span className="dv-cstmr-nts">Terms & Conditions</span>
                                                    <textarea className="form-control txt-area" rows="7" defaultValue={TERMS_CONDITIONS}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-5 " >
                                                <div className="dv-bill">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p style={{ fontWeight: 'bold' }}>
                                                                Sub Total
                                                            </p>
                                                        </div>
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-2">
                                                            <p>{numberFormat(soDetails.subTotal)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-4">
                                                            <p>Discount
                                                            </p>
                                                        </div>
                                                        <div className="col-md-3" style={{ display: 'flex' }}>
                                                            <input
                                                                type="number"
                                                                min={0}
                                                                onKeyDown={preventNegativeValues}
                                                                name="discount"
                                                                className="form-control"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={soDetails.discount}
                                                            />
                                                            <small className="sml-percent">%</small>
                                                        </div>
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-2">
                                                            <p>{numberFormat((soDetails.subTotal / 100) * soDetails.discount)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">

                                                            <p className="dv-bill-tds" >
                                                                <input
                                                                    style={{ marginTop: '7px' }}
                                                                    type="radio"
                                                                    min={0}
                                                                    onKeyDown={preventNegativeValues}
                                                                    name="taxType"
                                                                    className="form-check-input"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value="TDS"
                                                                    defaultChecked={true}
                                                                />&nbsp; <span> TDS </span>
                                                                &nbsp;&nbsp;
                                                                <input
                                                                    style={{ marginTop: '7px' }}
                                                                    type="radio"
                                                                    min={0}
                                                                    onKeyDown={preventNegativeValues}
                                                                    name="taxType"
                                                                    className="form-check-input"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value="TCS"
                                                                />&nbsp;
                                                                <span> TCS </span>
                                                            </p>

                                                        </div>
                                                        <div className="col-md-5" style={{ height: '30px' }}>
                                                            {soDetails.taxType == 'TDS' ? <Select
                                                                name="tax"
                                                                onChange={(e) => changeDropdown(e, 'tax')}
                                                                options={taxPercentOptions}
                                                                placeholder={soDetails.tax ? taxPercentOptions.find(x => x.value == soDetails.tax)?.label : 'Tax Type: All'}
                                                            /> :

                                                                <div style={{ display: 'flex' }}>
                                                                    <input
                                                                        type="number"
                                                                        min={0}
                                                                        onKeyDown={preventNegativeValues}
                                                                        name="tax"
                                                                        className="form-control"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={soDetails.tax}
                                                                    />
                                                                    <small className="sml-percent">%</small>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="col-md-1">

                                                        </div>
                                                        <div className="col-md-2">
                                                            <p>{numberFormat((soDetails.subTotal / 100) * soDetails.tax)}</p>

                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <p style={{ fontWeight: 'bold' }}>
                                                                Total(₹)
                                                            </p>
                                                        </div>
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-2">
                                                            <p>{numberFormat(soDetails.total)}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-3">
                                                    <span className="dv-cstmr-nts">Attach File(s) to sales order </span>
                                                    {soFiles.length > 0 && <div style={{ marginTop: '5px' }} className="dv-file-lst row">
                                                        {soFiles.map(
                                                            (item, index) => item &&
                                                                <div className="col-md-4">
                                                                    <span className="spn-fle">
                                                                        Sheet {index + 1}
                                                                    </span>{" "}
                                                                    &nbsp;
                                                                    <img
                                                                        onClick={(e) => {
                                                                            removeFile(e, index);
                                                                        }}
                                                                        src={deleteImg}
                                                                        className="smll-dlte"
                                                                    />
                                                                </div>
                                                        )}
                                                    </div>}
                                                    <div className="position-relative custom-upload">

                                                        <div className="upload dv-so-file">
                                                            <div className="upload-img">
                                                                <img src={uploadImg} alt="upload" />
                                                            </div>
                                                            <div className="upload-detail" style={{ fontSize: "12px" }}>
                                                                <div>
                                                                    Drop your File(s)
                                                                </div>
                                                                <div>Max 10 files can be uploaded, 10MB each</div>
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            className="custom-file"
                                                            name="soFiles"
                                                            multiple="multiple"
                                                            accept="image/jpg, image/jpeg, .pdf, .xlsx, .xls, .docs, .csv, .doc"
                                                            onBlur={handleBlur}
                                                            onChange={(event) => {
                                                                handleFileSheetUpload(event, "tech");
                                                            }}
                                                        />
                                                        {errors.soFiles &&
                                                            touched.soFiles ? (
                                                            <div className="invalid-feedback d-block">
                                                                {errors.soFiles}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        {/* <div className="row dv-bill-board">
                                            <div className="col-md-7" >
                                                
                                            </div>
                                            <div className="col-md-5" >
                                                
                                            </div>
                                        </div> */}
                                        <br />
                                        <button className="btn ornge-brdr-btn" onClick={(e) => handleSubmit('draft')} type="button" >Save as Draft</button> &nbsp;
                                        <button className="btn ornge-btn" type="submit" >Save and Submit</button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                    {open && <AddUpdateItem open={open} setOpen={setOpen} manufacturerOptions={manufacturerOptions} setManufacturerOptions={setManufacturerOptions}
                        setCategoryOption={setCategoryOption} categoryOption={categoryOption} customerOption={customerOption} setCustomerOption={setCustomerOption} setCreatedItem={setCreatedItem}/>
                    }
                </div>
            </div>
        </>
    );
};
