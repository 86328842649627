import React, { useEffect, useState, Component } from "react";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field, Formik } from "formik";
import { notify } from "../../features/notification/notify";
import * as Yup from "yup";
import { useLocation } from "react-router";
import { resetRfq } from "../../features/rfq/slice";
import { useNavigate } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { Modal } from "react-bootstrap";

import { Document, Page, pdfjs } from 'react-pdf';

//pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs`;



export const ModalForPreviewOfFile = (props) => {
    const { openModalForPreview, setOpenModalForPreview, setPdfUrl, pdfUrl, fileType, setFileType } = props;
    const handleCloseModal = () => {
        setOpenModalForPreview(false);
        setPdfUrl(null);
        setFileType(null);
    };
    const docs = [
        {
            uri: pdfUrl,
            fileType: fileType,
        }
    ];

    

    return (

        <Modal show={openModalForPreview} className="custom-modal" centered size="xl">
            <Modal.Header>
                <h2>
                    <button type="button" onClick={handleCloseModal} className="btn">
                        &#8249;
                    </button>
                    Preview
                </h2>

            </Modal.Header>

            <Modal.Body>
                <div className="body-wrap">
                    <div className="sample-detail-wrap">
                        {/* <div>
                            <Document
                                file={pdfUrl}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
                                <p>
                                    Page {pageNumber} of {numPages}
                                </p>
                            </Document>
                            <div>
                                <div className="pagec">
                                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                                </div>
                                <div className="buttonc">
                                    <button
                                        type="button"
                                        disabled={pageNumber <= 1}
                                        onClick={previousPage}
                                        className="Pre"

                                    >
                                        Previous
                                    </button>
                                    <button
                                        type="button"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}

                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div> */}

                        {/* <FileViewer
                            fileType={fileType}
                            filePath={pdfUrl}
                            /> */}
                        <div>
                            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
                        </div>
                    </div>
                </div>


            </Modal.Body>

            <Modal.Footer>


            </Modal.Footer>
        </Modal>

    )

}