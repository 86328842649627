import { useEffect, useRef, useState, useCallback } from "react";
import { addSampleToRfq, getSampleByRfqId, replyBid } from "../../features/rfq/action";
import { useDispatch, useSelector } from "react-redux";
import { resetRfq } from "../../features/rfq/slice";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import { notify } from "../../features/notification/notify";
import { Form, Formik } from "formik";
import { releaseRfq } from "../../features/rfq/action";
import { useNavigate } from "react-router-dom";
import { getUserByRoleName } from "../../features/userDetail/action";
import { resetUserDetail } from "../../features/userDetail/slice";
import { getRfqById } from "../../features/rfq/action";
import { string } from "yup/lib/locale";
export const ReplyFactory = (props) => {
    const { acceptFrom, rfqId, createdBidBy, defaultBid, setDefaultBid, bidTo, bidToBe,
        setBidToBe, } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    const [userInfo, setUserInfo] = useState([]);
    const [samples, setSamples] = useState([]);
    const [rfqDetail, setRfqDetail] = useState();
    let currentUser = JSON.parse(localStorage.getItem('authorization'))
    const loginUserName = currentUser.user.name;
    const [factoryDetail, setFactoryDetail] = useState({
        notifyTo: "", subject: "", regard: "", type: "", rfqId: "", createdBy: userId, loginUser: loginUserName, factoryCreatedBy: ""
    })
    const [storeSampleInfo, setStoreSampleInfo] = useState([]);
    const btnRef = useRef(null);
    const rfqResponse = useSelector((state) => state.rfq);
    const userResponse = useSelector((state) => state.userDetail);
    const [sampleElement, setSampleElement] = useState([]);
    const [customFieldList, setCustomFieldList] = useState([]);
    const [cstmField, setCstmField] = useState();
    //const [makeField, setMakeField] = useState([]);
    useEffect(() => {
        setFactoryDetail({
            ...factoryDetail,
            rfqId: rfqId,
        });
        if (rfqId) {
            dispatch(getSampleByRfqId(rfqId))
            dispatch(getRfqById(rfqId));
        }

    }, [rfqId]);

    useEffect(() => {

        if (bidTo) {
            setFactoryDetail({
                ...factoryDetail,
                notifyTo: bidTo,
                factoryCreatedBy: createdBidBy
            });
        }

    }, [bidTo]);

    useEffect(() => {
        setSampleElement([]);
    }, [bidToBe])

    useEffect(() => {
        if (defaultBid) {
            setCustmField(null);
        }
    }, [defaultBid]);

    // console.log("reply details are", defaultBid);
    // console.log("sample elements are", sampleElement);
    // console.log("store samples",storeSampleInfo)
    // console.log("samples are",samples)
    // console.log("setCustomFieldList", customFieldList)
    const setCustmField = useCallback((condition) => {
        let temp = [...sampleElement]
        let temp1 = [...customFieldList]
        if (condition && condition == 'reset') {
            temp = []
            temp1 = []
        }
        // let storeField = [];

        defaultBid?.bidSamples?.map((data, i) => {

            if (data.quantity)
                temp.push({ sampleId: data.id, fieldName: "Quantity", isCstm: true })

            if (data.price)
                temp.push({ sampleId: data.id, fieldName: "Price", isCstm: true })

            if (data.moq)
                temp.push({ sampleId: data.id, fieldName: "MOQ", isCstm: true })

            if (data.target)
                temp.push({ sampleId: data.id, fieldName: "Target", isCstm: true })

            if (data.leadTime)
                temp.push({ sampleId: data.id, fieldName: "Lead_Time", isCstm: true })

            defaultBid?.customFieldInfo?.map((item, j) => {
                item?.sampleCustomFields?.map((smp, k) => {
                    if (i == j)
                        temp.push({ sampleId: data.id, bidSampleId: smp.bidSampleId, fieldName: smp.label, isCstm: true })
                })
            })

        })

        // defaultBid?.customFieldInfo?.map((item, j) => {
        //     item?.sampleCustomFields?.map((smp, k) => {
        //         storeField.push({ sampleId: smp.bidSampleId, fieldName: smp.label, isCstm: true })
        //     })
        // })

        // defaultBid?.customFieldInfo?.map((data, i) => {
        //     data?.sampleCustomFields.map((item, j) => {
        //         storeField.push({ sampleId: data.id, fieldName: item.label, isCstm: true })
        //     })
        // })

        // samples.rfqSamplesInfo.map(smple => {
        //     if (smple.sampleDetail?.bidSamples && smple.sampleDetail?.bidSamples.length > 0) {
        //         let smpleInfo = smple.sampleDetail?.bidSamples.find(x => x.sampleId == smple.sampleId && x.bidId == defaultBid.id)
        //         if (smpleInfo) {
        //             temp.push({ sampleId: smple.sampleId, fieldName: "Price", isCstm: false },
        //                 { sampleId: smple.sampleId, fieldName: "Lead_Time", isCstm: false },
        //                 { sampleId: smple.sampleId, fieldName: "MOQ", isCstm: false },)
        //             if (smpleInfo.sampleCustomFields && smpleInfo.sampleCustomFields.length > 0) {
        //                 smpleInfo.sampleCustomFields.map(cstm => {
        //                     if (cstm.isShow && cstm.isRelease) {
        //                         temp.push({ sampleId: smple.sampleId, fieldName: cstm.label, isCstm: true })
        //                         temp1.push({ bidSampleId: smple.sampleId, label: cstm.label, value: null })
        //                     }
        //                 })
        //             }
        //         }

        //     }
        // })
        //setMakeField(storeField);
        setSampleElement(temp);
        setCustomFieldList(temp1);
    }, [defaultBid])

    useEffect(() => {
        dispatch(getUserByRoleName('Supplier'));
    }, []);


    useEffect(() => {
        if (rfqResponse.actionName === "getSampleByRfqId" && rfqResponse.rfqSampleList) {
            setSamples(rfqResponse.rfqSampleList);
            dispatch(resetRfq());
        }

        if (rfqResponse.actionName === "replyBid" && rfqResponse.success) {
            if (acceptFrom != 'internal')
                notify('Reply sent', 'success');
            setFactoryDetail({
                notifyTo: bidTo, subject: "", regard: "", type: "", rfqId: rfqId, createdBy: userId, loginUser: loginUserName, factoryCreatedBy: createdBidBy
            })
            setSamples([]);
            setStoreSampleInfo([]);
            setDefaultBid();
            dispatch(resetRfq());
            dispatch(getSampleByRfqId(rfqId))
            setCstmField();
            if (bidTo && defaultBid) {
                setCustmField('reset');
            }
        }
        if (rfqResponse.actionName == "getRfqById" && rfqResponse.success) {
            setRfqDetail(rfqResponse.rfqData);
            dispatch(resetRfq());
        }

    }, [rfqResponse]);

    useEffect(() => {
        if (userResponse.actionName === "userByRoleName" && userResponse.success) {
            setUserInfo(userResponse.roleDetails);
            dispatch(resetUserDetail())
        }
    }, [userResponse]);

    const handleChange = (e, bidSampleId, sampleId, sampleName) => {
        const { name, value } = e.target;

        if (e.target.name == "Quantity"||e.target.name == "Price" || e.target.name == "MOQ" || e.target.name == "Target" || e.target.name == "Lead_Time") {
            setStoreSampleInfo((prevData) => ({
                ...prevData,
                [sampleId]: {
                    ...prevData[sampleId],
                    sampleName: sampleName,
                    [name]: value,
                },
            }));
        }
        else {
            setStoreSampleInfo((prevData) => ({
                ...prevData,
                [sampleId]: {
                    ...prevData[sampleId],
                    sampleName: sampleName,
                    [name]: value,
                    bidSampleId: bidSampleId
                },
            }));
        }

    }

    const handleInput = (e) => {
        const { name, type, value, checked } = e.target;
        if (type == 'checkbox') {
            setFactoryDetail({
                ...factoryDetail,
                [name]: checked,
            });
        }
        else {
            setFactoryDetail({
                ...factoryDetail,
                [e.target.name]: e.target.value,
            });
        }
    }

    const handleSubmit = () => {
        let bidSample = {};
        Object.keys(storeSampleInfo).forEach((key) => {
            var data = customFieldList.filter(x => x.bidSampleId == key)
            var result = {
                ...storeSampleInfo[key],
                customField: data
            }
            bidSample[key] = result;
        });

        const allDetails = {
            rfqDetails: rfqDetail.Users,
            rfqId: rfqId,
            bidId: defaultBid?.info.id,
            bidDetails: factoryDetail,
            bidSamples: bidSample,
        };
        dispatch(replyBid(allDetails));
        btnRef.current.click();
    }

    const factorySchema = Yup.object().shape({
        type: acceptFrom != 'internal' ? Yup.string().required("Required") : null,
        subject: acceptFrom != 'internal' ? Yup.string().required("Required") : null,
        regard: acceptFrom != 'internal' ? Yup.string().required("Required") : null,
    });
    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()

    const addCustomField = () => {
        let temp = [...sampleElement];

        let temp1 = [...customFieldList];
        samples?.rfqSamplesInfo?.map(element => {
            temp.push({ sampleId: element.sampleId, fieldName: cstmField, isCstm: false })
            temp1.push({ bidSampleId: element.sampleId, label: cstmField, value: null })
        })
        setSampleElement(temp)
        setCustomFieldList(temp1)
        setCstmField('')
    }


    const handleCustomField = (e, sampleId, element) => {
        var temp = customFieldList
        temp?.map(x => {
            if (x.bidSampleId == sampleId && x.label == element.fieldName) {
                x['value'] = e.target.value
            }
        })
        setCustomFieldList(temp)
    }

    return (
        <>
            <Formik
                initialValues={factoryDetail}
                validationSchema={factorySchema}
                enableReinitialize={true}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values);
                    resetForm();
                }}
            >
                {({ errors, touched, isValid, handleBlur, resetForm }) => (
                    <>
                        <Form>
                            <div className="modal fade rfq" id="reply-factory" tabIndex="-1" aria-labelledby="reply-factoryLabel"
                                aria-hidden="true">
                                <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button ref={btnRef} type="button" className="btn" data-bs-dismiss="modal">&#8249;</button>
                                            <h2>{acceptFrom != 'internal' ? 'Reply For Bid(Factory)' : 'Accept Bid'} </h2>
                                        </div>
                                        <div className="modal-body">
                                            {acceptFrom != 'internal' &&
                                                <div className="row align-items-center mb-3">
                                                    <label className="form-label col-lg-2 col-md-3 col-sm-4">Notify To :</label>
                                                    <div className="col-lg-10 col-md-9 col-sm-8">
                                                        <input name="notifyTo" value={bidTo} disabled
                                                            className="form-control" />
                                                        {errors.subject ? (
                                                            <div className="invalid-feedback d-block">
                                                                {errors.subject}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>}
                                            {acceptFrom != 'internal' &&
                                                <div className="row align-items-center mb-3">
                                                    <label className="form-label col-lg-2 col-md-3 col-sm-4">Subject :</label>
                                                    <div className="col-lg-10 col-md-9 col-sm-8">
                                                        <input
                                                            type="text"
                                                            name="subject"
                                                            className="form-control"
                                                            onChange={handleInput}
                                                            onBlur={handleBlur}
                                                            value={factoryDetail.subject}
                                                        />
                                                        {errors.subject && touched.subject ? (
                                                            <div className="invalid-feedback d-block">
                                                                {errors.subject}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>}
                                            {acceptFrom != 'internal' &&
                                                <div className="row align-items-center mb-3">
                                                    <label className="form-label col-lg-2 col-md-3 col-sm-4">Type:</label>
                                                    <div className="col-lg-10 col-md-9 col-sm-8">
                                                        <input
                                                            type="text"
                                                            name="type"
                                                            className="form-control"
                                                            onChange={handleInput}
                                                            onBlur={handleBlur}
                                                            value={factoryDetail.type}
                                                        />
                                                        {errors.type && touched.type ? (
                                                            <div className="invalid-feedback d-block">
                                                                {errors.type}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            }
                                            {samples?.rfqSamplesInfo && samples.rfqSamplesInfo.length > 0 &&
                                                samples?.rfqSamplesInfo?.map((sample, index) => (
                                                    <div>
                                                        <div className="separate-label" >{sample.sampleDetail.sampleName} | Product | {sample.sampleDetail.id}</div>
                                                        {sampleElement?.map((element, idx) => (
                                                            element.sampleId == sample.sampleDetail.id &&
                                                            <div className="row align-items-center mb-3">
                                                                <label className="form-label col-lg-2 col-md-3 col-sm-4">
                                                                    {element.fieldName}:
                                                                </label>

                                                                <div style={{ display: 'flex' }} className="col-lg-10 col-md-9 col-sm-8">
                                                                    <input
                                                                        type={ element.fieldName == "Quantity" || element.fieldName == "Price" || element.fieldName == "Target" || element.fieldName == "Lead_Time" ? 'number' : 'string'}
                                                                        min={1}
                                                                        name={element.fieldName || element.label}
                                                                        className="form-control"
                                                                        onChange={(e) => {
                                                                            if (element.isCstm)
                                                                                handleChange(e, element.bidSampleId, sample.sampleDetail.id, sample.sampleDetail.sampleName)
                                                                            else
                                                                                handleCustomField(e, sample.sampleDetail.id, element)
                                                                        }
                                                                        }
                                                                        onBlur={handleBlur}
                                                                        onKeyDown={preventNegativeValues}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            <div className="row-md-12 text-center mb-3" >

                                                <button
                                                    type="button"
                                                    className=" dropdown-toggle btn ornge-brdr-btn"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Add Field
                                                </button>
                                                <div class="dropdown-menu dv-drpdn">
                                                    <label className="form-label">
                                                        Field Name
                                                    </label>

                                                    <div className="dv-cstmfld">

                                                        <input
                                                            value={cstmField}
                                                            onChange={(e) => setCstmField(e.target.value.replace(' ', '_'))}
                                                            type="text"
                                                            name="fieldName"
                                                            className="form-control"
                                                        />
                                                        &nbsp;
                                                        <div style={{ textAlign: 'center' }}>
                                                            <button onClick={addCustomField} disabled={!cstmField || cstmField?.trim().length == 0}
                                                                type="submit"
                                                                className="btn btn-rst-smpl"
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            {acceptFrom != 'internal' &&   <div className="modal-textarea">
                                                <span>Hello,</span>
                                                <textarea rows="5"
                                                    type="text"
                                                    name="regard"
                                                    className="form-control"
                                                    onChange={handleInput}
                                                    onBlur={handleBlur}
                                                    value={factoryDetail.regard}
                                                />
                                                {errors.regard && touched.regard ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.regard}
                                                    </div>
                                                ) : null}
                                                <span>Thanks and Regard</span>
                                            </div> }
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={(e) => setDefaultBid()} type="button" className="btn ornge-brdr-btn" data-bs-dismiss="modal">Discard</button>
                                            <button type="submit" onClick={resetForm} className="btn ornge-brdr-btn" >Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    );
}