export const SO = {
    PDC_OPTION: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '2' }
    ],    
    FINANCED: [
        { label: 'Yes', value: '1' },
        { label: 'No', value: '2' }
    ],
    APPROVER: [
        { label: 'Bhargavi Panwar', value: '1' },
        { label: 'Saim Akhtar', value: '2' }
    ],
    BUSINESS_LINE: [
        { label: 'Sourcing', value: '1' },
        { label: 'Trading', value: '2' },
        { label: 'SAAS', value: '3' },
        { label: 'Consulting', value: '4' }
    ],
    PRODUCT_TYPE: [
        { label: 'Fabric', value: '1' },
        { label: 'Garment', value: '2' },
        { label: 'Yarn', value: '3' },
        { label: 'Others', value: '4' }
    ],
    STATE : [
        { value: 'AN', label: 'Andaman & Nicobar'},
        { value: 'AP', label: 'Andhra Pradesh'},
        { value: 'AD', label: 'Andhra Pradesh(New)'},
        { value: 'AR', label: 'Arunachal Pradesh'},
        { value: 'AS', label: 'Assam'},
        { value: 'BR', label: 'Bihar'},
        { value: 'CH', label: 'Chandigarh'},
        { value: 'CT', label: 'Chhattisgarh'},
        { value: 'DD', label: 'Daman & Diu'},
        { value: 'DL', label: 'Delhi'},
        { value: 'DN', label: 'Dadra and Nagar Haveli'},
        { value: 'GA', label: 'Goa'},
        { value: 'GJ', label: 'Gujarat'},
        { value: 'HR', label: 'Haryana'},
        { value: 'HP', label: 'Himachal Pradesh'},
        { value: 'JK', label: 'Jammu & Kashmir'},
        { value: 'JH', label: 'Jharkhand'},
        { value: 'KA', label: 'Karnataka'},
        { value: 'KL', label: 'Kerala'},
        { value: 'LD', label: 'Lakshadweep'},
        { value: 'MP', label: 'Madhya Pradesh'},
        { value: 'MH', label: 'Maharashtra'},
        { value: 'MN', label: 'Manipur'},
        { value: 'ML', label: 'Meghalaya'},
        { value: 'MZ', label: 'Mizoram'},
        { value: 'NL', label: 'Nagaland'},
        { value: 'OR', label: 'Odisha'},
        { value: 'PB', label: 'Punjab'},
        { value: 'PY', label: 'Puducherry'},
        { value: 'RJ', label: 'Rajasthan'},
        { value: 'SK', label: 'Sikkim'},
        { value: 'TN', label: 'Tamil Nadu'},
        { value: 'TR', label: 'Tripura'},
        { value: 'TS', label: 'Telangana'},
        { value: 'UK', label: 'Uttarakhand'},
        { value: 'UP', label: 'Uttar Pradesh'},
        { value: 'WB', label: 'West Bengal'}
    ]
}

export const TERMS_CONDITIONS =`a. All disputes between the parties will be referred to arbitrator at Gurgaon, Haryana.
b. All terms mentioned herein above cannot to amended by way of any correspondence or document sent by the other parties except by written consent of Groyyo Pvt Ltd.

1) Garments have to match hand feel, shrinkage, spirality, pilling and look as per approved sample
2) No Fabric Liability to be placed on  Groyyo if the supplier booked the fabric without approvals
3) Production approval will be given after approval of PP Sample.
4) The shipment can be dispatched only after TOP sample Approval.
5) Maximum seven days delay from schedule will be acceptable without penalty.
6)  8 to 13 days delayed from Plan schedule -10% discount of the total bill to be charged.
7) 13 to 18 days delayed from Plan schedule -20% discount will be charged /or AIR cost of the shipment.
8) The vessel booking needs to be done 15 days prior to delivery, and shipment must be delivered at port 4 days before dispatch date,
9)In case of any claim/chargeback from the buyer due to quality issues it will be the supplier's responsibility.
10) Any packing defect such as missing Barcode / Wrong Barcode / Double Barcode or any such error resulting in any claim for weights/measures act will be the sole responsibility of the job worker/supplier.
11) If there are any leftover units that are not in complete prepacks, request approval from Buyer before shipping, it needs to be informed well on time before shipping.

Additional T&C's If any`


export const transformArrayOption = (array, valueKey, labelKey) => {
    return array.map(item => ({
        value: item[valueKey],
        label: item[labelKey]
    }));
};

export const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    }).format(value);