import React, { useEffect, useState } from "react";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { CONSTANT } from "../../utils/constant";
import ReactPaginate from "react-paginate";
import manpic from "../../assets/images/man.jpg";
import { ToastContainer } from "react-toastify";
import profilepic from "../../assets/images/profile-photo.png";
import downloadicon from "../../assets/images/downloadicon.png";
import deleteicon from "../../assets/images/deleteicon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSampleById, deleteSampleFile } from "../../features/sample/action";
import { confirmAlert } from "react-confirm-alert";
import { resetSample } from "../../features/sample/slice";
import editImg from "../../assets/images/edit.svg";
import trashImg from "../../assets/images/trash.svg";
import importImg from "../../assets/images/import.svg";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import { getUserByRoleName } from "../../features/userDetail/action";
import { getRfqBySampleId } from "../../features/rfq/action";
import { resetUserDetail } from "../../features/userDetail/slice";
import { rfq } from "../../features/rfq/slice";
export const SampleDetail = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const sampleResponse = useSelector((state) => state.sample);
  const rfqResponse = useSelector((state) => state.rfq);
  const [sampleDetail, setSampleDetail] = useState();
  const [mainImage, setMainImage] = useState();
  const navigate = useNavigate();
  const [tabNo, setTabNo] = useState(1);
  const [sampleId, setSampleId] = useState();
  const [active, setActive] = useState(false);
  const userResponse = useSelector((state) => state.userDetail);
  const [buyer, setBuyer] = useState([]);
  const [factory, setFactory] = useState([]);
  const [mill, setMill] = useState([]);
  const [rfqs, setRfqs] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const [ttlRfq, setTtlRfq] = useState(0);
  const changeColor = () => {
    setActive(!active);
  };
  useEffect(() => {
    if (state && state.sampleId) {
      dispatch(getSampleById(state.sampleId));
      dispatch(
        getRfqBySampleId({
          page: currentPage + 1,
          limit: itemCount,
          sampleId: state.sampleId,
        })
      );
      setSampleId(state.sampleId);
    }
  }, []);
  const getColorClass = (status) => {
    switch (status) {
      case "On Track":
        return "status green";
      case "On Hold":
        return "status yellow";
      case "Delayed":
        return "status red";
      case "Critical":
        return "status blue";
      case "Completed":
        return "status orange";
      default:
        return "";
    }
  };
  useEffect(() => {
    if (sampleResponse.actionName == "getSampleById" && sampleResponse.sample) {
      setSampleDetail(sampleResponse.sample);
      if (sampleResponse.sample.sampleFiles.length > 0)
        setMainImage(
          sampleResponse.sample.sampleFiles.filter(
            (file) => file.fileType === "image"
          )[0]
        );
      dispatch(resetSample());
    } else if (sampleResponse.actionName == "deleteSampleFile") {
      dispatch(getSampleById(sampleId));
    }
  }, [sampleResponse]);
  useEffect(() => {
    if (
      rfqResponse.actionName == "getRfqBySampleId" &&
      rfqResponse.samplesRfq
    ) {
      setTtlRfq(rfqResponse.samplesRfq[1]);
      setRfqs(rfqResponse.samplesRfq[0]);
      if (rfqResponse?.samplesRfq[1] !== null && pageCount === 0) {
        setPageCount(Math.ceil(rfqResponse.samplesRfq[1] / itemCount));
      }
    }
  }, [rfqResponse]);
  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };
  useEffect(() => {
    dispatch(getUserByRoleName("Supplier,Buyer"));
  }, []);

  useEffect(() => {
    if (userResponse.actionName === "userByRoleName") {
      let supp = userResponse.roleDetails.filter(
        (x) => x.Roles[0].role == "Supplier"
      );
      let buy = userResponse.roleDetails.filter(
        (x) => x.Roles[0].role == "Buyer"
      );
      dispatch(resetUserDetail());
      let temp1 = supp.find(
        (x) => x.id == parseInt(sampleDetail?.samplingFactory)
      );
      setFactory(temp1?.name ? temp1.name : temp1?.emailId);
      let temp2 = buy.find((x) => x.id == parseInt(sampleDetail?.buyer));
      setBuyer(temp2?.name ? temp2.name : temp2?.emailId);
      let tempMill = supp.find(
        (x) => x.id == parseInt(sampleDetail?.samplingMill)
      );
      setMill(tempMill?.name ? tempMill.name : tempMill?.emailId);
    }
  }, [userResponse]);
  const selectImage = (i) => {
    setMainImage(
      sampleDetail.sampleFiles.filter((file) => file.fileType === "image")[
        i + 1
      ]
    );
  };
  const handleEditClick = (e) => {
    if (tabNo === 1) {
      navigate("/edit-sample", {
        replace: true,
        state: { sampleId: sampleId },
      });
    } else if (tabNo === 2) {
      navigate("/edit-image", {
        replace: true,
        state: { sampleId: sampleId },
      });
    }
  };
  const handleBack = () => {
    navigate("/sample-list");
  };
  const rfqDetails = (rfqId) => {
    navigate("/rfq-info", {
      replace: true,
      state: { rfqId: rfqId, from: "sampleDetail", sampleId: sampleId },
    });
  };
  const deleteDocument = (ele) => {
   
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteSampleFile(ele.id));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  return (
    <>
      {/* <ToastContainer /> */}
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <div className="top-heading">
            <div onClick={handleBack} className="d-flex">
              <a href="#" className="back">
                &#8249;
              </a>
              <h2>Sample Detail</h2>
            </div>
          </div>
          <div className="sample-detail-wrap">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  onClick={() => setTabNo(1)}
                  className="nav-link active"
                  id="basic-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#basic-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="basic-tab-pane"
                  aria-selected="true"
                >
                  Basic Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  onClick={() => setTabNo(2)}
                  className="nav-link"
                  id="images-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#images-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="images-tab-pane"
                  aria-selected="false"
                >
                  Images
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  onClick={() => setTabNo(3)}
                  className="nav-link"
                  id="rfq-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#rfq-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="rfq-tab-pane"
                  aria-selected="false"
                >
                  RFQs
                </button>
              </li>
              <li className="ms-auto">
                <button onClick={handleEditClick} className="btn">
                  <img src={editImg} alt="edit" />
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="basic-tab-pane"
                role="tabpanel"
                aria-labelledby="basic-tab"
                tabIndex="0"
              >
                <div className="row row-gap-2 mt-5">
                  <div className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6">
                    <div className="category-img">
                      <img
                        src={mainImage?.filePath}
                      />

                      <div className="category-side-img">
                        {sampleDetail?.sampleFiles &&
                          sampleDetail.sampleFiles
                            .filter((file) => file.fileType === "image")
                            .slice(1)
                            .map((sideFile, i) => (
                              <img
                                style={{ cursor: "pointer" }}
                                onClick={() => selectImage(i)}
                                key={i}
                                src={sideFile.filePath}
                                alt={`sample-${i}-side-image-${i + 1}`}
                              />
                            ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-8 col-xl-9 col-lg-8 col-sm-12">
                    <div className="row row-gap-2">
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Sample ID</label>
                        <input
                          type="text"
                          readOnly
                          value={sampleDetail?.id}
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Sample Name</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.sampleName
                              ? sampleDetail?.sampleName?.length > 0
                                ? sampleDetail?.sampleName
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">
                          Sampling Cost FOB (INR)
                        </label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.samplingCostFOB
                              ? "₹ " +
                                parseInt(sampleDetail?.samplingCostFOB).toFixed(
                                  2
                                )
                              : "NA"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">
                          Sampling Cost CMT (INR)
                        </label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.samplingCostCMT
                              ? "₹ " +
                                parseInt(sampleDetail?.samplingCostCMT).toFixed(
                                  2
                                )
                              : "NA"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Sampling Factory</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            factory
                              ? factory?.length > 0
                                ? factory
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Sampling Mill</label>
                        <input
                          type="text"
                          readOnly
                          value={mill ? (mill?.length > 0 ? mill : "-") : "-"}
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">TAT</label>
                        <input
                          type="text"
                          readOnly
                          value={sampleDetail?.TAT ? sampleDetail?.TAT : "-"}
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Season</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.season?.seasonName
                              ? sampleDetail?.season?.seasonName?.length > 0
                                ? sampleDetail?.season?.seasonName
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Fit</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.fit
                              ? sampleDetail?.fit?.length > 0
                                ? sampleDetail?.fit
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Trims & Finishes</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.trimsAndFinishes
                              ? sampleDetail?.trimsAndFinishes?.length > 0
                                ? sampleDetail?.trimsAndFinishes
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">MOQ For Production</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.MOQForProduction
                              ? sampleDetail?.MOQForProduction
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Size</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.size
                              ? sampleDetail?.size?.length > 0
                                ? sampleDetail?.size
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Color</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.color
                              ? sampleDetail?.color?.length > 0
                                ? sampleDetail?.color
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Development Date</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.developmentDate
                              ? sampleDetail?.developmentDate?.length > 0
                                ? moment(sampleDetail?.developmentDate).format(
                                    "MMMM DD, YYYY"
                                  )
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Category</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.category?.categoryName
                              ? sampleDetail?.category?.categoryName?.length > 0
                                ? sampleDetail?.category?.categoryName
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Buyer</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.User?.name ? (sampleDetail?.User?.name?.length > 0 ? sampleDetail?.User?.name : "-") : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Sub-Category</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.subCategory?.subCategoryName
                              ? sampleDetail?.subCategory?.subCategoryName
                                  ?.length > 0
                                ? sampleDetail?.subCategory?.subCategoryName
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Fabric Content</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.fabricContents
                              ? sampleDetail?.fabricContents?.length > 0
                                ? sampleDetail?.fabricContents
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Style Number</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.styleNumber
                              ? sampleDetail?.styleNumber?.length > 0
                                ? sampleDetail?.styleNumber
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Style Name</label>
                        <input
                          type="text"
                          readOnly
                          value={
                            sampleDetail?.styleName
                              ? sampleDetail?.styleName?.length > 0
                                ? sampleDetail?.styleName
                                : "-"
                              : "-"
                          }
                          className="form-control"
                        />
                      </div>
                      {/* <div className="col-xl-2 col-lg-3 col-md-4 col-6">
                        <label className="form-label">Product</label>
                        <input type="text" readOnly value={sampleDetail.} className="form-control" />
                      </div> */}
                    </div>
                  </div>
                </div>
                <h3 className="my-5">Documents Uploaded</h3>
                <div className="row row-gap-4">
                  {sampleDetail?.sampleFiles &&
                    sampleDetail.sampleFiles
                      .filter((file) => file.fileType != "image")
                      .map((file, i) => (
                        <div key={i} className="col-lg-3 col-md-4 col-sm-6">
                          <div className="document-detail">
                            <div className="document-row">
                              <div>
                                Name: <div className="nameInsideContainer">{file.fileName}</div>
                              </div>
                              <a
                                target="_blank"
                                href={file?.filePath}
                              >
                                <img src={importImg} alt="import" />
                              </a>
                            </div>
                            <div className="document-row">
                              <div>
                                Description:{" "}
                                <span>{file.description || "-"}</span>
                              </div>
                              <button
                                className="border-0"
                                onClick={() => deleteDocument(file)}
                              >
                                <img src={trashImg} alt="trash" />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="images-tab-pane"
                role="tabpanel"
                aria-labelledby="images-tab"
                tabIndex="0"
              >
                <div className="user-wrap mt-5">
                  <div className="row row-gap-5">
                    {sampleDetail?.sampleFiles &&
                      sampleDetail.sampleFiles
                        .filter((file) => file.fileType === "image")
                        .map((file, i) => (
                          <div
                            key={i}
                            className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6"
                          >
                            <div className="sample-img ">
                              <img
                                src={file?.filePath}
                                alt="main-img"
                              />
                              <div className="document-detail">
                                <div className="document-row">
                                  <div>
                                    Name: <div>{file.fileName}</div>
                                  </div>
                                  <a
                                    target="_blank"
                                    href={file?.filePath}
                                  >
                                    <img src={importImg} alt="import" />
                                  </a>
                                </div>
                                <div className="document-row">
                                  <div>
                                    Description:{" "}
                                    <span>{file.description || "-"}</span>
                                  </div>
                                </div>
                                <div className="document-row">
                                  <div>
                                    Display Image:{" "}
                                    <span>
                                      {file.display == true ? "Yes" : "No"}
                                    </span>
                                  </div>
                                </div>
                                <div className="document-row">
                                  <div>
                                    Front Image:{" "}
                                    <span>
                                      {file.isFront == true ? "Yes" : "No"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="rfq-tab-pane"
                role="tabpanel"
                aria-labelledby="rfq-tab"
                tabIndex="0"
              >
                <div className="rfq-status-table tab-content">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>RFQ Name</th>
                          <th>RFQ Date</th>
                          <th>Assignee</th>
                          <th>Category</th>
                          <th>Buyer</th>
                          <th>Style Code</th>
                          <th>Conversations</th>
                          <th>Latest Comments</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rfqs?.length > 0 &&
                          rfqs.map((item, index) => (
                            <tr
                              key={index}
                              onClick={(e) => rfqDetails(item.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <td>{index + 1}</td>
                              <td>{item.rFQName} </td>

                              <td>
                                {" "}
                                {moment(item.rfqRecievedDate).format(
                                  "YYYY-MM-DD"
                                )}
                              </td>

                              {/* <td>{item.rfqRecievedDate}</td> */}
                              <td
                                data-tooltip-id="my-tooltip-inline"
                                data-tooltip-content={
                                  item?.Users
                                    ? item.Users.map(
                                        (innerItem, index) => innerItem.name
                                      )
                                    : ""
                                }
                              >
                                {(item?.Users && item.Users.length) || "-"}
                              </td>
                              <td>
                                {item?.category?.categoryName
                                  ? item?.category?.categoryName?.length > 0
                                    ? item?.category?.categoryName
                                    : "NA"
                                  : "NA"}
                              </td>
                              <td>
                                {buyer
                                  ? buyer?.length > 0
                                    ? buyer
                                    : "NA"
                                  : "NA"}
                              </td>
                              <td>{item.styleNo || "-"}</td>
                              <td>
                                <a href="#">View</a>
                              </td>
                              <td>NA</td>
                              <td
                                data-tooltip-id="my-tooltip-inline"
                                data-tooltip-content={item.status}
                              >
                                <span
                                  className={getColorClass(item.status)}
                                ></span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <nav className="pagination-block">
                    <p className="total">{ttlRfq} items total</p>

                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination justify-content-center mt-4"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <div className="customSelect">
                      <label htmlFor="">Items per page :</label>
                      <div className="selectBox">
                        <select name="" id="" onChange={handleItemsChange}>
                          <option value={CONSTANT.PAGE_LIMIT}>
                            {CONSTANT.PAGE_LIMIT}
                          </option>
                          <option value="15">15</option>

                          <option value="20">20</option>
                        </select>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tooltip
        id="my-tooltip-inline"
        style={{
          backgroundColor: "#FE4900",
          color: "white",
          fontWeight: "bolder",
        }}
      />
    </>
  );
};
