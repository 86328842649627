import { Modal, Button } from "react-bootstrap";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDateClick } from "../../../../utils/datePickerHelper";
import moment from "moment";
import * as Yup from "yup";
import { addActualDates, addNewDates, getActualDates } from "../../../../features/po/action";
import { resetPo } from "../../../../features/po/slice";
import { notify } from "../../../../features/notification/notify";
export const ActualDatesEntryModal = (props) => {
    const { userId, getDates, showActualDateModal, setShowActualDateModal, selectedFactory, selectedMilestone, factMilstn } = props
    const dispatch = useDispatch();
    const poResponse = useSelector((state) => state.po);
    const [newDates, setNewDates] = useState([])


    useEffect(() => {
        if (poResponse.actionName == "addActualDates" && poResponse.success) {
            setNewDates([]);
        }

    }, [poResponse]);

    useEffect(() => {
        if (factMilstn && selectedFactory) {
            let arr = []
            factMilstn
                .filter((x) => x.poFactoryId == selectedFactory.id)
                .map((factoryMilestones) => {
                    arr.push({milestoneName: factoryMilestones.milestone.name , startDate: factoryMilestones.startDate, endDate: factoryMilestones.endDate, qty: factoryMilestones.quantity, poFactoryMilestoneId: factoryMilestones.id, actualStartDate: null, actualEndDate: null, quantity: null, remarks: null, createdBy: userId })
                }) 
                setNewDates(arr)
        }
    }, [factMilstn, selectedFactory]);

    const closeModal = () => {
        setShowActualDateModal(false);
    };

    const preventNegativeValues = (e) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const handleChange = (e,index) => {
        let temp = [...newDates]
        temp[index][e.target.name] = e.target.value
        setNewDates(temp);
    };

    const submitData = () => {        
            dispatch(addActualDates(newDates));
    };

    const rejectPoModalSchema = Yup.object().shape({
        actualStartDate: Yup.date()
            .required("Start date is required")
            .typeError("Invalid start date"),
        quantity: Yup.number().nullable().required("Quantity is required"),
        remarks: Yup.string().nullable().required("Required")
    });



    return (
        <Modal
            show={showActualDateModal}
            onHide={closeModal}
            size="xl"
        >
            <Formik
                initialValues={newDates}
                validationSchema={rejectPoModalSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    submitData();
                }}
            >
                {({ errors, touched, isValid, dirty, handleBlur }) => (
                    <>
                        <Form>
                            <Modal.Header>
                                <h5 className="modal-title" id="edit-profile-Label">
                                    Propose new time frame
                                </h5>
                            </Modal.Header>
                            <Modal.Body>
                                <row className="centre">
                                    {/**/}
                                    {newDates
                                        .map((element,index) => (
                                            <div>

                                                <br />
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <label className="form-label">
                                                        <nobr> Milestone Name </nobr>
                                                            <p>
                                                                <span className="ttlqty-smpl">
                                                                    {element.milestoneName}
                                                                </span>
                                                            </p>
                                                        </label>
                                                    </div>&nbsp;&nbsp;&nbsp;
                                                    <div className="col-md-1">
                                                        <label className="form-label">
                                                            Start date
                                                            <p>
                                                                <span className="ttlqty-smpl">
                                                                    {moment(element.startDate).format("yyyy-MM-DD")}
                                                                </span>
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="form-label">
                                                            End date
                                                            <p>
                                                                <span className="ttlqty-smpl">
                                                                    {moment(element.endDate).format("yyyy-MM-DD")}
                                                                </span>
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label className="form-label">
                                                            Quantity
                                                            <p>
                                                                <span className="ttlqty-smpl">
                                                                    {element.qty}
                                                                </span>
                                                            </p>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2  form-label">
                                                        Actual start date
                                                        <input
                                                            type="date"
                                                            name="actualStartDate"
                                                            value={
                                                                element.actualStartDate
                                                                    ? moment(element.actualStartDate).format(
                                                                        "yyyy-MM-DD"
                                                                    )
                                                                    : ""
                                                            }
                                                            onClick={handleDateClick}
                                                            onChange={(e)=>handleChange(e,index)}
                                                            min={new Date().toISOString().split("T")[0]}
                                                            className="form-control"
                                                        />
                                                        {errors.actualStartDate && touched.actualStartDate ? (
                                                            <div className="invalid-feedback d-block">
                                                                {errors.actualStartDate}
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    <div className="col-md-2  form-label">
                                                        Actual end date
                                                        <input
                                                            type="date"
                                                            name="actualEndDate"
                                                            value={
                                                                element.actualEndDate
                                                                    ? moment(element.actualEndDate).format("yyyy-MM-DD")
                                                                    : ""
                                                            }
                                                            onClick={handleDateClick}
                                                            onChange={(e)=>handleChange(e,index)}
                                                            min={new Date().toISOString().split("T")[0]}
                                                            className="form-control"
                                                        />
                                                        {errors.actualEndDate && touched.actualEndDate ? (
                                                            <div className="invalid-feedback d-block">
                                                                {errors.actualEndDate}
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    <div className="col-md-1  form-label">
                                                        <span><nobr>Qty Completed  </nobr></span>
                                                        <input
                                                            onChange={(e)=>handleChange(e,index)}
                                                            type="number"
                                                            value={element.quantity}
                                                            name="quantity"
                                                            className="form-control"
                                                            min={1}
                                                            onKeyDown={preventNegativeValues}
                                                        />
                                                        {errors.quantity && touched.quantity ? (
                                                            <div className="invalid-feedback d-block">
                                                                {errors.quantity}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-md-2  form-label">
                                                        &nbsp;&nbsp;  Remarks
                                                        <input
                                                            onChange={(e)=>handleChange(e,index)}
                                                            type="text"
                                                            value={element.remarks}
                                                            name="remarks"
                                                            className="form-control"
                                                        />
                                                        {errors.remarks && touched.remarks ? (
                                                            <div className="invalid-feedback d-block">
                                                                {errors.remarks}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>))}
                                </row>
                            </Modal.Body>
                            <Modal.Footer>
                                <a
                                    type="button"
                                    className="btn inner-blue-border-btn"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </a>
                                <Button type="submit" className="btn inner-blue-btn">
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
