import React, { useEffect } from 'react'
import Select from "react-select";
import { numberFormat } from '../soDataOption';

export const PoItemList = (props) => {

    const {
        openAddUpdateItem,
        itemRows,
        setItemRows,
        setSODetails,
        soDetails,
        setItemSearch,
        taxPreferenceOptions,
        categoryOption,
        handleBlur,
        itemSearch,
        itemOption,
        createdItem,
        selectedSoSample
    } = props;

    const deleteItemRow = (index) => {
        let temp = [...itemRows]
        temp[index].isDeleted = true
        setItemRows(temp);
    };

    useEffect(() => {
        if(createdItem){
            console.log("createdItem", createdItem)
            const newRow = { id: itemRows.length + 1, sampleId: createdItem.sampleId, itemName: createdItem.EAN, itemType: createdItem.itemType != null ? createdItem.itemType : "", qty: 1, rate: '', taxType: createdItem.taxPreference != null ? createdItem.taxPreference : "", amount: 0, isDeleted: false };
            setItemRows([...itemRows, newRow]);
            let temp = [...itemRows]
            temp.push(newRow)
        }
    }, [createdItem])

    useEffect(() => {
        if(selectedSoSample) {

            let temp = [...itemRows];
            selectedSoSample.map((smple, index) => {
                    temp.push({
                        id: index + 1, soSampleId: smple.id, sampleId: smple.sampleDetail.zohoItemId ? smple.sampleDetail.zohoItemId : smple.sampleDetail.id,
                        itemName: smple.sampleDetail?.sampleName ? smple.sampleDetail?.sampleName : null,
                        itemType: smple.itemType, qty: 1, rate: parseInt(smple.taxRate), taxType: smple.taxType,
                        amount: parseInt(smple.amount), isDeleted: false
                    })
                })
                setItemRows(temp);

                let subTotal = selectedSoSample.reduce((sum, item) => sum += parseInt(item.amount), 0);
                setSODetails({ ...soDetails, subTotal: subTotal })  
        }
    }, [selectedSoSample])


    const handleItemInputChange = (e) => {
        setItemSearch(e.target.value)
    }

    const handleSelectItem = (item) => {
        const newRow = { id: itemRows.length + 1, sampleId: item.value, itemName: item.label, itemType: '', qty: 1, rate: item.rate, taxType: '', amount: parseInt(item.rate) * 1 , isDeleted: false};
        setItemRows([...itemRows, newRow]);
        let temp = [...itemRows]
        temp.push(newRow)
        let sum = temp.reduce((total, currentValue) => total = total + currentValue.amount, 0);
        setSODetails({ ...soDetails, subTotal: sum })
        setItemSearch("")
    }

    const handleChangeItemInfo = (value, index, name) => {
        let temp = [...itemRows]
        temp[index][name] = value


        if (name == 'qty') {
            temp[index].amount = temp[index].rate * value
        }
        else if (name == 'rate') {
            temp[index].amount = temp[index].qty * value
        }
        let sum = temp.reduce((total, currentValue) => total = total + currentValue.amount, 0);
        setSODetails({ ...soDetails, subTotal: sum })
        setItemRows(temp)
    }

    useEffect(() => {
        itemRows && console.log("itemRows", itemRows)
    }, [itemRows])
    

  return (
    <div className="so-item-section">
    <div className="so-item-head">
        <div>
            <b>Item Table</b>
        </div>
        <div style={{ color: "#0d6efdab" }}>
            <button onClick={openAddUpdateItem} className="btn ornge-brdr-btn me-3" type="button" ><i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add new Item</button>
        </div>
    </div>
    <div className="table-responsive so-item-table">
        <table className="table table-striped tbl-item-so">
            <thead>
                <tr>
                    <th >ITEM DETAILS</th>
                    <th >MRP</th>
                    <th >ITEM TYPE</th>
                    <th style={{ width: '20px' }}>QUANTITY</th>
                    <th style={{ width: '20px' }}>RATE</th>
                    <th >TAX</th>
                    <th >AMOUNT</th>
                </tr>
            </thead>
            <tbody>
                {itemRows.map((itemRow, index) => (
                  itemRow.isDeleted == false &&  <tr className="tbl-row-itm" key={itemRow.id}>
                        <td >
                            <div>
                                <small>{itemRow.itemName}</small>
                            </div>
                        </td>
                        <td >INR</td>
                        <td >
                            <Select
                                value={itemRow.itemType}
                                name="itemType"
                                classNamePrefix="form-control"
                                onChange={(e) => handleChangeItemInfo(e.value, index, 'itemType')}
                                onBlur={handleBlur}
                                options={categoryOption}
                                placeholder={itemRow.itemType == "" ? 'Type: All' : categoryOption.find(x => x.value == itemRow.itemType)?.label}
                            />
                        </td>
                        <td style={{ width: '20px' }} ><input className="form-control" onChange={(e) => handleChangeItemInfo(e.target.value, index, 'qty')} name="qty" value={itemRow.qty} /></td>

                        <td style={{ width: '20px' }}><input className="form-control" onChange={(e) => handleChangeItemInfo(e.target.value, index, 'rate')} name="rate" value={itemRow.rate} /></td>
                        <td >

                            <Select
                                classNamePrefix="form-select"
                                name="taxType"
                                value={itemRow.taxType}
                                onChange={(e) => handleChangeItemInfo(e.value, index, 'taxType')}
                                options={taxPreferenceOptions}
                                placeholder={itemRow.taxType == "" ? 'Tax Type: All' : taxPreferenceOptions.find(x => x.value == itemRow.taxType)?.label}
                                isClearable="true"
                            />
                        </td>
                        <td >{numberFormat(itemRow.amount)}</td>
                        <td onClick={() => deleteItemRow(index)} className="cross-item-row">X</td> 

                    </tr>
                ))}
                <tr className="tbl-row-itm" >
                    <td >
                        <input
                            type="text"
                            placeholder="Type or click to select an item"
                            className="form-control dropdown-toggle btn item-list-input"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            name="itemName"
                            value={itemSearch}
                            onChange={handleItemInputChange}
                        />


                        <div style={{ background: 'white' }} className="dropdown-menu dv-drp" >
                            <ul className="ul-item">
                                {itemOption.filter(x => !itemSearch || x.label.includes(itemSearch)).map((element) => (
                                    <li onClick={(e) => handleSelectItem(element)} key={element.value} value={element.value}>
                                        {element.label} <br /> Rate: {element.rate}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </td>
                    <td >INR</td>
                    <td ><Select placeholder='Select Type' /></td>
                    <td >0.00</td>
                    <td >0.00</td>
                    <td >
                        <Select placeholder='Select a tax' />
                    </td>
                    <td >0.00</td>


                </tr>

            </tbody>
        </table>
        {/* <div className="p-3">
            <button className="btn ornge-brdr-btn me-3" type="button" onClick={addItemRow}><i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add new row</button>
            <button className="btn ornge-btn" type="button" ><i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add bulk items</button>
        </div> */}
    </div>
</div>
  )
}
