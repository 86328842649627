import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { deleteRfqFile, getRfqById } from "../../features/rfq/action";
import trashImg from "../../assets/images/trash.svg"
import importImg from "../../assets/images/import.svg"
import moment from 'moment';
import { resetRfq } from "../../features/rfq/slice";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "react-tooltip";
import { getUserById } from "../../features/user_management/action";
import { resetUserManagememnt, userManagememnt } from "../../features/user_management/slice";
import editImg from "../../assets/images/edit.svg"
import { useNavigate } from "react-router-dom";
import { ModalForPreviewOfFile } from "./modalForPreviewOfFile";

export const AttachAllImagesOfRfq = (props) => {
    const { attachImagesOfRfq } = props;
    const roleType = localStorage.getItem("roleType");
    const dispatch = useDispatch();
    const roleId = localStorage.getItem("roleId");

    const handleDelete = (data) => {
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure you want to delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        dispatch(deleteRfqFile(data));
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const [openModalForPreview, setOpenModalForPreview] = useState(false);

    const [pdfUrl, setPdfUrl] = useState(null);
    const [fileType, setFileType] = useState(null);
    const PdfPreview = (pdfUrl) => {
        const fileExtension = pdfUrl?.split(".").pop().toLowerCase();
        setOpenModalForPreview(true);
        setFileType(fileExtension);
        setPdfUrl(pdfUrl);
    };

    return (
        <>
            <div className="tab-pane fade show active mt-4" id="image-tab-pane" role="tabpanel" aria-labelledby="image-tab" tabIndex="0">
                <div className="row row-gap-4">
                    {attachImagesOfRfq?.rfqFiles &&
                        attachImagesOfRfq.rfqFiles
                            .filter((file) => file.fileType != "image")
                            .map((file, i) =>
                            (

                                roleType == "Internal" || roleType == "External" && attachImagesOfRfq?.showRfqFields.length == 0 || attachImagesOfRfq?.showRfqFields.length == 1 && attachImagesOfRfq?.showRfqFields[0]?.userType != roleId ?

                                    <>
                                        {file?.fileType == "attach image" ?
                                            <div key={i} className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="document-detail">

                                                    <>
                                                        <a className="rounded rfqBasicInfoFile" onClick={(e) => PdfPreview(file?.filePath)}>{file.fileName.split(".").pop().toLowerCase()}</a>
                                                        {/* <a target="_blank"><img src={file?.filePath} alt="import" className="thumbnail rounded rfqBasicInfoImage" /> */}



                                                        <div className="document-row">
                                                            <div>Name: <span>{file.fileName}</span></div>
                                                            <a target="_blank" href={file?.filePath}><img src={importImg} alt="image" /></a>
                                                        </div>

                                                        <div className="document-row">
                                                            <div>Description: <span>{file.fileType}</span></div>
                                                            {localStorage.getItem("roleType") == "Internal" &&
                                                                <a onClick={(e) => handleDelete(file.id)} ><img src={trashImg} alt="trash" /></a>
                                                            }
                                                        </div> </>

                                                </div>
                                            </div>
                                            : ""}
                                    </>
                                    :

                                    attachImagesOfRfq?.showRfqFields[0]?.userType == roleId || attachImagesOfRfq?.showRfqFields[1]?.userType == roleId ?
                                        file?.fileType == "attach image" && attachImagesOfRfq?.showRfqFields[0]?.showGarment && attachImagesOfRfq?.showRfqFields[0]?.userType == roleId
                                            || file?.fileType == "attach image" && attachImagesOfRfq?.showRfqFields[1]?.showGarment && attachImagesOfRfq?.showRfqFields[1]?.userType == roleId ?

                                            <>
                                                <div className="col-lg-3 col-md-4 col-sm-6">
                                                    <div className="document-detail">
                                                        <a className="rounded rfqBasicInfoFile" onClick={(e) => PdfPreview(file?.filePath)}>{file.fileName.split(".").pop().toLowerCase()}</a>

                                                        <div className="document-row">
                                                            <div>Name: <span>{file.fileName}</span></div>
                                                            <a target="_blank" href={file?.filePath}><img src={importImg} alt="image" /></a>
                                                        </div>

                                                        <div className="document-row">
                                                            <div>Description: <span>{file.fileType}</span></div>
                                                            {localStorage.getItem("roleType") == "Internal" &&
                                                                <a onClick={(e) => handleDelete(file.id)} ><img src={trashImg} alt="trash" /></a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                            : ""

                                        : ""

                            ))}
                </div>
            </div>

            {openModalForPreview ? <ModalForPreviewOfFile openModalForPreview={openModalForPreview} setOpenModalForPreview={setOpenModalForPreview} setPdfUrl={setPdfUrl} pdfUrl={pdfUrl} fileType={fileType} setFileType={setFileType} /> : ""}
        </>
    )
}