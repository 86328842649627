export const BASE_URLS = {
  BASE_URL: process.env.REACT_APP_API_URL,
};



export const API_URLS = {

  COMMON: {
    ROLE: BASE_URLS.BASE_URL + "/common/getRoles",
    GET_CATEGORY: BASE_URLS.BASE_URL + "/common/getCategory",
    GET_SEASON: BASE_URLS.BASE_URL + "/common/getSeason",
    GET_RFQ_STATUS: BASE_URLS.BASE_URL + '/common/getRfqStatus',
    GET_SUBCATEGORY_BY_CATEGORYiD: BASE_URLS.BASE_URL + "/common/getSubCategoryByCategoryId",
  },

  USERS: {
    GROYYO_LOGIN: BASE_URLS.BASE_URL + "/users/groyyoLogin",
    LOGIN: BASE_URLS.BASE_URL + "/users/login",
    LOGIN_VALIDATE: BASE_URLS.BASE_URL + "/users/validateLogin",
    GROYYO_LOGIN_VALIDATE: BASE_URLS.BASE_URL + "/users/fetchGroyyoUserLoginValidate",
    GROYYO_RESEND_OTP: BASE_URLS.BASE_URL + "/users/resendOtpGroyyo",
    SIGNUP_VALIDATE: BASE_URLS.BASE_URL + "/users/signup",
    ADMIN_SET_PIN: BASE_URLS.BASE_URL + "/users/setUserPin",
    ADMIN_UPDATE_USER: BASE_URLS.BASE_URL + "/users/UpdateUser",
    FORGET_PIN: BASE_URLS.BASE_URL + "/users/forgetPin",
    GET_USER_BY_ROLE_TYPE: BASE_URLS.BASE_URL + "/users/getUserByRoleType",
    GET_USER_BY_NAME_ROLE_TYPE: BASE_URLS.BASE_URL + "/users/getUserByNameNRoleType",
    USER_BULK_UPLOAD: BASE_URLS.BASE_URL + "/users/userBulkUpload"
  },

  RFQ: {
    ADD_UPDATE_RFQ: BASE_URLS.BASE_URL + "/rfq/addUpdateRfq",
    GET_ALL_RFQ: BASE_URLS.BASE_URL + "/rfq/getAllRfq",
    GET_ALL_FACTORY_RFQ: BASE_URLS.BASE_URL + "/rfq/getAllFactoryRfq",
    GET_SAMPLE_BY_RFQID: BASE_URLS.BASE_URL + '/rfq/getSampleByRfqId',
    ADD_SAMPLETO_RFQ: BASE_URLS.BASE_URL + '/rfq/addSampleToRfq',
    ADD_COMMENT_TO_RFQSAMPLE: BASE_URLS.BASE_URL + "/rfq/addCommentToRfqSample",
    GET_RFQ_BY_ID: BASE_URLS.BASE_URL + "/rfq/getRfqById",
    RELEASE_RFQ: BASE_URLS.BASE_URL + "/rfq/releaseRfq",
    REPLY_RFQ: BASE_URLS.BASE_URL + "/rfq/replyBid",
    GET_BID_BY_RFQID: BASE_URLS.BASE_URL + "/rfq/getBidByRfqId",
    GET_RFQ_STATUS_BY_RFQ_ID: BASE_URLS.BASE_URL + "/rfq/getRfqStatusByRfqId",
    SET_RFQ_STATUS: BASE_URLS.BASE_URL + "/rfq/setRfqStatus",
    GET_USER_BY_ROLE_NAME: BASE_URLS.BASE_URL + "/users/getUserByRoleName",
    DELETE_RFQ_FILE: BASE_URLS.BASE_URL + "/rfq/deleteRfqFileById",
    GET_RFQ_MESSAGES: BASE_URLS.BASE_URL + "/rfq/getRFQMessages",
    SAVE_RFQ_MESSAGES: BASE_URLS.BASE_URL + "/rfq/saveRFQMessages",
    GET_ALL_MESSAGES_BY_RFQ: BASE_URLS.BASE_URL + "/rfq/getAllMessagesByRFQ",
    GET_RFQ_BY_SAMPLEID: BASE_URLS.BASE_URL + "/rfq/getRfqBySampleId",
    IS_RFQ_ASSIGNEE: BASE_URLS.BASE_URL + "/rfq/isRfqAssignee",
    GET_BID_REPLY_DETAILS: BASE_URLS.BASE_URL + '/rfq/getBidReplyDetails',
    ACCEPT_ONE_BID: BASE_URLS.BASE_URL + '/rfq/acceptOneBid',
    GET_GENERATE_PO: BASE_URLS.BASE_URL + '/rfq/getGeneratePo',
    SAVE_RFQSAMPLE_COMMENTS: BASE_URLS.BASE_URL + "/rfq/addCommentToRfqSample",
    GET_ALL_RFQSAMPLE_COMMENTS: BASE_URLS.BASE_URL + "/rfq/getRfqSampleComments",
    ADD_BULK_RFQ: BASE_URLS.BASE_URL + "/rfq/addBulkRfq",
    SHOW_RFQ_DETAILS_TOFACTORY: BASE_URLS.BASE_URL + "/rfq/showRfqDetailsToFactoryOrBuyer",
    GET_SHOW_RFQ_DETAILS_DATA: BASE_URLS.BASE_URL + '/rfq/getShowRfqDetailsData'
  },

  RFQ_CHAT: {
    GET_ALL_RFQCHATS: BASE_URLS.BASE_URL + "/rfqChats/getAllChats",
    GET_ALL_CHAT_MESSAGES: BASE_URLS.BASE_URL + "/rfqChats/getAllChatMessages",
    SAVE_NEW_CHAT_MESSAGE: BASE_URLS.BASE_URL + "/rfqChats/saveNewChatMessage",
    CREATE_CHAT_GROUP: BASE_URLS.BASE_URL + "/rfqChats/createGroupChat",
    CREATE_SINGLE_CHAT: BASE_URLS.BASE_URL + "/rfqChats/createSingleChat",
    UPDATE_CHAT_GROUP: BASE_URLS.BASE_URL + "/rfqChats/updateGroupChat",
    GET_CHATBY_RFQ_BID: BASE_URLS.BASE_URL + "/rfqChats/getChatByRfqBid",
  },

  SAMPLE: {
    ADD_UPDATE_SAMPLE: BASE_URLS.BASE_URL + "/sample/addUpdateSample",
    GET_ALL_SAMPLES: BASE_URLS.BASE_URL + "/sample/getAllSamples",
    Send_Samples_ToSelectedMail: BASE_URLS.BASE_URL + "/sample/sendMailOfSelectedSample",
    GET_SAMPLE_BY_ID: BASE_URLS.BASE_URL + "/sample/getSampleById",
    DELETE_SELECTED_SAMPLE: BASE_URLS.BASE_URL + "/sample/deleteSelectedSamples",
    DELETE_SAMPLE_FILE: BASE_URLS.BASE_URL + "/sample/deleteSampleFile",
    ADD_SAMPLE_IMAGE: BASE_URLS.BASE_URL + "/sample/addSampleImage",
    GET_SAMPLES_BY_NAME: BASE_URLS.BASE_URL + '/sample/getSamplesByName',
    EDIT_SAMPLE_FILE: BASE_URLS.BASE_URL + "/sample/editSampleFile",
    ADD_SAMPLE_IMAGE: BASE_URLS.BASE_URL + "/sample/addSampleImage",
    EXPORT_AS_PPT: BASE_URLS.BASE_URL + "/sample/exportSamplesAsPPT",

  },

  MANAGEMENT: {
    ADD_UPDATE_BUYER: BASE_URLS.BASE_URL + '/management/addUpdateBuyer',
    GET_ALL_BUYER: BASE_URLS.BASE_URL + '/management/getAllBuyer',
    GET_BUYER_BYID: BASE_URLS.BASE_URL + '/management/getBuyerById',
    GET_ALL_USER: BASE_URLS.BASE_URL + '/management/getAllUser',
    GET_ALL_SUPPLIER: BASE_URLS.BASE_URL + '/management/getAllSupplier',
    GET_ALL_LOCATION: BASE_URLS.BASE_URL + '/management/getLocationDetail',
    ADD_UPDATE_SUPPLIER: BASE_URLS.BASE_URL + '/management/addUpdateSupplier',
    ADMIN_GET_USERS: BASE_URLS.BASE_URL + "/management/getusers",
    ADMIN_UPDATE_USER_STATUS: BASE_URLS.BASE_URL + "/management/UpdateUserStatus",
    ADMIN_ADD_UPDATE_USER: BASE_URLS.BASE_URL + "/management/addUpdateUser",
    GET_SUPPLIER_BY_ID: BASE_URLS.BASE_URL + '/management/getSupplierById',
    GET_USER_BY_ID: BASE_URLS.BASE_URL + '/management/getUserById',
  },

  SUPPLIER: {
    SUPPLIER_BULK_UPLOAD: BASE_URLS.BASE_URL + "/supplier/supplierBulkUpload",
  },


  PRODUCT: {
    GET_All_CATEGORIES: BASE_URLS.BASE_URL + '/product/getAllCategories',
    ADD_UPDATE_Category: BASE_URLS.BASE_URL + '/product/addUpdateCategory'
  },

  MILESTONE: {
    ADD_UPDATE_MILESTONE: BASE_URLS.BASE_URL + '/milestone/addUpdateMilestone',
    GET_ALL_MILESTONE: BASE_URLS.BASE_URL + '/milestone/getAllMilestone',
    FETCH_MILESTONES: BASE_URLS.BASE_URL + '/milestone/fetchMilestones',
    DELETE_MILESTONE: BASE_URLS.BASE_URL + '/milestone/deleteMilestone',
  },

  TNA: {
    ADD_UPDATE_TNA_TEMPLATE: BASE_URLS.BASE_URL + '/tna/addUpdateTNATemplate',
    GET_ALL_TNA_TEMPLATE: BASE_URLS.BASE_URL + '/tna/getTNATemplates',
    DELETE_TNA_TEMPLATE: BASE_URLS.BASE_URL + '/tna/deleteTNATemplate',
    ADD_BULK_TEMPLATE: BASE_URLS.BASE_URL + '/tna/addBulkTemplate',
  },
  PO: {
    UPLOAD_INVOICE: BASE_URLS.BASE_URL + '/po/uploadInvoice',
    GET_ALL_PO: BASE_URLS.BASE_URL + '/po/getAllPOs',
    DELETE_PO: BASE_URLS.BASE_URL + '/po/deletePO',
    ADD_UPDATE_PO: BASE_URLS.BASE_URL + '/po/addUpdatePo',
    GET_ALL_Templates: BASE_URLS.BASE_URL + '/po/getAllTemplates',
    ADD_TEMPLATE_TO_PO: BASE_URLS.BASE_URL + '/po/addTemplateToPo',
    GET_ACCEPTED_FACTORY: BASE_URLS.BASE_URL + '/po/getAcceptedFactory',
    GET_MILESTONE_BY_TEMPLATEID: BASE_URLS.BASE_URL + '/po/getMilestoneByTempId',
    ADD_FACTORY_TO_PO: BASE_URLS.BASE_URL + '/po/addFactoryToPo',
    GET_PO_BY_ID: BASE_URLS.BASE_URL + '/po/getPoByIdAndRole',
    ADD_DATE_TO_POFACTORY_MILESTONE: BASE_URLS.BASE_URL + '/po/addDatePoFactoryMilestones',
    RELEASE_PO: BASE_URLS.BASE_URL + '/po/releasePo',
    SET_PO_FACTORY_EDITABLE: BASE_URLS.BASE_URL + '/po/setPoFactoryEditable',
    ADD_NEW_DATES: BASE_URLS.BASE_URL + '/po/addDatePoFactoryMilestonesLog',
    ACCEPT_OR_REJECT_PODATES: BASE_URLS.BASE_URL + '/po/setPoFactoryMilestoneAcceptOrReject',
    ADD_ACTUAL_DATES: BASE_URLS.BASE_URL + '/po/setTNAMilestoneActualDates',
    EXPORT_PO_TEMPLATE: BASE_URLS.BASE_URL + '/po/exportPOTemplate',
    PO_TEMPLATE_BULK_UPLOAD: BASE_URLS.BASE_URL + '/po/poTemplateBulkUpload',
    ACCEPT_ALL_PO_MILESTONE_DATES: BASE_URLS.BASE_URL + '/po/acceptAllPoMilestonesDates'
  },

  PO_CHAT: {
    GET_ALL_POCHATS: BASE_URLS.BASE_URL + "/poChats/getAllChats",
    GET_ALL_CHAT_MESSAGES: BASE_URLS.BASE_URL + "/poChats/getAllChatMessages",
    SAVE_NEW_CHAT_MESSAGE: BASE_URLS.BASE_URL + "/poChats/saveNewChatMessage",
    CREATE_CHAT_GROUP: BASE_URLS.BASE_URL + "/poChats/createGroupChat",
    CREATE_SINGLE_CHAT: BASE_URLS.BASE_URL + "/poChats/createSingleChat",
    UPDATE_CHAT_GROUP: BASE_URLS.BASE_URL + "/poChats/updateGroupChat"
  },

  SO_PO: {
    GET_ALL_CUSTOMER: BASE_URLS.BASE_URL + "/poSoBypass/getAllBuyersOfZohoAndDb",
    GET_ALL_SAMPLE_ITEM: BASE_URLS.BASE_URL + "/poSoBypass/getAllItemsOfZohoAndDb",
    ADD_UPDATE_SO: BASE_URLS.BASE_URL + "/poSoBypass/addUpdateSO",
    ADD_UPDATE_PO: BASE_URLS.BASE_URL + "/poSoBypass/addUpdatePO",
    ITEM_COMMON_DATA: BASE_URLS.BASE_URL + "/poSoBypass/itemCommonData",
    SO_COMMON_DATA: BASE_URLS.BASE_URL + "/poSoBypass/soCommonData",
    GET_SO_LIST: BASE_URLS.BASE_URL + '/poSoBypass/getAllSo',
    GET_SELLING_ACCOUNT_SUBTYPE_ID:BASE_URLS.BASE_URL + '/poSoBypass/getSellingAccountSubTypeId',
    GET_PURCHASE_ACCOUNT_SUBTYPE_ID:BASE_URLS.BASE_URL + '/poSoBypass/getPurchaseAccountSubTypeId',
    GET_ALL_SUPPLIER:BASE_URLS.BASE_URL + '/poSoBypass/getAllSupplierOfZohoAndDb',
    ADD_UPDATE_ITEM: BASE_URLS.BASE_URL + "/poSoBypass/addUpdateItem",
    CREATE_BUYER_IFNOTEXIST:BASE_URLS.BASE_URL + "/poSoBypass/createBuyerIfNotExist",
    CREATE_MANUFACTURER_IFNOTEXIST:BASE_URLS.BASE_URL + "/poSoBypass/createManufacturerIfNotExist",
    GET_SO_BY_ID:BASE_URLS.BASE_URL + "/poSoBypass/getSoById",
    DELETE_SO:BASE_URLS.BASE_URL + "/poSoBypass/deleteSo"
  } 














  /*    Rfq    */


  /*    rfq comments     */


  /*    new rfqChats     */



  /*    Management    */





}
