import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LeftMenu from "../../layouts/leftMenu";
import { Header } from "../../layouts/header";
import { POBasicInfo } from "./poBasicInfo/poBasicInfo";
import { POChatConversation } from "./poChat/poChatConversation";
import { POFinalizedTNA } from "./poBasicInfo/poFinalisedTNA";

export const POInfo = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [poId, setPoId] = useState();
  const [poActiveTab, setPoActiveTab] = useState("basic-tab");
  const navigate = useNavigate();
  const [currentChat, setCurrentChat] = useState();
  const [poGenerated, setPoGenerated] = useState(false);

  useEffect(() => {
    // setPoId(2);
    if (state && state.poId) {
      setPoId(state.poId);
    }
    // if (state && state.chat == true) {
    //     setpoActiveTab('conversation-tab-pane')
    // } else {
    //     setpoActiveTab('basic-tab')
    // }

    //add loader for conversation page
  }, []);

  const handleOpenTab = (tabId) => {
    setPoActiveTab(tabId);
  };

  const handleBack = () => {
    // if (state?.from == "sampleDetail") {
    //     navigate("/sample-detail", {
    //         replace: true,
    //         state: { sampleId: state.sampleId },
    //     });
    // } else {
    //     navigate('/rfq-list')
    // }
    navigate("/po-list");
  };

  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <div>
            <small>POList &gt; PO Details </small>
          </div>
          <div className="top-heading">
            <div className="d-flex">
              <a onClick={handleBack} className="back">
                &#8249;
              </a>
              <h2>PO {poId}</h2>
            </div>
          </div>
          <div className="sample-detail-wrap">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    poActiveTab === "basic-tab" ? "active" : ""
                  }`}
                  id="basic-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#basic-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="basic-tab-pane"
                  aria-selected="true"
                  onClick={() => handleOpenTab("basic-tab")}
                >
                  PO Basic Information
                </button>
              </li>
              {poGenerated == true && (
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      poActiveTab === "finalTna-tab" ? "active" : ""
                    }`}
                    id="finalTna-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#finalTna-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="finalTna-tab-pane"
                    aria-selected="false"
                    onClick={() => handleOpenTab("finalTna-tab")}
                  >
                    PO Finalised TNA
                  </button>
                </li>
              )}
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    poActiveTab === "conversation-tab-pane" ? "active" : ""
                  }`}
                  id="conversation-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#conversation-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="conversation-tab-pane"
                  aria-selected="false"
                  onClick={() => handleOpenTab("conversation-tab-pane")}
                >
                  Conversations
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className={`tab-pane fade ${
                  poActiveTab === "basic-tab" ? "show active" : ""
                }`}
                id="basic-tab-pane"
                role="tabpanel"
                aria-labelledby="basic-tab"
                tabIndex="0"
              >
                <POBasicInfo poId={poId} setPoGenerated={setPoGenerated} />
              </div>
              {poGenerated ? (
                <div
                  className={`tab-pane fade ${ poActiveTab === "finalTna-tab" ? "show active" : "" }`}
                  id="finalTna-tab-pane"
                  role="tabpanel"
                  aria-labelledby="finalTna-tab"
                  tabIndex="1">
                  <POFinalizedTNA poId={poId} />                 
                </div>
              ):("")}
              <div
                className={`tab-pane fade ${
                  poActiveTab === "conversation-tab-pane" ? "show active" : ""
                }`}
                id="conversation-tab-pane"
                role="tabpanel"
                aria-labelledby="conversation-tab"
                tabIndex="2"
              >
                <POChatConversation
                  poId={poId}
                  setCurrentChat={setCurrentChat}
                  currentChat={currentChat}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
