import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getRfqStatusByRfqId } from "../../features/rfq/action";
import Tick from "../../assets/images/tick-circle.svg";
import PlusCircle from "../../assets/images/plus-circle.svg";
import moment from "moment";
import { PostUpdate } from "./postUpdate";
import { notify } from "../../features/notification/notify";
import { resetRfq } from "../../features/rfq/slice";
import { getUserByRoleType } from "../../features/rfq/action";


export const RfqStatus = (props) => {


  const { rfqId, setStatusCount } = props;
  const dispatch = useDispatch();
  const [rfqStatusDetail, setRfqStatusDetail] = useState();
  const [rfId, setRfId] = useState();
  const [internalUsers, setInternalUsers] = useState([]);
  const rfqResponse = useSelector((state) => state.rfq);

  const [showPostModal, setShowPostModal] = useState(false)


  useEffect(() => {
    if (rfqId) {
      dispatch(getRfqStatusByRfqId(rfqId)); 
      dispatch(getUserByRoleType({ rollType: "Internal", userId: 0 }));
    } 
  }, [rfqId]);
  useEffect(() => {
    if (rfqId) setRfId(rfqId);
  }, [rfqId]);


  useEffect(() => {
    if (rfqResponse.actionName == "getRfqStatusByRfqId" && rfqResponse.success) {
      setRfqStatusDetail(rfqResponse.rfqStatuses);
      setStatusCount(rfqResponse.rfqStatuses != undefined ? rfqResponse.rfqStatuses.length : 0);
      dispatch(resetRfq())
    } 
    if (rfqResponse.actionName == "userByRoleType") {
      let assignArr = [];
      if (rfqResponse.roleDetails.length > 0)
        rfqResponse.roleDetails.map((item) => {
          let list = {
            id: item.id,
            name: item.name ? item.name : item.emailId,
          };
          assignArr.push(list);
        })
        setInternalUsers(assignArr.sort((a, b) => a.name.localeCompare(b.name)));
      dispatch(resetRfq());
    }
  }, [rfqResponse]);


  const getClassName = (status) => {
    if (status == "On Track")
      return "status-icon green";
    else if (status == "On Hold")
      return "status-icon yellow";
    else if (status == "Delayed")
      return "status-icon red";
    else if (status == "Critical")
      return "status-icon blue";
    else
      return "status-icon orange";

  }
  return (
    <>
      <div
        className="modal rfq"
        id="rfq-status"
        tabIndex="-1"
        aria-labelledby="rfq-statusLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <button className="btn rfq-status">
              <img src="images/status.svg" alt="status" />
            </button>

            <div className="rfq-status-wrap">
              <a href="#" className="close-status">
                <img src="images/close-status.svg" alt="" />
              </a>
              <div className="rfq-status-header">
                <h2>Status Update</h2>
                <select className="form-select">
                  <option value="">All</option>
                  <option value="">All</option>
                  <option value="">All</option>
                </select>
              </div>
              <a href="#" className="post-update"  data-bs-dismiss="modal" onClick={()=>{setShowPostModal(true)}}>
                <img src={PlusCircle} className="me-2" />
                Post an update
              </a>
              {rfqStatusDetail != undefined && rfqStatusDetail.length == 0 ? (
                <ul>
                  <h1 className="noUpdate">No updates available..!!</h1>
                </ul>
              ) : (
                <ul>
                  {rfqStatusDetail != undefined &&
                    rfqStatusDetail.length > 0 &&
                    rfqStatusDetail.map((item, index) => (
                      <li>
                        <span className="date">{moment(item.createdDate).format("ddd, DD MMM | h:mm A ")}</span>
                        <div className="status-update">
                          <div>
                            <span className="logo">{item.User.name.substring(0, 1)}</span>
                            {item.User.name}
                          </div>
                          <div className="status">
                            <span className={getClassName(item.status)}></span>
                            {item.status}
                          </div>
                        </div>
                        <p>Subject: {item.subject}</p>
                        <p>Message: {item.message}</p>
                        {/* <div className="resolve">
                          <img src={Tick} alt="tick" /> Marked Resolved
                        </div> */}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      {showPostModal &&  <PostUpdate rfqId={rfId} showPostModal={showPostModal} setShowPostModal={setShowPostModal} internalUsers={internalUsers} /> }
    </>
  );
};
