import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSamples } from "../../features/sample/action";
import { notify } from "../../features/notification/notify";
import { useLocation, useNavigate } from "react-router-dom";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { resetRfq } from "../../features/rfq/slice";
import { ToastContainer } from "react-toastify";
import { addSampleToRfq, getSampleByRfqId } from "../../features/rfq/action";
import { resetSample } from "../../features/sample/slice";
import { CONSTANT } from "../../utils/constant";
import { getCategory, getSeason } from "../../features/common/action";
import { getUserByRoleName } from "../../features/userDetail/action";
import { resetCommon } from "../../features/common/slice";
import ReactPaginate from "react-paginate";

export const RFQSampleList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [samples, setSamples] = useState([]);
    const { state } = useLocation();
    const [rfqId, setRfqId] = useState();
    const rfqResponse = useSelector((state) => state.rfq);
    const [sampleOfSelectedRfq, setSampleOfSelectedRfq] = useState([]);
    const [factoryList, setFactoryList] = useState([]);
    const [buyerList, setBuyerList] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const sampleResponse = useSelector((state) => state.sample);
    const userResponse = useSelector((state) => state.userDetail);
    const commonResponse = useSelector((state) => state.common)
    const userData = JSON.parse(localStorage.getItem("authorization"));
    const role = userData?.roles.length > 0 ? userData?.roles[0] : "";
    const supplierId = (role.roleId === CONSTANT.USER_ROLE.SUPPLIER || role.roleId === CONSTANT.USER_ROLE.BUYER) && userData?.user ? userData.user.id : 0;
    const roleId = localStorage.getItem("roleId");
    const location = roleId == (CONSTANT.USER_ROLE.DESIGN || CONSTANT.USER_ROLE.OPERATIONS) ? userData.user.location : 0;

    const [filters, setFilters] = useState({
        search: '', category: '', employee: '', location: location,
        factory: '', mill: '', season: '', buyer: '', supplierId: supplierId
    })
    const [pageCount, setPageCount] = useState(0);
    const [itemCount, setItemCount] = useState(CONSTANT.SAMPLE_PAGE_LIMIT);
    const [currentPage, setcurrentPage] = useState(0);
    const [ttlSample, setTtlSample] = useState(0);
    const isFirstRender = useRef(true);

    useEffect(() => {
        dispatch(getCategory());
        dispatch(getSeason());
        dispatch(getUserByRoleName('Supplier,Buyer'));
        if (!isFirstRender.current) {
            callAllSample();
        }
        isFirstRender.current = false;
    }, [currentPage, itemCount]);

    useEffect(() => {
        callAllSample();
    }, [filters]);

    const callAllSample = () => {
        dispatch(getAllSamples({ page: currentPage + 1, limit: itemCount, filters: filters }));
    }

    useEffect(() => {
        if (state && state.rfqId) {
            setRfqId(state.rfqId)
        }
    }, []);

    useEffect(() => {
        if (rfqId) {
            //dispatch(getAllSamples({ page: 1, limit: 10 }));
            dispatch(getSampleByRfqId(rfqId));
        }
    }, [rfqId]);

    useEffect(() => {
        if (userResponse.actionName === "userByRoleName") {
            setFactoryList(userResponse.roleDetails.filter(x => x.Roles[0].role == 'Supplier'));
            setBuyerList(userResponse.roleDetails.filter(x => x.Roles[0].role == 'Buyer'));
        }
    }, [userResponse]);

    useEffect(() => {
        if (commonResponse.actionName === "getCategory" && commonResponse.success) {
            setCategoryList(commonResponse.category)
            dispatch(resetCommon());
        }
        if (commonResponse.actionName === "getSeason" && commonResponse.success) {
            setSeasons(commonResponse.season)
            dispatch(resetCommon());
        }
    }, [commonResponse]);
    useEffect(() => {
        if (sampleResponse.actionName === "getAllSamples" && sampleResponse.success) {
            sampleResponse.allsamples[0] && setSamples(sampleResponse.allsamples[0]);
            setTtlSample(sampleResponse.allsamples[1])
            dispatch(resetSample())
            if (sampleResponse.allsamples[1] !== null && pageCount === 0) {
                setPageCount(Math.ceil(sampleResponse.allsamples[1] / itemCount));
            }
        }
    }, [sampleResponse]);

    useEffect(() => {
        if (rfqResponse.actionName == 'addSampleToRfq' && rfqResponse.success) {
            notify(rfqResponse.message, 'success')
            dispatch(resetRfq());
            dispatch(getSampleByRfqId(rfqId));
        }
        if (rfqResponse.actionName === "getSampleByRfqId" && rfqResponse.success) {
            setSampleOfSelectedRfq(rfqResponse.rfqSampleList.rfqSamplesInfo);
            dispatch(resetRfq());
        }
    }, [rfqResponse]);



    const handleSampleClick = (id, e) => {
        const { name, checked } = e.target;
        var payload = [{ rfqId: rfqId, sampleId: id, isChecked: e.target.checked }]
        dispatch(addSampleToRfq(payload));
    }
    const handleBack = () => {
        navigate("/rfq-info", {
            replace: true,
            state: { rfqId: rfqId },
        });
    }
    const resetFilter = () => {
        setFilters({
            search: '', category: '', employee: '', location: location,
            factory: '', mill: '', season: '', buyer: '', supplierId: supplierId
        })
    }
    const handleChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    }

    const handlePageChange = (selectedObject) => {
        setcurrentPage(selectedObject.selected);
    };
    const handleItemsChange = (event) => {
        setItemCount(event.target.value);
        setcurrentPage(0);
    };
    return (
        <>
            <LeftMenu />
            <div className="right-column">
                <Header />
                <div className="body-wrap">
                    <div className="top-heading">
                        <div className="d-flex">
                            <a onClick={handleBack} className="back">&#8249;</a>
                            <h2 >Sample List</h2> &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="search-top">
                                <input onChange={handleChange} value={filters.search} name="search" type="text" placeholder="Search by Sample name, size, color, style" />
                            </div>
                        </div>

                    </div>
                    <div className=" row">
                        <div className="col-md-2">
                            <div>
                                <select onChange={handleChange} value={filters.factory} name="factory" className="form-select">
                                    <option value="">Factory</option>
                                    {factoryList.length > 0 && factoryList.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name ? item.name : item.emailId}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <select onChange={handleChange} value={filters.mill} name="mill" className="form-select">
                                    <option value="">Mill</option>
                                    {factoryList.length > 0 && factoryList.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name ? item.name : item.emailId}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <select onChange={handleChange} value={filters.season} name="season" className="form-select">
                                    <option value="">Season</option>
                                    {seasons.length > 0 && seasons.map((item, index) => (
                                        <option key={index} value={item.id}>{item.seasonName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <select onChange={handleChange} value={filters.buyer} name="buyer" className="form-select">
                                    <option value="">Buyer</option>
                                    {buyerList.length > 0 && buyerList.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name ? item.name : item.emailId}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <select onChange={handleChange} value={filters.category} name="category" className="form-select">
                                    <option value="">Category</option>
                                    {categoryList.length > 0 && categoryList.map((item, index) => (
                                        <option key={index} value={item.id}>{item.categoryName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-1">
                            <button className="btn btn-rst-smpl" onClick={resetFilter}>Reset</button>
                        </div>
                    </div>
                    <br />
                    <div className="row row-gap-4">
                        {samples &&
                            samples.map((sample, index) => (
                                <div className="col-xxl-2 col-xl-3 col-lg-4 col-sm-6" >
                                    <div className="category-item">
                                        <div className="category-img" style={{ cursor: 'pointer' }} >
                                            {sample.sampleFiles &&
                                                sample.sampleFiles
                                                    .filter((file) => file.fileType === "image")
                                                    .slice(0, 1)
                                                    .map((image, imageIndex) => (
                                                        <img
                                                            key={imageIndex}
                                                            src={image.filePath}
                                                            alt={`sample-${index}-main-image`}
                                                        />
                                                    ))}
                                            <div className="category-side-img">
                                                {sample.sampleFiles &&
                                                    sample.sampleFiles
                                                        .filter((file) => file.fileType === "image")
                                                        .slice(1)
                                                        .map((sideFile, sideFileIndex) => (
                                                            <img
                                                                key={sideFileIndex}
                                                                src={sideFile.filePath}
                                                                alt={`sample-${index}-side-image-${sideFileIndex + 1
                                                                    }`}
                                                            />
                                                        ))}
                                            </div>
                                        </div>
                                        <div className="category-detail">
                                            <div className="category-row">
                                                <div style={{ cursor: 'pointer' }}>
                                                    <span className="icon">{sample.id}</span>
                                                    {sample.sampleName}
                                                </div>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    onClick={(e) => handleSampleClick(sample.id, e)}
                                                    checked={sampleOfSelectedRfq?.find(x => x.sampleDetail.id == sample.id) != null}
                                                // disabled={sampleOfSelectedRfq?.find(x => x.sampleDetail.id == sample.id) != null}
                                                />
                                            </div>
                                            <div className="row" style={{ cursor: 'pointer' }}>
                                                <div className="col-4" title={sample.User ? sample.User.name : ""}> 
                                                    <span>Buyer</span>
                                                    <div className="category-name">{sample.User ? sample.User.name : "--"}</div>
                                                </div>
                                                <div className="col-4" title={sample.sampleName ? sample.sampleName : ""}>
                                                    <span>Product</span>
                                                    <div className="category-name">
                                                        {sample.sampleName ? sample.sampleName:"--" }
                                                    </div>
                                                </div>
                                                <div className="col-4" title={sample.season ? sample.season.sampleName : ""}>
                                                    <span>Season</span>
                                                    <div className="category-name">{sample.season ? sample.season?.seasonName : "--"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    {ttlSample > 10 && (
                        <nav className="pagination-block">
                            <p className="total">
                                {ttlSample} items total
                            </p>

                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                forcePage={currentPage}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination justify-content-center mt-4"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                            <div className="customSelect">
                                <label htmlFor="">Items per page :</label>
                                <div className="selectBox">
                                    <select name="" id="" onChange={handleItemsChange}>
                                        <option value={CONSTANT.SAMPLE_PAGE_LIMIT}>{CONSTANT.SAMPLE_PAGE_LIMIT}</option>
                                        <option value="28">28</option>

                                        <option value="42">42</option>
                                    </select>
                                </div>
                            </div>
                        </nav>
                    )}
                </div>
            </div>
        </>
    );
}