import { createSlice } from "@reduxjs/toolkit";
import { addUpdateSO, getCustomer, getSampleItem, addUpdateItem, getSoCommonData, getItemCommonData, getAllSO, getSellingAccountSubTypeId, getPurchaseAccountSubTypeId, getSupplier, createBuyerIfNotExist, addUpdatePO, getSoById ,deleteSo,
  createManufacturerIfNotExist
 } from "./action";
// import {  } from "./action";


export const SoPo = createSlice({
  name: "SoPo",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message: null,
    actionName: null,
    customers: null,
    sampleItem: null,
    soCommonData : null,
    itemCommonData : null,
    soList: null,
    createdItem : null
  },

  reducers: {
    resetSoPo: (state, action) => {
      state.success = false
      state.actionName = null
      state.message = null
    },
  },

  extraReducers: {
    [getCustomer.pending]: (state) => {
      state.loading = true;
    },
    [getCustomer.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.customers = action.payload.allBuyersOfZoho;
      state.actionName = "getCustomer";
    },
    [getCustomer.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },

    [getSupplier.pending]: (state) => {
      state.loading = true;
    },
    [getSupplier.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.suppliers = action.payload.allSuppliersOfZoho;
      state.actionName = "getSupplier";
    },
    [getSupplier.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },

    [getSampleItem.pending]: (state) => {
      state.loading = true;
    },
    [getSampleItem.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.sampleItem = action.payload.allItemsOfZoho;
      state.actionName = "getSampleItem";
    },
    [getSampleItem.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [getSoCommonData.pending]: (state) => {
      state.loading = true;
    },
    [getSoCommonData.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.soCommonData = action.payload;
      state.actionName = "getSoCommonData";
    },
    [getSoCommonData.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [getItemCommonData.pending]: (state) => {
      state.loading = true;
    },
    [getItemCommonData.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.itemCommonData = action.payload;
      state.actionName = "getItemCommonData";
    },
    [getItemCommonData.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [addUpdateSO.pending]: (state) => {
      state.loading = true;
    },
    [addUpdateSO.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;;
      state.actionName = "addUpdateSO";
    },
    [addUpdateSO.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [addUpdatePO.pending]: (state) => {
      state.loading = true;
    },
    [addUpdatePO.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;;
      state.actionName = "addUpdatePO";
    },
    [addUpdatePO.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [addUpdateItem.pending]: (state) => {
      state.loading = true;
    },
    [addUpdateItem.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.createdItem = action.payload.item;
      state.actionName = "addUpdateItem";
    },
    [addUpdateItem.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [getAllSO.pending]: (state) => {
      state.loading = true;
    },
    [getAllSO.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.soList = action.payload.allSo;
      state.actionName = "getAllSO";
    },
    [getAllSO.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },

    [getSellingAccountSubTypeId.pending]: (state) => {
      state.loading = true;
    },
    [getSellingAccountSubTypeId.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.sellingAccountSubType = action.payload.data;
      state.actionName = "getSellingAccountSubTypeId";
    },
    [getSellingAccountSubTypeId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getPurchaseAccountSubTypeId.pending]: (state) => {
      state.loading = true;
    },
    [getPurchaseAccountSubTypeId.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.purchaseAccountSubType = action.payload.data;
      state.actionName = "getPurchaseAccountSubTypeId";
    },
    [getPurchaseAccountSubTypeId.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  
    [createBuyerIfNotExist.pending]: (state) => {
      state.loading = true;
    },
    [createBuyerIfNotExist.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.buyerId = action.payload.data;
      state.actionName = "createBuyerIfNotExist";
    },
    [createBuyerIfNotExist.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.message;
    },

    [getSoById.pending]: (state) => {
      state.loading = true;
    },
    [getSoById.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.soData = action.payload.data;
      state.actionName = "getSoById"
    },
    [getSoById.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [deleteSo.pending]: (state) => {
      state.loading = true;
    },
    [deleteSo.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.success;
      state.actionName = "deleteSo";
    },
    [deleteSo.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },

    [createManufacturerIfNotExist.pending]: (state) => {
      state.loading = true;
    },
    [createManufacturerIfNotExist.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.supplierId = action.payload.data;
      state.actionName = "createManufacturerIfNotExist";
    },
    [createManufacturerIfNotExist.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.message;
    },

  },
});

export default SoPo.reducer;

export const { resetSoPo } = SoPo.actions;
