import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getAllCategories } from "../../features/product/action";
import { CONSTANT } from "../../utils/constant";
import ReactPaginate from "react-paginate";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { getAllSubCategories } from "../../features/product/action";
import { resetProduct } from "../../features/product/slice";
export const AllCategories = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [categoryListLimit, setCategoryListLimit] = useState(0);
    const [totalCatSub, setTotalCatSub] = useState(0);
    const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
    const pageNumber = localStorage.getItem("currentPage") ? localStorage.getItem("currentPage") : 0;
    const [currentPage, setcurrentPage] = useState(parseInt(pageNumber));
    const [saveAllCategories, setSaveAllCategories] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const isFirstRender = useRef(true);
    useEffect(() => {
        callCategoryData()
    }, []);


    useEffect(() => {
        if (!isFirstRender.current) {
            callCategoryData()
        }
        isFirstRender.current = false;
    }, [currentPage, itemCount]);

    const callCategoryData = () => {
        dispatch(getAllCategories({
            page: currentPage + 1,
            limit: itemCount
        })
        );

    };

    const categoryResponse = useSelector((state) => state.product);
    useEffect(() => {
        if (categoryResponse.actionName == "getAllCategories") {
            categoryResponse?.allCategories?.returnResult &&
                setCategories(categoryResponse?.allCategories?.returnResult);

        }

        if (categoryResponse?.allCategories?.totalCount!= null ) {
            setPageCount(Math.ceil(categoryResponse?.allCategories?.totalCount / itemCount));
        }
        

    }, [categoryResponse]);


    function handlePageChange(selectedObject) {
            setcurrentPage(selectedObject.selected);
    };

    function goToEditPage(item) {
        if (item.categoryId) {
            let obj1 = { id: "", parentId: "", categoryName: "" }
            obj1.id = item.id;
            obj1.parentId = item.categoryId;
            obj1.categoryName = item.subCategoryName;
            item = { ...obj1 }
            navigate("/category/add", { state: { item } })
        }
        else {
            navigate("/category/add", { state: { item } })
        }

    };
    const addNewCategory = () => {
        navigate("/category/add")
    }
    const handleItemsChange = (event) => {
        setItemCount(event.target.value);
        setcurrentPage(0);
    };

    const handleCancel = () => {
        navigate("/all-categories");
    }


    console.log("save categories ", saveAllCategories)
    return (
        <>
            <LeftMenu />

            <div className="right-column">
                <Header />
                <div className="body-wrap">
                    <div className="top-heading">
                        <div className="d-flex">
                            <a onClick={handleCancel} className="back">
                                &#8249;
                            </a>
                            <h2>All Categories</h2>
                        </div>
                        <div className="d-flex gap-2">
                            <button onClick={addNewCategory} className="btn ornge-brdr-btn">Add New Category</button>
                        </div>
                    </div>

                    <div className="rfq-status-table tab-content">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Category Name</th>
                                        <th>Parent Name</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categories &&
                                        categories?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.id}</td>
                                                <td>{item.name} </td>
                                                {/* { handleSaveAllCategories(item.id,item.categoryName)} */}
                                                {item.parentName!="" ? <td>{item.parentName}  </td> :<td>--</td> }
                                                <td>
                                                    <a onClick={() => goToEditPage(item)} title="Edit Category"><i className="fa-regular fa-pen-to-square mr-2"></i></a>
                                                </td>
                                            </tr>
                                        ))}

                                </tbody>
                            </table>
                            <nav className="pagination-block">
                                <p className="total">
                                    {categoryResponse?.allCategories?.totalCount} items total
                                </p>

                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    onPageChange={handlePageChange}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    forcePage={currentPage}
                                    containerClassName={"pagination justify-content-center mt-4"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-lin k"}
                                    activeClassName={"active"}
                                />
                                <div className="customSelect">
                                    <label htmlFor="">Items per page :</label>
                                    <div className="selectBox">
                                        <select name="" id="" onChange={handleItemsChange}>
                                            <option value={CONSTANT.PAGE_LIMIT}>
                                                {CONSTANT.PAGE_LIMIT}
                                            </option>
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                        </select>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};