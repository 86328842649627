import { configureStore } from "@reduxjs/toolkit";
import userDetail from "../features/userDetail/slice";
import userLogin from "../features/login/slice";
import common from "../features/common/slice";
import guarantor from "../features/member/finance/guarantor/slice";
import dashboard from "../features/member/finance/dashboard/slice"
import operation from "../features/member/operations/slice"
import scFinance from "../features/member/scfianance/slice";
import  userSignup  from "../features/signup/slice";
import sample from "../features/sample/slice";
import rfq from "../features/rfq/slice";
import chats from "../features/chats/slice";
import rfqSampleComment from "../features/rfqComment/slice";
import userManagememnt from "../features/user_management/slice";
import uploadTNA from "../features/uploadTNA/slice";
import product  from "../features/product/slice";
import milestone  from "../features/milestone/slice";
import  Tna  from "../features/Tna/slice";
import  po  from "../features/po/slice";
import poChat from "../features/poChats/slice";
import soPo from "../features/soPo/slice";


export const store = configureStore({
  reducer: {
    userDetail,
    userLogin,
    userSignup,
    common,
    guarantor,
    dashboard,
    operation,
    scFinance,
    sample,
    rfq,
    chats,
    rfqSampleComment,
    uploadTNA,
    userManagememnt,
    product,
    milestone,
    Tna,
    po,
    poChat,
    soPo
  },
});
