import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {handleDateClick} from "../../utils/datePickerHelper";
import { resetRfq } from "../../features/rfq/slice";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRfq,
  getAllFactoryRfq,
  getUserByRoleType,
} from "../../features/rfq/action";
import { CONSTANT } from "../../utils/constant";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { resetCommon } from "../../features/common/slice";
import { getCategory, getRfqStatus } from "../../features/common/action";
import { Tooltip } from "react-tooltip";
import Select from "react-select";
import { getUserByRoleName } from "../../features/userDetail/action";
import { resetUserDetail } from "../../features/userDetail/slice";
import { notify } from "../../features/notification/notify";

export const RfqList = () => {
  const [active, setActive] = useState(false);
  const rfqResponse = useSelector((state) => state.rfq);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const today = new Date();
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const isFirstRender = useRef(true);
  const [rfqList, setRfqList] = useState([]);
  const [ttlRfq, setTtlRfq] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [creatorList, setCreatorList] = useState([]);
  const [assineeOption, setAssineeOption] = useState([]);
  const [creatorOption, setCreatorOption] = useState([]);
  const [buyerOption, setBuyerOption] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [statusOption, setStatusOption] = useState([    
    { value: 'Completed', label: 'Completed' },
    { value: 'Critical', label: 'Critical' },
    { value: 'Delayed', label: 'Delayed' },
    { value: 'On Hold', label: 'On Hold' },
    { value: 'On Track', label: 'On Track' },
  ]);

  const [buyerList, setBuyerList] = useState([]);
  const userData = JSON.parse(localStorage.getItem("authorization"));
  const roleId = localStorage.getItem("roleId");
  const location = (roleId == CONSTANT.USER_ROLE.DESIGN || roleId == CONSTANT.USER_ROLE.OPERATIONS) ? userData.user.location : 0;
  const [placeholderText, setPlaceholderText] = useState("Assignee: All");
  const [filters, setFilters] = useState({
    status: "",
    category: "",
    createDate: "",
    assignee: "",
    buyer: "",
    search: "",
    creator: "",
    lastUpdatedSort: "INIT",
    location: location
  });
  const commonResponse = useSelector((state) => state.common);
  const userResponse = useSelector((state) => state.userDetail);

  const changeColor = () => {
    setActive(!active);
  };

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getRfqStatus());
    dispatch(getUserByRoleType({ rollType: "Internal", userId: 0 }));
    dispatch(getUserByRoleName("Buyer"));
    // if (!isFirstRender.current) {
    callAllRfqs();
    // }
    // isFirstRender.current = false;
  }, [currentPage, itemCount]);

  useEffect(() => {
    callAllRfqs();
  }, [filters]);

  const callAllRfqs = () => {
    let userData = JSON.parse(localStorage.getItem("authorization"));
    let role = userData?.roles ? userData?.roles[0] : "";
    let factoryId = 0;
    if (
      role.roleId === CONSTANT.USER_ROLE.SUPPLIER ||
      role.roleId === CONSTANT.USER_ROLE.BUYER
    ) {
      factoryId = userData?.user ? userData.user.id : 0;
    }
    dispatch(
      getAllRfq({
        page: currentPage + 1,
        limit: itemCount,
        filters: filters,
        factoryId: factoryId,
      })
    );
  };

  useEffect(() => {
    if (
      (rfqResponse.actionName == "getAllRfq" ||
        rfqResponse.actionName == "getAllFactoryRfq") &&
      rfqResponse.success
    ) {
      setRfqList(rfqResponse.allRfq[0]);
      setTtlRfq(rfqResponse.allRfq[1]);
      dispatch(resetRfq());
      if (rfqResponse.allRfq[1] !== null && pageCount === 0) {
        setPageCount(Math.ceil(rfqResponse.allRfq[1] / itemCount));
      }
    }
    if (rfqResponse.actionName == "userByRoleType" && rfqResponse.success) {
      setAssigneeList(rfqResponse.roleDetails);
      setCreatorList(rfqResponse.roleDetails)
      dispatch(resetRfq());
    }
    if (rfqResponse.actionName == "addBulkRfq" && rfqResponse.success) {
      notify("All RFQs Created Successfully.", "success");
    }
  }, [rfqResponse]);

  useEffect(() => {
    if (commonResponse.actionName === "getCategory" && commonResponse.success) {
      setCategoryList(commonResponse.category);
      dispatch(resetCommon());
    }
  }, [commonResponse]);

  useEffect(() => {
    if (userResponse.actionName === "userByRoleName" && userResponse.success) {
      setBuyerList(userResponse.roleDetails);
      dispatch(resetUserDetail());
    }
  }, [userResponse]);

  useEffect(() => {
    let assigneeArr = [];
    assigneeList &&
      assigneeList.map((assinee) => {
        let list = {
          value: assinee.id,
          label: assinee.name ? assinee.name : assinee.emailId,
        };
        assigneeArr.push(list);
      });
    setAssineeOption(assigneeArr.sort((a, b) => a.label.localeCompare(b.label)));
  }, [assigneeList]);

  useEffect(() => {
    let  creatorArr = [];
    creatorList &&
    creatorList.map((creator) => {
        let list = {
          value: creator.id,
          label: creator.name ? creator.name : creator.emailId,
        };
        creatorArr.push(list);
      });
    setCreatorOption(creatorArr.sort((a, b) => a.label.localeCompare(b.label)));
  }, [creatorList]);

  useEffect(() => {
    let buyerArr = [];
    if (buyerList.length > 0)
      buyerList.map((item, index) => {
        let list = {
          value: item.id,
          label: item.name ? item.name : item.emailId,
        };
        buyerArr.push(list);
      })
    setBuyerOption(buyerArr.sort((a, b) => a.label.localeCompare(b.label)));
  }, [buyerList]);

  useEffect(() => {
    let categoryArr = [];
    if (categoryList.length > 0) {
      categoryList.map((item, index) => {
        let list = {
          value: item.categoryName,
          label: item.categoryName,
        };
        categoryArr.push(list);
      })
    }
    setCategoryOption(categoryArr.sort((a, b) => a.label.localeCompare(b.label)));
  }, [categoryList]);


  const rfqDetails = (rfqId) => {
    navigate("/rfq-info", {
      replace: true,
      state: { rfqId: rfqId, chat: false },
    });
  };
 
  const handleViewChat = (rfqId) => {
    navigate("/rfq-info", {
      replace: true,
      state: { rfqId: rfqId, chat: true },
    });
  };

  const handleAddRfq = () => {
    navigate("/add-update-rfq");
  };

  const getColorClass = (status) => {
    switch (status) {
      case "On Track":
        return "status green";
      case "On Hold":
        return "status yellow";
      case "Delayed":
        return "status red";
      case "Critical":
        return "status blue";
      case "Completed":
        return "status orange";
      default:
        return "";
    }
  };

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleAssigneeChange = (selectedAssignee) => {
    setFilters({
      ...filters,
      assignee: selectedAssignee.value,
    });
  };

  const handleCreatorChange = (selectedCreator) => {
    setFilters({
      ...filters,
      creator: selectedCreator.value,
    });
  };

  const handleBuyerChange = (selectedBuyer) => {
    setFilters({
      ...filters,
      buyer: selectedBuyer.value,
    });
  };
  const handleCategoryChange = (category) => {
    setFilters({
      ...filters,
      category: category.value,
    });
  };
  const handleStatusChange = (status) => {
    setFilters({
      ...filters,
      status: status.value,
    });
  };
  const resetFilter = () => {
    setFilters({
      status: "",
      category: "",
      createDate: "",
      creator: "",
      assignee: "",
      buyer: "",
      search: "",
      lastUpdatedSort: "ASC",
    });
    setPlaceholderText("Assignee: All");
  };

  const calculateDays = (modDate) => {
    let temp = modDate && moment(new Date()).diff(modDate, "days");
    if (temp) return temp + " Day(s) ago";
    else if (temp == 0) return "Today";
    else return "NA";
  };

  const arrangeOrder = (order) => {
    setFilters({
      ...filters,
      lastUpdatedSort: order,
    });
  };
  
  return (
    <>
      <LeftMenu />
      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <div className="top-heading">
            <div className="d-flex gap-2 align-items-center flex-wrap">
              <h2>RFQs</h2>
              <div className="search-top">
                <input
                  onChange={handleChange}
                  value={filters.search}
                  name="search"
                  type="text"
                  placeholder="Search by RFQ name"
                />
              </div>
            </div>
            {localStorage.getItem("roleType") == "Internal" && (
              <div className="dropdown">
                <button
                  className="btn ornge-btn"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  &#43; Add New RFQ
                </button>

                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" onClick={handleAddRfq}>
                      Add Single RFQ
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/addbulkRfq">
                      Add Bulk RFQ
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-2">
              <Select
                value={filters.status}
                onChange={handleStatusChange}
                options={statusOption}
                placeholder={filters.status == "" ? 'Status: All' : filters.status}
              />
            </div>
            <div className="col-md-2">

              <Select
                value={filters.category}
                onChange={handleCategoryChange}
                options={categoryOption}
                placeholder={filters.category == "" ? 'Category: All' : filters.category}
              />
            </div>
            <div className="col-md-2">
              <Select
                value={filters.buyer}
                onChange={handleBuyerChange}
                options={buyerOption}
                placeholder={filters.buyer == "" ? 'Buyer: All' :  buyerOption.find(x => x.value == filters.buyer).label}
              />
            </div>
            <div className="col-md-2">
              <Select
                value={filters.assignee}
                onChange={handleAssigneeChange}
                options={assineeOption}
                placeholder={filters.assignee == "" ? placeholderText :  assineeOption.find(x => x.value == filters.assignee).label}

              />
            </div>
            <div className="col-md-2">
              <Select
                value={filters.creator}
                onChange={handleCreatorChange}
                options={creatorOption}
                placeholder={filters.creator == "" ? "Created By: All" :  creatorOption.find(x => x.value == filters.creator).label}

              />
            </div>
            <div className="col-md-1">
              <button className="btn btn-rst-smpl" onClick={resetFilter}>
                Reset
              </button>
            </div>
          </div>

          <div className="rfq-status-table tab-content">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>RFQ Name</th>
                    <th>RFQ Date</th>
                    <th>Assignee</th>
                    <th>Category</th>
                    <th>Buyer</th>
                    <th>Style Code</th>
                    <th>Conversations</th>
                    <th>Latest Comments</th>
                    <th>Status</th>
                    <th>
                      Last Updated &nbsp;
                      {filters.lastUpdatedSort == "DESC" ? (
                        <i
                          onClick={() => arrangeOrder("ASC")}
                          className="fa fa-arrow-down"
                        ></i>
                      ) : (
                        <i
                          onClick={() => arrangeOrder("DESC")}
                          className="fa fa-arrow-up"
                        ></i>
                      )}
                    </th>
                    <th>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="user-tab"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rfqList.length > 0 &&
                    rfqList.map((item, index) => (
                      <tr key={index} style={{ cursor: "pointer" }}>
                        <td>{index + 1}</td>
                        <td onClick={(e) => rfqDetails(item.id)}>
                          {item.rFQName}{" "}
                        </td>
                        <td>
                          {" "}
                          {moment(item.createdDate).format("YYYY-MM-DD")}
                        </td>
                       {(item?.Users && item.Users.length > 0) ? <td
                          data-tooltip-id="my-tooltip-inline"
                          data-tooltip-content={
                            item?.Users
                              ? item.Users.map(
                                (innerItem, index) => innerItem.name ? innerItem.name : innerItem.emailId
                              )
                              : ""
                          }
                        >
                          {(item?.Users && item.Users.length) || "-"} Person(s)
                        </td> : <td> NA</td> }
                        <td>{item.category?.categoryName}</td>
                        <td>
                          {buyerList.find((x) => x.id == item.brand)?.name}
                        </td>
                        <td>{item.styleNo || "-"}</td>
                        <td>
                          <a
                            href="#"
                            onClick={() => {
                              handleViewChat(item.id);
                            }}
                          >
                            View
                          </a>
                        </td>
                        <td
                          data-tooltip-id="my-tooltip-inline"
                          data-tooltip-content={item.rfqStatuses.at(0)?.message}>
                          {item.rfqStatuses.at(0)?.message && item.rfqStatuses.at(0)?.message.substring(0, 10) + "..." || "NA"}</td>
                        <td
                          data-tooltip-id="my-tooltip-inline"
                          data-tooltip-content={item.rfqStatuses.at(0)?.status}
                        >
                          <span className={getColorClass(item.rfqStatuses?.at(0)?.status)}></span>
                        </td>

                        <td>
                          {calculateDays(item.rfqStatuses.at(0)?.createdDate)}
                        </td>
                        {/* <td>{item.rfqStatuses.at(0)?.createdDate && moment(new Date()).diff(item.rfqStatuses.at(0)?.createdDate, 'days') ? moment(new Date()).diff(item.rfqStatuses.at(0)?.createdDate, 'days') + " Days ago " : 'Today' }  </td> */}
                        <td>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={item.isActive}
                            name="user-tab"
                          />
                        </td>
                      </tr>
                    ))}
                  {rfqList.length == 0 && (
                    <tr>
                      <td colSpan={12} className="text-center">
                        No RFQ Found!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {ttlRfq > 10 && (
              <nav className="pagination-block">
                <p className="total">{ttlRfq} items total</p>

                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  forcePage={currentPage}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination justify-content-center mt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
                <div className="customSelect">
                  <label htmlFor="">Items per page :</label>
                  <div className="selectBox">
                    <select name="" id="" onChange={handleItemsChange}>
                      <option value={CONSTANT.PAGE_LIMIT}>
                        {CONSTANT.PAGE_LIMIT}
                      </option>
                      <option value="15">15</option>

                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              </nav>
            )}
          </div>
        </div>
      </div>
      <Tooltip
        id="my-tooltip-inline"
        style={{
          backgroundColor: "#FE4900",
          color: "white",
          fontWeight: "bolder",
        }}
      />
    </>
  );
};
