import React, { useEffect, useRef, useState } from "react";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { handleDateClick } from "../../utils/datePickerHelper";
import { getUserByRoleType } from "../../features/rfq/action";
import { useDispatch, useSelector } from "react-redux";
import uploadImg from "../../assets/images/upload-image.svg";
import { numberFormat, SO, TERMS_CONDITIONS, transformArrayOption } from "./soDataOption";
import { addUpdateSO, getCustomer, getItemCommonData, getSampleItem, getSoCommonData, getSupplier } from "../../features/soPo/action";
import { resetSoPo } from "../../features/soPo/slice";
import { resetCommon } from "../../features/common/slice";
import { getCategory, getSubCategoryByCategoryId } from "../../features/common/action";
import { getUserByRoleName } from "../../features/userDetail/action";
import { notify } from "../../features/notification/notify";
import { confirmAlert } from "react-confirm-alert";
import deleteImg from "../../assets/images/deleteicon.png";
import { v1 as uuidv1 } from "uuid";
import { AddUpdateItem } from "./addUpdateItem";
import { getSoById } from "../../features/soPo/action";
import moment from "moment";
import { resetUserDetail } from "../../features/userDetail/slice";
import { ItemList } from "./soComponent/ItemList";
import { createBuyerIfNotExist } from "../../features/soPo/action";

export const CreateSO = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currencyOption, setCurrencyOption] = useState()
    const [stateOption, setStateOption] = useState(SO.STATE)
    const [branchOption, setBranchOption] = useState()
    const [paymentOption, setPaymentOption] = useState()
    const [deliveryOption, setDeliveryOption] = useState()
    const [salesPersonOption, setSalesPersonOption] = useState([])
    const [pdcOption, setPdcOption] = useState(SO.PDC_OPTION)
    const [approverOption, setApproverOption] = useState(SO.APPROVER)
    const [businessLineOption, setBusinessLineOption] = useState(SO.BUSINESS_LINE)
    const [productTypeOption, setProductTypeOption] = useState(SO.PRODUCT_TYPE)
    const [customerOption, setCustomerOption] = useState([])
    const [itemOption, setItemOption] = useState([])
    const rfqResponse = useSelector((state) => state.rfq);
    const sopoResponse = useSelector((state) => state.soPo);
    const [itemSearch, setItemSearch] = useState()
    const [itemRows, setItemRows] = useState([]);
    const [soFiles, setSOFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [manufacturerOptions, setManufacturerOptions] = useState([])
    const btnRef = useRef(null)
    const { state } = useLocation();
    const [soId, setSoId] = useState();
    const [soUpdateDetail, setSoUpdateDetail] = useState();
    const [categoryOption, setCategoryOption] = useState([])

    const commonResponse = useSelector((state) => state.common);
    const userResponse = useSelector((state) => state.userDetail);

    const [taxPreferenceOptions, setTaxPreferenceOptions] = useState([])
    const [taxPercentOptions, setTaxPercentOptions] = useState([])
    const loggedInUser = localStorage.getItem("userId");
    const [createdItem, setCreatedItem] = useState()
    

    const [soDetails, setSODetails] = useState({
        salesOrder: (uuidv1()).substring(0, 10),
        customerName: '',
        currency: '',
        placeOfSupply: '',
        branch: '',
        reference: '',
        salesOrderDate: moment(new Date()).format("yyyy-MM-DD"),
        expectedShipDate: '',
        paymentTerms: '',
        deliveryMethod: '',
        salesPerson: '',
        pdcReceived: '',
        approver: '',
        businessType: '',
        businessLine: '',
        productType: '',
        buyerPODate: '',
        expectedMargin: '',
        subTotal: 0,
        shippingCharges: 0,
        tax: 0,
        taxType: 'TDS',
        discount: 0,
        total: 0,
        customerNotes: '',
        adjustment: 0,
        loggedUser: loggedInUser,
        status: 0
    })

    useEffect(() => {
        if (soUpdateDetail) {
            setSODetails({
                soId: soId,
                salesOrder: soUpdateDetail.salesOrder,
                customerName: soUpdateDetail.customerName,
                currency: soUpdateDetail.currency,
                placeOfSupply: soUpdateDetail.placeOfSupply,
                branch: soUpdateDetail.branch,
                reference: soUpdateDetail.reference,
                salesOrderDate: moment(soUpdateDetail.salesOrderDate).format("YYYY-MM-DD"),
                expectedShipDate: moment(soUpdateDetail.expectedShipmentDate).format("YYYY-MM-DD"),
                paymentTerms: soUpdateDetail.paymentTerms,
                deliveryMethod: soUpdateDetail.deliveryMethod,
                salesPerson: soUpdateDetail.salesPerson,
                pdcReceived: soUpdateDetail.PDCReceived,
                approver: soUpdateDetail.approver,
                businessType: soUpdateDetail.businessType,
                businessLine: soUpdateDetail.businessLine,
                productType: soUpdateDetail.productType,
                buyerPODate: moment(soUpdateDetail.buyerPoDate).format("YYYY-MM-DD"),
                expectedMargin: soUpdateDetail.expectedMargin,
                subTotal: soUpdateDetail.subTotal,
                shippingCharges: soUpdateDetail.shippingCharges,
                tax: soUpdateDetail.tax,
                taxType: soUpdateDetail.taxType,
                discount: soUpdateDetail.discount,
                total: soUpdateDetail.total,
                customerNotes: soUpdateDetail.customerNotes,
                adjustment: soUpdateDetail.adjustment,
                loggedUser: soUpdateDetail.loggedUser,
                status: soUpdateDetail.status
            });
            if (soUpdateDetail?.soSamples && soUpdateDetail?.soSamples.length > 0) {
                let temp = [...itemRows];
                soUpdateDetail.soSamples.map((smple, index) => {
                    temp.push({
                        id: index + 1, soSampleId: smple.id, sampleId: smple.sampleDetail.zohoItemId ? smple.sampleDetail.zohoItemId : smple.sampleDetail.id,
                        itemName: smple.sampleDetail?.sampleName ? smple.sampleDetail?.sampleName : null,
                        itemType: smple.itemType, qty: 1, rate: parseInt(smple.taxRate), taxType: smple.taxType,
                        amount: parseInt(smple.amount), isDeleted: false
                    })
                })
                setItemRows(temp);
            }
            if (soUpdateDetail?.soFiles && soUpdateDetail.soFiles.length > 0) {

                const attachImageFile = [...soFiles];
                soUpdateDetail.soFiles.map((item) => {
                    attachImageFile.push({
                        id: item.id,
                        fileName: item.fileName,
                        file: null,
                        isDeleted: false
                    });
                });
                setSOFiles(attachImageFile);
            }
        }
    }, [soUpdateDetail]);

    
    

    useEffect(() => {
        dispatch(getUserByRoleType({ rollType: "Internal", userId: 0 }));
        dispatch(getCustomer());
        dispatch(getSupplier());
        dispatch(getCategory());
        //dispatch(getUserByRoleName("Supplier"));
        dispatch(getSoCommonData());
        dispatch(getItemCommonData());
        if (state && state.Id) {
            setSoId(state.Id);
        }
    }, [])
    useEffect(() => {
        soId && dispatch(getSoById(soId));
    }, [soId])

    useEffect(() => {
        let percent = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.discount)
        setSODetails({ ...soDetails, total: parseInt(soDetails.subTotal) - (isNaN(percent) ? 0 : percent) })
    }, [soDetails.discount])

    useEffect(() => {
        let percent = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.discount)
        let finalAmount = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.tax)
        setSODetails({ ...soDetails, total: parseInt(soDetails.subTotal) - (isNaN(percent) ? 0 : percent) + (isNaN(parseInt(finalAmount)) ? 0 : parseInt(finalAmount)) + (isNaN(parseInt(soDetails.shippingCharges)) ? 0 : parseInt(soDetails.shippingCharges)) })
    }, [soDetails.tax])

    useEffect(() => {
        let percent = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.discount)
        let finalAmount = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.tax)
        setSODetails({ ...soDetails, total: parseInt(soDetails.subTotal) - (isNaN(percent) ? 0 : percent) + (isNaN(parseInt(soDetails.shippingCharges)) ? 0 : parseInt(soDetails.shippingCharges)) + (isNaN(parseInt(finalAmount)) ? 0 : parseInt(finalAmount)) })
    }, [soDetails.shippingCharges])

    useEffect(() => {
        if (parseInt(soDetails.subTotal) > 0) {
            setSODetails({ ...soDetails, total: (isNaN(parseInt(soDetails.subTotal)) ? 0 : parseInt(soDetails.subTotal)) })
        }
    }, [soDetails.subTotal])

    useEffect(() => {
        let percent = (parseInt(soDetails.subTotal) / 100) * parseInt(soDetails.discount)
        let shippingcharge = parseInt(soDetails.subTotal) - (isNaN(percent) ? 0 : percent) + (isNaN(parseInt(soDetails.shippingCharges)) ? 0 : parseInt(soDetails.shippingCharges))
        setSODetails({ ...soDetails, total: shippingcharge + (isNaN(parseInt(soDetails.adjustment)) ? 0 : parseInt(soDetails.adjustment)) })
    }, [soDetails.adjustment])

    useEffect(() => {
        if (commonResponse.actionName === "getCategory" && commonResponse.success) {
            const categoryOptions = commonResponse.category.map((item) => ({
                value: item.id,
                label: item.categoryName,
            }));
            setCategoryOption(categoryOptions)
            dispatch(resetCommon());
        }

    }, [commonResponse]);
    useEffect(() => {
        if (sopoResponse.actionName === "getCustomer" && sopoResponse.success) {
            let arr = [];
            if (sopoResponse.customers.totalBuyers.length > 0)
                sopoResponse.customers.totalBuyers.map((item) => {
                    let list = {
                        value: item.contact_id,
                        label: item.contact_name,
                    };
                    arr.push(list);
                })

            setCustomerOption(arr.sort((a, b) => a.label.localeCompare(b.label)));
            let arrItm = [];
            if (sopoResponse.customers.totalItems.length > 0)
                sopoResponse.customers.totalItems.map((item) => {
                    let list = {
                        value: item.item_id,
                        label: item.item_name,
                        rate: item.rate ? item.rate : 0
                    };
                    arrItm.push(list);
                })
            setItemOption(arrItm.sort((a, b) => a.label?.localeCompare(b.label)));
            dispatch(resetSoPo())
        }

        if (sopoResponse.actionName === "getSupplier" && sopoResponse.success) {
            let arr = [];
            if (sopoResponse.suppliers.totalSuppliers.length > 0)
                sopoResponse.suppliers.totalSuppliers.map((item) => {
                    let list = {
                        value: item.contact_id,
                        label: item.contact_name,
                    };
                    arr.push(list);
                })

            setManufacturerOptions(arr.sort((a, b) => a.label?.localeCompare(b.label)));
            dispatch(resetSoPo());
        }

        if (sopoResponse.actionName === "addUpdateSO" && sopoResponse.success) {
            if (soId)
                notify('Sales Order Updated successfully!', 'success')
            else
                notify('Sales Order added successfully!', 'success')
            handleBack()
            dispatch(resetSoPo())
        }
        if (sopoResponse.actionName === "getSoCommonData" && sopoResponse.success) {
            let soCommonData = sopoResponse.soCommonData
            setCurrencyOption(transformArrayOption(soCommonData.currencies, 'id', 'name'))
            setBranchOption(transformArrayOption(soCommonData.branches, 'id', 'name'))
            setDeliveryOption(transformArrayOption(soCommonData.deliveryMethods, 'id', 'name'))
            setPaymentOption(transformArrayOption(soCommonData.paymentTerms, 'id', 'name'))
            setTaxPercentOptions(transformArrayOption(soCommonData.tdsTaxes, 'tax', 'name'))
            dispatch(resetSoPo())
        }
        if (sopoResponse.actionName === "getItemCommonData" && sopoResponse.success) {
            let itemCommonData = sopoResponse.itemCommonData
            setTaxPreferenceOptions(transformArrayOption(itemCommonData.taxPreferences, 'id', 'name'))
            dispatch(resetSoPo())
        }
        if (sopoResponse.actionName == "getSoById" && sopoResponse.success) {
            setSoUpdateDetail(sopoResponse.soData);
            dispatch(resetSoPo());
        }

    }, [sopoResponse]);

    useEffect(() => {
        if (rfqResponse.actionName == "userByRoleType" && rfqResponse.success) {
            let arr = [];
            if (rfqResponse.roleDetails.length > 0)
                rfqResponse.roleDetails.map((item) => {
                    let list = {
                        value: item.id,
                        label: item.name ? item.name : item.emailId,
                    };
                    arr.push(list);
                })
            setSalesPersonOption(arr.sort((a, b) => a.label.localeCompare(b.label)));
        }
    }, [rfqResponse]);

    const soSchema = Yup.object().shape({
        customerName: Yup.string().required("Required"),
        currency: Yup.number().nullable().required("Required"),
        placeOfSupply: Yup.string().nullable().required("Required"),
        branch: Yup.number().nullable().required("Required"),
        reference: null,
        salesOrderDate: Yup.date().nullable().required("Required"),
        expectedShipDate: null,
        paymentTerms: null,
        deliveryMethod: null,
        salesPerson: null,
        pdcReceived: null,
        approver: Yup.number().nullable().required("Required"),
        businessType: null,
        businessLine: Yup.number().nullable().required("Required"),
        productType: Yup.string().required("Required"),
        buyerPODate: Yup.date().nullable().required("Required"),
        expectedMargin: Yup.number().nullable().required("Required")
    });
    const handleBack = () => {
        navigate('/so-list')
    }

    const handleChange = (e) => {
        setSODetails({ ...soDetails, [e.target.name]: e.target.value })
    }

    const changeDropdown = (e, name) => {
        setSODetails({ ...soDetails, [name]: e.value })
    }
    const handleSubmit = (type) => {
        let details
        if (type == 'final') {
            details = { ...soDetails, status: 1 }
        } else
            details = { ...soDetails }

        let formData = new FormData();
        const fileNames = [];
        soFiles.map((fileSet) => {
            formData.append("upload_file", fileSet.file);
            fileNames.push({
                id: fileSet.id,
                fileType: "SOFiles",
                fileName: fileSet.fileName,
                isDeleted: fileSet.isDeleted,
            });
        });
        formData.append("soDetails", JSON.stringify(details));
        formData.append("fileList", JSON.stringify(fileNames));
        formData.append("itemRows", JSON.stringify(itemRows));
        dispatch(addUpdateSO(formData))
    }

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()

    

    const openDropdown = () => {
        btnRef.current.click()
    }
    

    const handleFileSheetUpload = (e, type) => {
        if (e.target.files.length > 10) {
            notify("Only 10 files can be added at a time", "info")
            e.target.value = "";
            return;
        }
        Array.from(e.target.files).forEach(item => {
            const fileExtension = item?.name
                .split(".")
                .pop()
                .toLowerCase();
            var acceptedFormats = ["pdf", "xlsx", "docx", "csv", "xls", "doc", "jpeg", "jpg"];
            if (!acceptedFormats.includes(fileExtension)) {
                notify("File type not supported", "info")
                e.target.value = "";
                return;
            }
            else {
                const { file, newImageName } = getFile(item, fileExtension);
                setSOFiles((soFiles) => [
                    ...soFiles,
                    { file: file, fileName: newImageName, isDeleted: false, id: 0 },
                ]);
            }
        });
    };

    const getFile = (image, fileExtension) => {
        let newImageName, index;
        const blob = image.slice(0, image.size);
        if (fileExtension == "jpeg" || fileExtension == "jpg" || fileExtension == "png") {
            if (image?.name.indexOf("AM") != -1)
                index = image?.name.indexOf("AM");

            else if (image?.name.indexOf("PM") != -1)
                index = image?.name.indexOf("PM");

            if (index) {
                newImageName =
                    image?.name.slice(0, index - 1) + "_" + image?.name.slice(index);
            }

            else
                newImageName = image?.name;

        }
        else
            newImageName = image?.name;
        const file = new File([blob], newImageName);
        return { file, newImageName };
    };

    const removeFile = (e, index) => {
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure want to delete this file?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        handleDelete(e, index);
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const openAddUpdateItem = (e) => {
        setOpen(true);
    }

    const handleDelete = (e, index) => {
        let temp = [...soFiles]
        temp.splice(index, 1);
        setSOFiles(temp)
    };

    return (
        <>
            <LeftMenu />
            <div className="right-column">
                <Header />
                <div className="body-wrap">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a onClick={handleBack} >SO List </a>
                            </li>
                            <li className="breadcrumb-item">
                                <a> Add SO</a>
                            </li>
                        </ol>
                    </nav>
                    <div className="top-heading">
                        <div className="d-flex">
                            <a onClick={handleBack} className="back">
                                &#8249;
                            </a>
                            <h2> New Sales Order</h2>
                        </div>
                    </div>
                    <Formik initialValues={soDetails}
                        validationSchema={soSchema}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            handleSubmit('final');
                            // Clear the form after submission
                            resetForm();
                        }}>
                        {({ errors, touched, isValid, handleBlur }) => (
                            <>
                                <Form>
                                    <div className="user-wrap so-section">
                                        <div className="row row-gap-4">
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Customer Name<span className="star-color"> *</span></label>

                                                <Select
                                                    name="customerName"
                                                    value={soDetails.customerName}
                                                    onChange={(e) => changeDropdown(e, 'customerName')}
                                                    options={customerOption}
                                                    placeholder={soDetails.customerName == "" ? 'Search Customer' : customerOption?.find(x => x.value == soDetails.customerName)?.label}
                                                />

                                                {errors.customerName && touched.customerName ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.customerName}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Currency <span className="star-color"> *</span></label>
                                                <Select
                                                    name="currency"
                                                    value={soDetails.currency ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'currency')}
                                                    options={currencyOption}
                                                    placeholder={soDetails.currency == "" ? 'Select Currency' : currencyOption?.find(x => x.value == soDetails.currency)?.label}
                                                />
                                                {errors.currency && touched.currency ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.currency}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Place of supply <span className="star-color"> *</span></label>
                                                <Select
                                                    value={soDetails.placeOfSupply ?? ""}
                                                    name="placeOfSupply"
                                                    onChange={(e) => changeDropdown(e, 'placeOfSupply')}
                                                    options={stateOption}
                                                    placeholder={soDetails.placeOfSupply == "" ? 'Select State' : stateOption?.find(x => x.value == soDetails.placeOfSupply)?.label}
                                                />
                                                {errors.placeOfSupply && touched.placeOfSupply ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.placeOfSupply}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Branch<span className="star-color"> *</span></label>
                                                <Select
                                                    value={soDetails.branch ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'branch')}
                                                    name="branch"
                                                    options={branchOption}
                                                    placeholder={soDetails.branch == "" ? 'Select Branch' : branchOption?.find(x => x.value == soDetails.branch)?.label}
                                                />
                                                {errors.branch && touched.branch ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.branch}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Reference#</label>
                                                <input
                                                    type="text"
                                                    name="reference"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={soDetails.reference}
                                                />

                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Sales Order Date<span className="star-color"> *</span></label>
                                                <input
                                                    type="date"
                                                    name="salesOrderDate"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onClick={handleDateClick}
                                                    value={soDetails.salesOrderDate}
                                                />
                                                {errors.salesOrderDate && touched.salesOrderDate ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.salesOrderDate}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Expected Shipment Date</label>
                                                <input
                                                    type="date"
                                                    name="expectedShipDate"
                                                    className="form-control"
                                                    onClick={handleDateClick}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={soDetails.expectedShipDate}
                                                />

                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Payment Terms</label>

                                                <Select
                                                    value={soDetails.paymentTerms ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'paymentTerms')}
                                                    name="paymentTerms"
                                                    options={paymentOption}
                                                    placeholder={soDetails.paymentTerms == "" ? 'Select Payment Terms' : paymentOption?.find(x => x.value == soDetails.paymentTerms)?.label}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Delivery Method</label>

                                                <Select
                                                    value={soDetails.deliveryMethod ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'deliveryMethod')}
                                                    name="deliveryMethod"
                                                    options={deliveryOption}
                                                    placeholder={soDetails.deliveryMethod == "" ? 'Select Delivery Method' : deliveryOption?.find(x => x.value == soDetails.deliveryMethod)?.label}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Sales Person</label>

                                                <Select
                                                    value={soDetails.salesPerson ?? ""}
                                                    name="salesPerson"
                                                    onChange={(e) => changeDropdown(e, 'salesPerson')}
                                                    options={salesPersonOption}
                                                    placeholder={soDetails.salesPerson == "" ? 'Select Sales Person' : salesPersonOption?.find(x => x.value == soDetails.salesPerson)?.label}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">PDC Received</label>

                                                <Select
                                                    value={soDetails.pdcReceived ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'pdcReceived')}
                                                    name="pdcReceived"
                                                    options={pdcOption}
                                                    placeholder={soDetails.pdcReceived == "" ? 'Select' : pdcOption?.find(x => x.value == soDetails.pdcReceived)?.label}
                                                />

                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Approver<span className="star-color"> *</span></label>

                                                <Select
                                                    value={soDetails.approver ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'approver')}
                                                    name="approver"
                                                    options={approverOption}
                                                    placeholder={soDetails.approver == "" ? 'Select Approver' : approverOption?.find(x => x.value == soDetails.approver)?.label}
                                                />
                                                {errors.approver && touched.approver ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.approver}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Business Type</label>
                                                <input
                                                    type="text"
                                                    name="businessType"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={soDetails.businessType}
                                                />

                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Business Line<span className="star-color"> *</span></label>

                                                <Select
                                                    value={soDetails.businessLine ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'businessLine')}
                                                    name="businessLine"
                                                    options={businessLineOption}
                                                    placeholder={soDetails.businessLine == "" ? 'Select Business Line' : businessLineOption?.find(x => x.value == soDetails.businessLine)?.label}
                                                />
                                                {errors.businessLine && touched.businessLine ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.businessLine}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Product Type<span className="star-color"> *</span></label>

                                                <Select
                                                    value={soDetails.productType ?? ""}
                                                    onChange={(e) => changeDropdown(e, 'productType')}
                                                    name="productType"
                                                    options={productTypeOption}
                                                    placeholder={soDetails.productType == "" ? 'Select Product Type' : productTypeOption?.find(x => x.value == soDetails.productType)?.label}
                                                />
                                                {errors.productType && touched.productType ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.productType}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Buyer PO Date<span className="star-color"> *</span></label>
                                                <input
                                                    type="date"
                                                    onClick={handleDateClick}
                                                    name="buyerPODate"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={soDetails.buyerPODate}
                                                />
                                                {errors.buyerPODate && touched.buyerPODate ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.buyerPODate}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <label className="form-label">Expected Margin %<span className="star-color"> *</span></label>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    onKeyDown={preventNegativeValues}
                                                    name="expectedMargin"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={soDetails.expectedMargin}
                                                />
                                                {errors.expectedMargin && touched.expectedMargin ? (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.expectedMargin}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <ItemList
                                         openAddUpdateItem={openAddUpdateItem}
                                         itemRows={itemRows}
                                         setItemRows={setItemRows}
                                         setSODetails={setSODetails}
                                         soDetails={soDetails}
                                         setItemSearch={setItemSearch}
                                         taxPreferenceOptions={taxPreferenceOptions}
                                         categoryOption={categoryOption}
                                         handleBlur={handleBlur}
                                         itemSearch={itemSearch}
                                         itemOption={itemOption}
                                         createdItem={createdItem}
                                         />

                                        <br />
                                        <div className="row dv-bill-board">
                                            <div className="col-md-7" >
                                                <div className="dv-cstrnot-area">
                                                    <span className="dv-cstmr-nts">Customer Notes </span>
                                                    <textarea defaultValue={soDetails.customerNotes} name="customerNotes" onChange={handleChange} className="form-control txt-area" placeholder="Enter any notes to be displayed in your transaction"></textarea>
                                                </div>
                                                <div className="mt-3">
                                                    <span className="dv-cstmr-nts">Terms & Conditions</span>
                                                    <textarea className="form-control txt-area" rows="7" defaultValue={TERMS_CONDITIONS}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-5 " >
                                                <div className="row dv-bill">
                                                    <div className="col-md-4">
                                                        <p style={{ fontWeight: 'bold' }}>
                                                            Sub Total
                                                        </p>

                                                    </div>
                                                    <div className="col-md-3">

                                                    </div>
                                                    <div className="col-md-3">

                                                    </div>
                                                    <div className="col-md-2">
                                                        <p>{numberFormat(soDetails.subTotal)}</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <p>Discount
                                                        </p>
                                                    </div>
                                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            onKeyDown={preventNegativeValues}
                                                            name="discount"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={soDetails.discount}
                                                        />
                                                        <small className="sml-percent">%</small>
                                                    </div>
                                                    <div className="col-md-3">

                                                    </div>
                                                    <div className="col-md-2">
                                                        <p>{numberFormat((soDetails.subTotal / 100) * soDetails.discount)}</p>
                                                    </div>
                                                    <div className="col-md-4">

                                                        <p>Shipping charges
                                                        </p>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <p>
                                                            <input
                                                                type="number"
                                                                min={0}
                                                                onKeyDown={preventNegativeValues}
                                                                name="shippingCharges"
                                                                className="form-control"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={soDetails.shippingCharges}
                                                            />
                                                        </p>

                                                    </div>
                                                    <div className="col-md-3">

                                                    </div>
                                                    <div className="col-md-2">
                                                        <p>{numberFormat(soDetails.shippingCharges)}</p>
                                                    </div>
                                                    <div className="col-md-4">

                                                        <p className="dv-bill-tds" >
                                                            <input
                                                                style={{ marginTop: '7px' }}
                                                                type="radio"
                                                                min={0}
                                                                onKeyDown={preventNegativeValues}
                                                                name="taxType"
                                                                className="form-check-input"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value="TDS"
                                                                checked={soDetails.taxType == 'TDS' ? true : false}
                                                            />&nbsp; <span> TDS </span>
                                                            &nbsp;&nbsp;
                                                            <input
                                                                style={{ marginTop: '7px' }}
                                                                type="radio"
                                                                min={0}
                                                                onKeyDown={preventNegativeValues}
                                                                name="taxType"
                                                                className="form-check-input"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value="TCS"
                                                                checked={soDetails.taxType == 'TCS' ? true : false}
                                                            />&nbsp;
                                                            <span> TCS </span>
                                                        </p>

                                                    </div>
                                                    <div className="col-md-5" style={{ height: '30px' }}>
                                                        {soDetails.taxType == 'TDS' ? <Select
                                                            name="tax"
                                                            onChange={(e) => changeDropdown(e, 'tax')}
                                                            options={taxPercentOptions}
                                                            placeholder={soDetails.tax ? taxPercentOptions.find(x => x.value == soDetails.tax)?.label : 'Tax Type'}
                                                        /> :

                                                            <div style={{ display: 'flex' }}>
                                                                <input
                                                                    type="number"
                                                                    min={0}
                                                                    onKeyDown={preventNegativeValues}
                                                                    name="tax"
                                                                    className="form-control"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={soDetails.tax}
                                                                />
                                                                <small className="sml-percent">%</small>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-md-1">

                                                    </div>
                                                    <div className="col-md-2">
                                                        <p>{numberFormat((soDetails.subTotal / 100) * soDetails.tax)}</p>

                                                    </div>
                                                    <div className="col-md-4" style={{ marginTop: '5px' }}>

                                                        <p>Adjustments
                                                        </p>

                                                    </div>
                                                    <div className="col-md-3" style={{ marginTop: '5px' }}>
                                                        <p>
                                                            <input
                                                                type="number"
                                                                name="adjustment"
                                                                className="form-control"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={soDetails.adjustment}
                                                            />
                                                        </p>

                                                    </div>
                                                    <div className="col-md-3">

                                                    </div>
                                                    <div className="col-md-2" style={{ marginTop: '5px' }}>
                                                        <p>{numberFormat(soDetails.adjustment)}</p>
                                                    </div>
                                                    <hr />
                                                    <div className="col-md-4">
                                                        <p style={{ fontWeight: 'bold' }}>
                                                            Total(₹)
                                                        </p>
                                                    </div>
                                                    <div className="col-md-3">

                                                    </div>
                                                    <div className="col-md-3">

                                                    </div>
                                                    <div className="col-md-2">
                                                        <p>{numberFormat(soDetails.total)}</p>
                                                    </div>
                                                </div>

                                                <div className="mt-3">
                                                    <span className="dv-cstmr-nts">Attach File(s) to sales order </span>
                                                    {soFiles.length > 0 && <div style={{ marginTop: '5px' }} className="dv-file-lst row">
                                                        {soFiles.map(
                                                            (item, index) => item.isDeleted == false &&
                                                                <div className="col-md-4">
                                                                    <span className="spn-fle">
                                                                        Sheet {index + 1}
                                                                    </span>{" "}
                                                                    &nbsp;
                                                                    <img
                                                                        onClick={(e) => {
                                                                            removeFile(e, index);
                                                                        }}
                                                                        src={deleteImg}
                                                                        className="smll-dlte"
                                                                    />
                                                                </div>
                                                        )}
                                                    </div>}
                                                    <div className="position-relative custom-upload">

                                                        <div className="upload dv-so-file">
                                                            <div className="upload-img">
                                                                <img src={uploadImg} alt="upload" />
                                                            </div>
                                                            <div className="upload-detail" style={{ fontSize: "12px" }}>
                                                                <div>
                                                                    Drop your File(s)
                                                                </div>
                                                                <div>Max 10 files can be uploaded, 10MB each</div>
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            className="custom-file"
                                                            name="soFiles"
                                                            multiple="multiple"
                                                            accept="image/jpg, image/jpeg, .pdf, .xlsx, .xls, .docs, .csv, .doc"
                                                            onBlur={handleBlur}
                                                            onChange={(event) => {
                                                                handleFileSheetUpload(event, "tech");
                                                            }}
                                                        />
                                                        {errors.soFiles &&
                                                            touched.soFiles ? (
                                                            <div className="invalid-feedback d-block">
                                                                {errors.soFiles}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        {/* <div className="row dv-bill-board">
                                            <div className="col-md-7" >
                                                
                                            </div>
                                            <div className="col-md-5" >
                                                
                                            </div>
                                        </div> */}
                                        <br />
                                        <button className="btn ornge-brdr-btn" onClick={(e) => handleSubmit('draft')} type="button" >Save as Draft</button> &nbsp;
                                        <button className="btn ornge-btn" type="submit" >Save and Submit</button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                    {open && <AddUpdateItem open={open} setOpen={setOpen} manufacturerOptions={manufacturerOptions} setManufacturerOptions={setManufacturerOptions}
                        setCategoryOption={setCategoryOption} categoryOption={categoryOption} customerOption={customerOption} setCustomerOption={setCustomerOption} setCreatedItem={setCreatedItem} />
                    }
                </div>
            </div>
        </>
    );
};
