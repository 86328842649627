import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  setRfqStatus,
  getRfqStatusByRfqId,
  getRfqById,
} from "../../features/rfq/action";
import { Modal } from "react-bootstrap";
import { resetRfq } from "../../features/rfq/slice";
import Multiselect from "multiselect-react-dropdown";

export const PostUpdate = (props) => {
  const { rfqId , setShowPostModal , showPostModal, internalUsers} = props;
  const userData = JSON.parse(localStorage.getItem("authorization"));
  const userId = localStorage.getItem("userId");
  const loggedInUser = {id: userId, name: userData.user.name}
  const rfqResponse = useSelector((state) => state.rfq);
  const dispatch = useDispatch();
  const [removeAssignee, setRemoveAssignee] = useState([]);
  const [rfqStatusUpdate, setRfqStatusUpdate] = useState({
    rfqId: props.rfqId,
    status: "",
    notifyTo: [loggedInUser],
    subject: "",
    message: "",
    createdBy: userId,
  });
  const [selectedAssignees, setSelectedAssignees] = useState([{ id: userData.user.id, name: userData.user.name ? userData.user.name : userData.user.emailId }]);
  useEffect(() => {
    setRfqStatusUpdate((prev) => ({ ...prev, rfqId: props.rfqId }));
  }, [props.rfqId]);
 
  useEffect(() => {
    if (rfqResponse.actionName == "setRfqStatus" && rfqResponse.success) {
      dispatch(getRfqById(rfqId));
      dispatch(getRfqStatusByRfqId(rfqId));
      dispatch(resetRfq());
    }
  }, [rfqResponse]);

  const rfqStatusUpdateSchema = Yup.object().shape({
   
    status: Yup.string().required("Required"),
    subject: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
  });
  const handleChange = (e) => {
    setRfqStatusUpdate({
      ...rfqStatusUpdate,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = () => {
    {
      dispatch(setRfqStatus(rfqStatusUpdate));
      setShowPostModal(false)
    }
  };

  

  const getColorClass = (status) => {
    switch (status) {
      case "On Track":
        return "circle-icon-green";
      case "On Hold":
        return "circle-icon-yellow";
      case "Delayed":
        return "circle-icon-red";
      case "Critical":
        return "circle-icon-blue";
      case "Completed":
        return "circle-icon-orange";
      default:
        return "";
    }
  };

  const setStatus = (status) => {
    setRfqStatusUpdate({ ...rfqStatusUpdate, status });
  };

  const handleCloseModal = () => {
    setShowPostModal(false);
  };

  const handleSelectAssignee = (selectedList, selectedItem) => {
    // Handle selection changes
    setSelectedAssignees(selectedList);
    setRfqStatusUpdate({
      ...rfqStatusUpdate,
      notifyTo: selectedList,
    });
  };
  let rm = [];
  const handleRemoveAssignee = (selectedList, removedItem) => {
    const updatedRemovedAssignees = [...selectedAssignees];
    updatedRemovedAssignees.push(removedItem);
    setRemoveAssignee([...removeAssignee, removedItem]);
  }

  return (
    <>
    {showPostModal && <Modal show={showPostModal} className="custom-modal" centered   size="lg">
        <Modal.Header>
          <h2>
            {" "}
            <button type="button" className="btn" data-bs-dismiss="modal">
              &#8249;
            </button>
            Post an update
          </h2>

          <div className="dropdown custom-dropdown">
            <button
              className="btn dropdown-toggle custom-btn"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span
                className={`circle-icon ${getColorClass(
                  rfqStatusUpdate.status
                )}`}
              ></span>
              {rfqStatusUpdate.status ? rfqStatusUpdate.status : "Set Status"}
            </button>

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a
                  className="dropdown-item "
                  onClick={() => setStatus("On Track")}
                >
                  <span className="circle-icon circle-icon-green"></span>
                  On Track
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item "
                  onClick={() => setStatus("On Hold")}
                >
                  <span className="circle-icon circle-icon-yellow"></span>
                  On Hold
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item "
                  onClick={() => setStatus("Delayed")}
                >
                  <span className="circle-icon circle-icon-red"></span>
                  Delayed
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item "
                  onClick={() => setStatus("Critical")}
                >
                  <span className="circle-icon circle-icon-blue"></span>
                  Critical
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item "
                  onClick={() => setStatus("Completed")}
                >
                  <span className="circle-icon circle-icon-orange"></span>
                  Completed
                </a>
              </li>
            </ul>
          </div>
        </Modal.Header>
        <Formik
          initialValues={rfqStatusUpdate}
          validationSchema={rfqStatusUpdateSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ errors, touched, isValid, handleBlur }) => (
            <Form>
              <Modal.Body>
                <div className="row align-items-center mb-3">
                  <label className="form-label col-lg-2 col-md-3 col-sm-4">
                    Notify To :
                  </label>
                  <div className="col-lg-10 col-md-9 col-sm-8">
                  <Multiselect
                          options={internalUsers}
                          selectedValues={selectedAssignees}
                          onSelect={handleSelectAssignee}
                          onRemove={handleRemoveAssignee}
                          name="assignee"
                          displayValue="name"
                        />
                  </div>
                </div>
                <div className="row align-items-center mb-3">
                  <label className="form-label col-lg-2 col-md-3 col-sm-4">
                    Subject :
                  </label>
                  <div className="col-lg-10 col-md-9 col-sm-8">
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={rfqStatusUpdate.subject}
                    />
                    {errors.subject && touched.subject ? (
                      <div className="invalid-feedback d-block">
                        {errors.subject}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="modal-textarea">
                  <span>Hello,</span>
                  <Field
                    as="textarea"
                    name="message"
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                    onBlur={handleBlur}
                    value={rfqStatusUpdate.message}
                  />

                  <span>Thanks and Regards</span>
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn grey-brdr-btn"
                  onClick={handleCloseModal}
                >
                  Discard
                </button>
                <button type="submit" className="btn ornge-brdr-btn">
                  Send
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
}
     
    </>
  );
};
