import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import uploadImg from "../../assets/images/upload-image.svg";
import deleteImg from "../../assets/images/deleteicon.png";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import Select from "react-select";
import { handleDateClick } from "../../utils/datePickerHelper";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateSample } from "../../features/sample/action";
import { notify } from "../../features/notification/notify";
import { resetSample } from "../../features/sample/slice";
import { useNavigate } from "react-router-dom";
import { getUserByRoleName } from "../../features/userDetail/action";
import {
  getCategory,
  getSeason,
  getSubCategoryByCategoryId,
} from "../../features/common/action";
import { resetCommon } from "../../features/common/slice";
import { confirmAlert } from "react-confirm-alert";

export const AddSample = () => {
  const [costSheet, setCostSheet] = useState([]);
  const [imgList, setImgList] = useState([]);
  const [specSheet, setSpecSheet] = useState([]);
  const [techPack, setTechPack] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const sampleResponse = useSelector((state) => state.sample);
  const userResponse = useSelector((state) => state.userDetail);
  const commonResponse = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("userId");
  const [sample, setSample] = useState({
    sampleId: "",
    sampleName: "",
    samplingCostFOB: null,
    samplingCostCMT: null,
    samplingFactory: "",
    samplingMill: "",
    tat: null,
    season: "",
    fit: "",
    trimsAndFinishes: "",
    MOQForProduction: null,
    size: "",
    color: "",
    developmentDate: null,
    category: "",
    buyer: "",
    subCategory: "",
    fabricContents: "",
    styleNumber: "",
    styleName: "",
    product: "",
    createdBy: loggedInUser,
  });

  useEffect(() => {
    if (
      sampleResponse.actionName == "addUpdateSample" &&
      sampleResponse.success
    ) {
      //this if block will be changed in future as this condition is hardcoded
      if (sampleResponse.message == "Exist") {
        notify("Sample name already exist!", "info");
      } else {
        notify(sampleResponse.message, "success");
        navigate("/sample-list");
      }
      dispatch(resetSample());
    }
  }, [sampleResponse]);

  useEffect(() => {
    dispatch(getUserByRoleName("Supplier,Buyer"));
    dispatch(getSeason());
    dispatch(getCategory());
  }, []);

  useEffect(() => {
    if (userResponse.actionName === "userByRoleName") {
      setFactoryList(
        userResponse.roleDetails.filter((x) => x.Roles[0].role == "Supplier")
      );
      setBuyerList(
        userResponse.roleDetails.filter((x) => x.Roles[0].role == "Buyer")
      );
    }
  }, [userResponse]);

  useEffect(() => {
    if (commonResponse.actionName === "getCategory" && commonResponse.success) {
      setCategoryList(commonResponse.category);
      dispatch(resetCommon());
    }
    if (commonResponse.actionName === "getSeason" && commonResponse.success) {
      setSeasons(commonResponse.season);
      dispatch(resetCommon());
    }
    if (
      commonResponse.actionName === "getSubCategory" &&
      commonResponse.success
    ) {
      setSubCat(commonResponse.subCategory);
      dispatch(resetCommon());
    }
  }, [commonResponse]);

  const sampleSchema = Yup.object().shape({
    sampleName: Yup.string().required("Required"),
    samplingCostFOB: Yup.number()
      .nullable()
      .moreThan(-1, "Value should not be negative"),
    samplingCostCMT: Yup.number()
      .nullable()
      .moreThan(-1, "Value should not be negative"),
    tat: Yup.number()
      .nullable()
      .moreThan(0, "Value should be greater than Zero"),
    MOQForProduction: Yup.number()
      .nullable()
      .moreThan(0, "Value should be greater than Zero"),
    season: Yup.string().required("Required"),
    fit: Yup.string().required("Required"),
    trimsAndFinishes: Yup.string().required("Required"),
    size: Yup.string().required("Required"),
    color: Yup.string().required("Required"),
    developmentDate: Yup.date().nullable().required("Required"),
    fabricContents: Yup.string().required("Required"),
    styleNumber: Yup.string().required("Required"),
  });

  const handleChange = (e) => {
    setSample({
      ...sample,
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "category") {
      dispatch(getSubCategoryByCategoryId(e.target.value));
    }
  };
  const handleSubmit = (e) => {
    if (
      imgList.length == 0 ||
      (costSheet.length == 0 && specSheet.length == 0 && techPack.length == 0)
    ) {
      notify("Please add an Image and a file", "info");
    } else {
      let formData = new FormData();
      const fileNames = [];
      imgList.map((fileSet, index) => {
        if (index === 0) {
          formData.append("upload_file", fileSet.file);
          fileNames.push({
            fileType: "image",
            fileName: fileSet.fileName,
            isFront: true,
          });
        } else {
          formData.append("upload_file", fileSet.file);
          fileNames.push({
            fileType: "image",
            fileName: fileSet.fileName,
            isFront: false,
          });
        }
      });
      costSheet.map((fileSet) => {
        formData.append("upload_file", fileSet.file);
        fileNames.push({ fileType: "cost sheet", fileName: fileSet.fileName });
      });
      specSheet.map((fileSet) => {
        formData.append("upload_file", fileSet.file);
        fileNames.push({ fileType: "spec sheet", fileName: fileSet.fileName });
      });
      techPack.map((fileSet) => {
        formData.append("upload_file", fileSet.file);
        fileNames.push({ fileType: "tech pack", fileName: fileSet.fileName });
      });
      formData.append("content", JSON.stringify(sample));
      formData.append("fileList", JSON.stringify(fileNames));
      dispatch(addUpdateSample(formData));
    }
  };

  const getFile = (image, fileExtension) => {
    let originalFileName, index;
    const blob = image.slice(0, image.size);
    if (image.name.indexOf("AM") != -1)
      index = image?.name.indexOf("AM");

    else if (image?.name.indexOf("PM") != -1)
      index = image?.name.indexOf("PM");
    if (index) {
      originalFileName =
        image?.name.slice(0, index - 1) + "_" + image?.name.slice(index);
    }
    else
      originalFileName = image?.name;
    const lastDotIndex = originalFileName.lastIndexOf(".");
    const filenameWithoutExtension = originalFileName.substring(
      0,
      lastDotIndex
    );
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .replace(/:/g, "")
      .replace(/\..+/, "")
      .replace(/-/g, "");
    const newImageName =
      filenameWithoutExtension + "_" + formattedDate + "." + fileExtension;
    const file = new File([blob], newImageName);
    return { file, newImageName };
  };

  const handleImgUpload = (e) => {
    if (e.target.files.length > 5) {
      notify("Only 5 files can be added at a time", "error")
      e.target.value = "";
      return;
    }
    Array.from(e.target.files).forEach(item => {
      const fileExtension = item?.name
        .split(".")
        .pop()
        .toLowerCase();
      var acceptedFormats = ["png", "jpeg", "jpg", "webp"];
      if (!acceptedFormats.includes(fileExtension)) {
        notify("file not supported", "error");
        return;
      }
      const { file, newImageName } = getFile(item, fileExtension);
      setImgList((imgList) => [
        ...imgList,
        { file: file, fileName: newImageName },
      ]);
    });
  };

  const handleFileSheetUpload = (e, type) => {
    if (e.target.files.length > 5) {
      notify("Only 5 files can be added at a time", "error");
      e.target.value = "";
      return;
    }
    Array.from(e.target.files).forEach(item => {
      const fileExtension = item?.name
        .split(".")
        .pop()
        .toLowerCase();
      var acceptedFormats = ["pdf", "xlsx", "docx", "csv", "xls", "doc"];
      if (!acceptedFormats.includes(fileExtension)) {
        notify("File type not supported", "error");
        e.target.value = "";
        return;
      }
      const { file, newImageName } = getFile(item, fileExtension);
      if (type === "cost" && costSheet.length != 5) {
        setCostSheet((costSheet) => [
          ...costSheet,
          { file: file, fileName: newImageName },
        ]);
      } else if (type === "spec" && specSheet.length != 5) {
        setSpecSheet((specSheet) => [
          ...specSheet,
          { file: file, fileName: newImageName },
        ]);
      } else if (type === "techpack" && techPack.length != 5) {
        setTechPack((techPack) => [
          ...techPack,
          { file: file, fileName: newImageName },
        ]);
      } else {
        notify("Only 5 files can be added at a time", "info");
      }
    });
  };
  
  const removeImg = (e, index) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure want to delete this file?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const temp = [...imgList];
            temp.splice(index, 1);
            setImgList(temp);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleBack = () => {
    navigate("/sample-list");
  };

  const removeFile = (e, index, type) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure want to delete this file?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            handleDelete(e, index, type);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleDelete = (e, index, type) => {
    if (type === "cost") {
      const cost = [...costSheet];
      cost.splice(index, 1);
      setCostSheet(cost);
    } else if (type == "spec") {
      const spec = [...specSheet];
      spec.splice(index, 1);
      setSpecSheet(spec);
    } else if (type == "tech") {
      const temp = [...techPack];
      temp.splice(index, 1);
      setTechPack(temp);
    }
  };
  console.log(sample);
  const preventNegativeValues = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const options = factoryList.map((item) => ({
    value: item.id,
    label: item.name ? item.name : item.emailId,
  }));

  // Find the selected option
  const selectedOptionsamplingFactory = options.find(
    (option) => option.value === sample.samplingFactory
  );

  // Handle change for react-select
  const handleSelectChangeSamplingFactory = (selectedOptionsamplingFactory) => {
    handleChange({
      target: {
        name: "samplingFactory",
        value: selectedOptionsamplingFactory
          ? selectedOptionsamplingFactory.value
          : "",
      },
    });
  };

  const selectedOptionsamplingMill = options.find(
    (option) => option.value === sample.samplingMill
  );

  // Handle change for react-select
  const handleSelectChangeSamplingMill = (selectedOptionsamplingMill) => {
    handleChange({
      target: {
        name: "samplingMill",
        value: selectedOptionsamplingMill
          ? selectedOptionsamplingMill.value
          : "",
      },
    });
  };

  const seasonOptions = seasons.map((item) => ({
    value: item.id,
    label: item.seasonName,
  }));

  // Handle change for react-select
  const handleSelectChangeSeason = (seasonOptions) => {
    handleChange({
      target: {
        name: "season",
        value: seasonOptions ? seasonOptions.value : "",
      },
    });
  };

  const categoryOptions = categoryList.map((item) => ({
    value: item.id,
    label: item.categoryName,
  }));

  // Find the selected option
  const selectedOptionCategory = categoryOptions.find(
    (categoryOptions) => categoryOptions.value === sample.category
  );

  // Handle change for react-select
  const handleSelectChangeCategory = (selectedOptionCategory) => {
    handleChange({
      target: {
        name: "category",
        value: selectedOptionCategory ? selectedOptionCategory.value : "",
      },
    });
  };

  const buyerOptions = buyerList.map((item) => ({
    value: item.id,
    label: item.name ? item.name : item.emailId,
  }));

  // Find the selected option
  const selectedOptionBuyer = buyerOptions.find(
    (buyerOptions) => buyerOptions.value === sample.buyer
  );

  // Handle change for react-select
  const handleSelectChangeBuyer = (selectedOptionBuyer) => {
    handleChange({
      target: {
        name: "buyer",
        value: selectedOptionBuyer ? selectedOptionBuyer.value : "",
      },
    });
  };

  const subCategoryOptions = subCat.map((item) => ({
    value: item.id,
    label: item.subCategoryName,
  }));

  // Handle change for react-select
  const handleSelectChangeSubCategory = (subCategoryOptions) => {
    handleChange({
      target: {
        name: "subCategory",
        value: subCategoryOptions ? subCategoryOptions.value : "",
      },
    });
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <LeftMenu />

      <div className="right-column">
        <Header />
        <div className="body-wrap">
          <Formik
            initialValues={sample}
            validationSchema={sampleSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, isValid, handleBlur }) => (
              <>
                <Form>
                  <div className="top-heading">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/sample-list" >Sample List </a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#"> Add Sample</a>
                        </li>
                      </ol>
                    </nav>
                    <div>
                      <button
                        type="button"
                        className="btn grey-brdr-btn mr-20"
                        onClick={handleBack}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn ornge-brdr-btn">
                        Next
                      </button>
                    </div>
                  </div>
                  <div className="top-heading">
                    <div className="d-flex">
                      <a className="back" onClick={handleBack}>
                        &#8249;
                      </a>
                      <h2>Add Sample</h2>
                    </div>
                  </div>
                  <div className="add-user-wrap">
                    <div className="mb-3">
                      <label className="form-label">
                        <b>Sample Details</b>
                        <span className="required-text"></span>
                      </label>
                    </div>

                    <div className="row">
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Sample Name
                                <span className="star-color"> *</span>
                              </label>
                              <input
                                type="text"
                                name="sampleName"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.sampleName && touched.sampleName ? (
                                <div className="invalid-feedback d-block">
                                  {errors.sampleName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Sampling Cost FOB
                              </label>
                              <input
                                type="number"
                                name="samplingCostFOB"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={preventNegativeValues}
                              />
                              {errors.samplingCostFOB &&
                                touched.samplingCostFOB ? (
                                <div className="invalid-feedback d-block">
                                  {errors.samplingCostFOB}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Sampling Cost CMT<span></span>
                              </label>
                              <input
                                type="number"
                                name="samplingCostCMT"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={preventNegativeValues}
                              />
                              {errors.samplingCostCMT &&
                                touched.samplingCostCMT ? (
                                <div className="invalid-feedback d-block">
                                  {errors.samplingCostCMT}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Sampling Factory
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="samplingFactory"
                                value={selectedOptionsamplingFactory || ""}
                                onChange={handleSelectChangeSamplingFactory}
                                options={options}
                                placeholder="-- Select Sampling Factory --"
                                isClearable
                              />
                              {errors.samplingFactory &&
                                touched.samplingFactory ? (
                                <div className="invalid-feedback d-block">
                                  {errors.samplingFactory}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Sampling Mill
                              </label>
                              <Select
                                classNamePrefix="form-select"
                                name="samplingMill"
                                value={selectedOptionsamplingMill || ""}
                                onChange={handleSelectChangeSamplingMill}
                                options={options}
                                placeholder="-- Select Sampling Mill --"
                                isClearable
                              />
                              {errors.samplingMill && touched.samplingMill ? (
                                <div className="invalid-feedback d-block">
                                  {errors.samplingMill}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">TAT</label>
                              <input
                                type="number"
                                name="tat"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={preventNegativeValues}
                              />
                              {errors.tat && touched.tat ? (
                                <div className="invalid-feedback d-block">
                                  {errors.tat}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Season<span className="star-color"> *</span>
                              </label>
                              <Select
                                name="season"
                                classNamePrefix="form-control"
                                onChange={handleSelectChangeSeason}
                                onBlur={handleBlur}
                                options={seasonOptions}
                                placeholder="-- Select Season --"
                                isClearable
                              />
                              {errors.season && touched.season ? (
                                <div className="invalid-feedback d-block">
                                  {errors.season}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Fit<span className="star-color"> *</span>
                              </label>
                              <input
                                type="text"
                                name="fit"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.fit && touched.fit ? (
                                <div className="invalid-feedback d-block">
                                  {errors.fit}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Trims & Finishes
                                <span className="star-color"> *</span>
                              </label>
                              <input
                                type="text"
                                name="trimsAndFinishes"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.trimsAndFinishes &&
                                touched.trimsAndFinishes ? (
                                <div className="invalid-feedback d-block">
                                  {errors.trimsAndFinishes}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                MOQ for Production
                              </label>
                              <input
                                type="number"
                                name="MOQForProduction"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={preventNegativeValues}
                              />
                              {errors.MOQForProduction &&
                                touched.MOQForProduction ? (
                                <div className="invalid-feedback d-block">
                                  {errors.MOQForProduction}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Size<span className="star-color"> *</span>
                              </label>
                              <input
                                type="text"
                                name="size"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.size && touched.size ? (
                                <div className="invalid-feedback d-block">
                                  {errors.size}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Color <span className="star-color"> *</span>
                              </label>
                              <input
                                type="text"
                                name="color"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.color && touched.color ? (
                                <div className="invalid-feedback d-block">
                                  {errors.color}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Development Date
                                <span className="star-color"> *</span>
                              </label>
                              <input
                                type="date"
                                name="developmentDate"
                                className="form-control"
                                onClick={handleDateClick}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.developmentDate &&
                                touched.developmentDate ? (
                                <div className="invalid-feedback d-block">
                                  {errors.developmentDate}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Category</label>
                              <Select
                                name="category"
                                classNamePrefix="form-control"
                                onChange={handleSelectChangeCategory}
                                onBlur={handleBlur}
                                options={categoryOptions}
                                placeholder="-- Select Category --"
                                isClearable
                              />
                              {errors.category && touched.category ? (
                                <div className="invalid-feedback d-block">
                                  {errors.category}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Buyer</label>
                              <Select
                                classNamePrefix="form-select"
                                name="buyer"
                                value={selectedOptionBuyer || ""}
                                onChange={handleSelectChangeBuyer}
                                options={buyerOptions}
                                placeholder="-- Select Buyer --"
                                isClearable
                              />
                              {errors.buyer && touched.buyer ? (
                                <div className="invalid-feedback d-block">
                                  {errors.buyer}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Sub-Category</label>
                              <Select
                                name="subCategory"
                                classNamePrefix="form-control"
                                onChange={handleSelectChangeSubCategory}
                                onBlur={handleBlur}
                                options={subCategoryOptions}
                                placeholder="-- Select Subcategory --"
                                isClearable
                              />
                              {errors.subCategory && touched.subCategory ? (
                                <div className="invalid-feedback d-block">
                                  {errors.subCategory}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Fabric Content
                                <span className="star-color"> *</span>
                              </label>
                              <input
                                type="text"
                                name="fabricContents"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.fabricContents &&
                                touched.fabricContents ? (
                                <div className="invalid-feedback d-block">
                                  {errors.fabricContents}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Style Number
                                <span className="star-color"> *</span>
                              </label>
                              <input
                                type="text"
                                name="styleNumber"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.styleNumber && touched.styleNumber ? (
                                <div className="invalid-feedback d-block">
                                  {errors.styleNumber}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Style Name</label>
                              <input
                                type="text"
                                name="styleName"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.styleName && touched.styleName ? (
                                <div className="invalid-feedback d-block">
                                  {errors.styleName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">Product</label>
                              <input
                                type="text"
                                name="product"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.product && touched.product ? (
                                <div className="invalid-feedback d-block">
                                  {errors.product}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="finance-upload-doc-wrap ml">
                          <div className="finance-upload-doc-inner ">
                            <label className="form-label">Cost sheet</label>
                            <div className="dv-file-lst row">
                              {costSheet.length > 0 &&
                                costSheet.map((item, index) => (
                                  <div className="col-md-4">
                                    <span className="spn-fle">
                                      Sheet {index + 1}
                                    </span>{" "}
                                    <img
                                      onClick={(e) => {
                                        removeFile(e, index, "cost");
                                      }}
                                      src={deleteImg}
                                      className="smll-dlte"
                                    />
                                  </div>
                                ))}
                            </div>
                            <div className="position-relative ">
                              <div className="upload uploadimg-outer">
                                <img
                                  src={uploadImg}
                                  alt="upload"
                                  className=" upload uploadimg"
                                />
                                <div className="upload-detail">
                                  <div>
                                    Drop file here or{" "}
                                    <span className="blue-txt">Browse</span>
                                  </div>
                                  <div>Max file size: 100Mb</div>
                                  <div>
                                    File supported: pdf, xlsx, xls, csv, docx and doc
                                  </div>
                                </div>
                              </div>
                              <input
                                id="fpCostSheet"
                                type="file"
                                className="custom-file"
                                multiple="multiple"
                                accept=".pdf, .xlsx, .xls, .csv, .docx, .doc"
                                onChange={(event) => {
                                  handleFileSheetUpload(event, "cost");
                                }}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="finance-upload-doc-wrap ml">
                          <div className="finance-upload-doc-inner ">
                            <label className="form-label">Images</label>
                            <div className="dv-file-lst row">
                              {imgList.length > 0 &&
                                imgList.map((item, index) => (
                                  <div className="col-md-4">
                                    <span className="spn-fle">
                                      Image {index + 1}
                                    </span>{" "}
                                    <img
                                      onClick={(e) => {
                                        removeImg(e, index);
                                      }}
                                      src={deleteImg}
                                      className="smll-dlte"
                                    />
                                  </div>
                                ))}
                            </div>
                            <div className="position-relative ">
                              <div className="upload uploadimg-outer">
                                <img
                                  src={uploadImg}
                                  alt="upload"
                                  className=" upload uploadimg"
                                />
                                <div className="upload-detail">
                                  <div>
                                    Drop Image here or{" "}
                                    <span className="blue-txt">Browse</span>
                                  </div>
                                  <div>Max file size: 5MB</div>
                                  <div>
                                    File supported: png, jpeg, jpg, webp
                                  </div>
                                </div>
                              </div>
                              <input
                                id="fpImages"
                                type="file"
                                multiple="multiple"
                                className="custom-file"
                                accept="image/jpg, image/jpeg, image/png, .webp"
                                onChange={(event) => {
                                  handleImgUpload(event);
                                }}
                                onBlur={handleBlur}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="finance-upload-doc-wrap ml">
                          <div className="finance-upload-doc-inner ">
                            <label className="form-label">Spec sheet</label>
                            <div className="dv-file-lst row">
                              {specSheet.length > 0 &&
                                specSheet.map((item, index) => (
                                  <div className="col-md-4">
                                    <span className="spn-fle">
                                      Sheet {index + 1}
                                    </span>{" "}
                                    <img
                                      onClick={(e) => {
                                        removeFile(e, index, "spec");
                                      }}
                                      src={deleteImg}
                                      className="smll-dlte"
                                    />
                                  </div>
                                ))}
                            </div>
                            <div className="position-relative ">
                              <div className="upload uploadimg-outer">
                                <img
                                  src={uploadImg}
                                  alt="upload"
                                  className=" upload uploadimg"
                                />
                                <div className="upload-detail">
                                  <div>
                                    Drop file here or{" "}
                                    <span className="blue-txt">Browse</span>
                                  </div>
                                  <div>Max file size: 100Mb;</div>
                                  <div>
                                    File supported: pdf, xlsx, xls, csv, docx and doc
                                  </div>
                                </div>
                              </div>
                              <input
                                id="fpSpecSheet"
                                type="file"
                                className="custom-file"
                                multiple="multiple"
                                accept=".pdf, .xlsx, .xls, .csv, .docx, .doc"
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  handleFileSheetUpload(event, "spec");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="finance-upload-doc-wrap ml">
                          <div className="finance-upload-doc-inner ">
                            <label className="form-label">Tech pack</label>
                            <div className="dv-file-lst row">
                              {techPack.length > 0 &&
                                techPack.map((item, index) => (
                                  <div className="col-md-4">
                                    <span className="spn-fle">
                                      Sheet {index + 1}
                                    </span>{" "}
                                    <img
                                      onClick={(e) => {
                                        removeFile(e, index, "tech");
                                      }}
                                      src={deleteImg}
                                      className="smll-dlte"
                                    />
                                  </div>
                                ))}
                            </div>
                            <div className="position-relative custom-upload">
                              <div className="upload uploadimg-outer">
                                <img
                                  src={uploadImg}
                                  alt="upload"
                                  className=" upload uploadimg"
                                />
                                <div className="upload-detail">
                                  <div>
                                    Drop file here or{" "}
                                    <span className="blue-txt">Browse</span>
                                  </div>
                                  <div>Max file size: 100Mb</div>
                                  <div>
                                    File supported: pdf, xlsx, xls, csv, docx, and doc
                                  </div>
                                </div>
                              </div>
                              <input
                                id="fpTechPacks"
                                type="file"
                                className="custom-file"
                                multiple="multiple"
                                accept=".pdf, .xlsx, .xls, .csv, .docx, .doc"
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  handleFileSheetUpload(event, "techpack");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
