import { createSlice } from "@reduxjs/toolkit";
import { addUpdatePo, deletePO, getAllPOs, acceptAllPoMilestonesDates,  getAllTemplates, addTemplateToPo, getAcceptedFactory, getMilestoneByTempId, addFactoryToPo, getPoByIdAndRole, addDatePoFactoryMilestones,releasePo, setPoFactoryEditable, addNewDates, acceptOrRejectPoDates, addActualDates, getActualDates,poTemplateBulkUpload } from "./action";

export const po = createSlice({
  name: "po",
  initialState: {
    loading: false,
    error: null,
    success: false,
    message: null,
    actionName: null,
    poList: null,
    templateList: null,
    factories: null,
    milestone: null,
    poData : null
  },

  reducers: {
    resetPo: (state, action) => {
      state.success = false
      state.actionName = null
      state.message = null
    },
  },

  extraReducers: {
    [getAllPOs.pending]: (state) => {
      state.loading = true;
    },
    [getAllPOs.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.poList = action.payload.pos;
      state.actionName = "getAllPOs";
    },
    [getAllPOs.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [getAllTemplates.pending]: (state) => {
      state.loading = true;
    },
    [getAllTemplates.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.templateList = action.payload.templates;
      state.actionName = "getAllTemplates";
    },
    [getAllTemplates.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [deletePO.pending]: (state) => {
      state.loading = true;
    },
    [deletePO.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "deletePO";
    },
    [deletePO.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [addUpdatePo.pending]: (state) => {
      state.loading = true;
    },
    [addUpdatePo.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message=action.payload;
      state.actionName = "addUpdatePo";
    },
    [addUpdatePo.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [addTemplateToPo.pending]: (state) => {
      state.loading = true;
    },
    [addTemplateToPo.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "addTemplateToPo";
    },
    [addTemplateToPo.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [getAcceptedFactory.pending]: (state) => {
      state.loading = true;
    },
    [getAcceptedFactory.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.factories = action.payload.factories
      state.actionName = "getAcceptedFactory";
    },
    [getAcceptedFactory.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [getMilestoneByTempId.pending]: (state) => {
      state.loading = true;
    },
    [getMilestoneByTempId.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.milestone = action.payload.milestone
      state.actionName = "getMilestoneByTempId";
    },
    [getMilestoneByTempId.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [addFactoryToPo.pending]: (state) => {
      state.loading = true;
    },
    [addFactoryToPo.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "addFactoryToPo";
    },
    [addFactoryToPo.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [getPoByIdAndRole.pending]: (state) => {
      state.loading = true;
    },
    [getPoByIdAndRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.poData = action.payload.po;
      state.actionName = "getPoByIdAndRole";
    },
    [getPoByIdAndRole.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [addDatePoFactoryMilestones.pending]: (state) => {
      state.loading = true;
    },
    [addDatePoFactoryMilestones.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "addDatePoFactoryMilestones";
    },
    [addDatePoFactoryMilestones.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [releasePo.pending]: (state) => {
      state.loading = true;
    },
    [releasePo.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "releasePo";
    },
    [releasePo.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [setPoFactoryEditable.pending]: (state) => {
      state.loading = true;
    },
    [setPoFactoryEditable.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "setPoFactoryEditable";
    },
    [setPoFactoryEditable.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [addNewDates.pending]: (state) => {
      state.loading = true;
    },
    [addNewDates.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.actionName = "addNewDates";
    },
    [addNewDates.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [acceptOrRejectPoDates.pending]: (state) => {
      state.loading = true;
    },
    [acceptOrRejectPoDates.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "acceptOrRejectPoDates";
    },
    [acceptOrRejectPoDates.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [acceptAllPoMilestonesDates.pending]: (state) => {
      state.loading = true;
    },
    [acceptAllPoMilestonesDates.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "acceptAllPoMilestonesDates";
    },
    [acceptAllPoMilestonesDates.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [addActualDates.pending]: (state) => {
      state.loading = true;
    },
    [addActualDates.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "addActualDates";
    },
    [addActualDates.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
    },
    [poTemplateBulkUpload.pending]: (state) => {
      state.loading = true;
    },
    [poTemplateBulkUpload.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.message = action.payload.message;
      state.actionName = "poTemplateBulkUpload";
    },
    [poTemplateBulkUpload.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    }

  },
});

export default po.reducer;

export const { resetPo } = po.actions;
