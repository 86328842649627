import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import LoggedIn from "./loggedin";
import { Login } from "../views/login/login";
import { UploadNewTNA } from "../views/uploadNewTNA";
import LoginOtp from "../views/login/loginOtp";
import Dashboard from "../views/admin/dashboard";
import { LoginPin } from "../views/login/loginPin";
import { OTPLogin } from "../views/login/otpLogin";
import { LoginSetPin } from "../views/login/loginSetPin";
import { LoginResetPin } from "../views/login/loginResetPin";
import { NotFound404 } from "../views/layouts/notFound404";
import { SampleListing } from "../views/sample/sampleList";
import { AddSupplier } from "../views/userManagement/addSupplier";
import { AddSample } from "../views/sample/addsample";
import { AddBulkRFQ } from "../views/rfqDetails/addbulkRfq";
import { PreviewBulkRfq } from "../views/rfqDetails/previewBulkRfq";
import { Signup } from "../views/signup/signup";
import { SampleDetail } from "../views/sample/sampleDetail";
import { AddBuyer } from "../views/userManagement/addBuyer";
import AdminAuth from "./adminAuth";
import { AddUpdateRFQ } from "../views/rfqDetails/addUpdateRfq";
import { RfqList } from "../views/rfqDetails/rfqList";
import { EditSample } from "../views/sample/editSample";
import { EditImage } from "../views/sample/editImage";
import { RfqInfo } from "../views/rfqDetails/rfqInfo";
import { RFQSampleList } from "../views/rfqDetails/rfqSampleList";
// import Chat from "../views/chat/chat";
import socketIO from "socket.io-client"
import { UserDashboard } from "../views/userManagement/userDashboard";
import { AddUser } from "../views/userManagement/addUser";
import { AddBulkUser } from "../views/userManagement/addUserBulkUpload";
import { AddBulkSupplier } from "../views/userManagement/addSupplierBulkUpload";
// import ChatPage from "../views/chat/ChatPage";
import { AddUpdateCategory } from "../views/products/addUpdateCategory";
import { AllCategories } from "../views/products/allCategories";
import { CreateTemplateTNA } from "../views/templateTNA/createTemplateTNA";
import { CreateMilestone } from "../views/milestone/createMilestone";
import { AllTnaTemplate } from "../views/templateTNA/allTnaTemplate";
import { AllMilestones } from "../views/milestone/allMilestones";
import { POList } from "../views/po/poList";
import { AddBulkTemplate } from "../views/templateTNA/addBulkTemplate";
import { PreviewBulkTemplate } from "../views/templateTNA/previewBulkTemplate";
import { PoBulkUploadPreview } from "../views/po/poBulkUploadPreview";
import { POInfo } from "../views/po/poDetails/poInfo";
import { CreateSO } from "../views/soPO/createSO";
import { AddUpdateItem } from "../views/soPO/addUpdateItem";
import { SOList } from "../views/soPO/soList";
import { CreatePO } from "../views/soPO/createPO";
const socket = socketIO.connect(process.env.REACT_APP_API_URL)

const MyRoutes = (props) => {

  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/upload-TNA" element={<UploadNewTNA />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/login-otp" element={<LoginOtp />} />
        <Route exact path="/enter-Otp" element={<OTPLogin />} />
        <Route exact path="/login-pin" element={<LoginPin />} />
        <Route exact path="/login-setpin" element={<LoginSetPin />} />
        <Route exact path="/login-resetpin" element={<AdminAuth><LoginResetPin /></AdminAuth>} />
        <Route exact path="/add-update-rfq" element={<AddUpdateRFQ />} />
        <Route exact path="/rfq-list" element={<AdminAuth><RfqList /></AdminAuth>} />
        <Route exact path="/addsample" element={<AdminAuth><AddSample /></AdminAuth>} />
        <Route exact path="/sample-list" element={<AdminAuth><SampleListing /></AdminAuth>} />
        <Route exact path="/addbulkRfq" element={<AdminAuth><AddBulkRFQ /></AdminAuth>} />
        <Route exact path="/previewBulkRfq" element={<AdminAuth><PreviewBulkRfq /></AdminAuth>} />
        <Route exact path="/sample-detail" element={<AdminAuth><SampleDetail /></AdminAuth>} />
        <Route exact path="/edit-image" element={<AdminAuth><EditImage /></AdminAuth>} />
        <Route exact path="/rfq-info" element={<AdminAuth><RfqInfo /></AdminAuth>} />
        <Route exact path="/rfq-sample-list" element={<AdminAuth><RFQSampleList /></AdminAuth>} />

        <Route exact path="/add-user" element={<AddUser />} />
        <Route exact path="/user-dashboard" element={<AdminAuth><UserDashboard /></AdminAuth>} />
        <Route exact path="/add-buyer" element={<AdminAuth><AddBuyer /></AdminAuth>} />
        <Route exact path="/add-supplier" element={<AdminAuth><AddSupplier /></AdminAuth>} />
        <Route exact path="/edit-sample" element={<AdminAuth><EditSample /></AdminAuth>} />
        <Route exact path="/404" element={<NotFound404 />} />
        <Route exact path="/category/add" element={<AdminAuth><AddUpdateCategory /></AdminAuth>} />
        <Route exact path="/all-categories" element={<AdminAuth><AllCategories /></AdminAuth>} />
        <Route exact path="/add-bulk-user" element={<AdminAuth><AddBulkUser /></AdminAuth>} />
        <Route exact path="/add-bulk-supplier" element={<AdminAuth><AddBulkSupplier /></AdminAuth>} />
        {/* <Route path="/chat-login" element={<Chat socket={socket}/>}></Route> */}
        {/* <Route path="/chat" element={<ChatPage socket={socket}/>}></Route> */}
        {/* admin routes  */}

        <Route path="/create-tna" element={<CreateTemplateTNA />}></Route>
        <Route path="/create-milestone" element={<CreateMilestone />}></Route>
        <Route path="/template-list" element={<AllTnaTemplate />}></Route>
        <Route path="/milestone-list" element={<AllMilestones />}></Route>
        <Route path="/po-list" element={<POList />}></Route>
        <Route path="/po-info" element={<POInfo />}></Route>
        <Route path="/previewBulkpo" element={<PoBulkUploadPreview />}></Route>
        <Route path="/add-bulk-template" element={<AddBulkTemplate />}></Route>
        <Route path="/preview-bulk-template" element={<PreviewBulkTemplate />}></Route>
        <Route path="/create-so" element={<CreateSO />}></Route>       
        <Route path="/so-list" element={<SOList />}></Route>
        <Route path="/create-po" element={<CreatePO />}></Route>
        <Route exact path="/admin/dashboard" element={<AdminAuth><Dashboard {...props} /></AdminAuth>} />
      </Routes>
    </BrowserRouter>
  )
}

export default MyRoutes;