import { useEffect, useRef, useState } from "react";
import { CONSTANT } from "../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import LeftMenu from "../layouts/leftMenu";
import { Header } from "../layouts/header";
import { getAllBuyer, updateUserStatus } from "../../features/user_management/action";
import { resetUserManagememnt } from "../../features/user_management/slice";
import ReactPaginate from "react-paginate";
import editIcon from "../../assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import { SetPinModal } from "./setPinModal";


export const BuyerList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [itemCount, setItemCount] = useState(CONSTANT.PAGE_LIMIT);
  const [currentPage, setcurrentPage] = useState(0);
  const isFirstRender = useRef(true);
  const [buyerList, setbuyerList] = useState([]);
  const [ttlbuyer, setTtlbuyer] = useState(0);
  const [clickedUser, setClickedUser] = useState("");
  const [show, setShow] = useState(false);
  const usrMngmntResponse = useSelector((state) => state.userManagememnt);
  const userRoleId = localStorage.getItem('roleId');
  const [filters, setFilters] = useState({
    search: ""
  })

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    callAllBuyers();
  }, [filters])

  useEffect(() => {
    if (!isFirstRender.current) {
      callAllBuyers();
    }
    isFirstRender.current = false;
  }, [currentPage, itemCount]);

  const callAllBuyers = () => {
    dispatch(getAllBuyer({ page: currentPage + 1, limit: itemCount, filters: filters }));
  };

  useEffect(() => {
    if (
      usrMngmntResponse.actionName == "getAllBuyer" &&
      usrMngmntResponse.success
    ) {
      setbuyerList(usrMngmntResponse.buyers[0]);
      setTtlbuyer(usrMngmntResponse.buyers[1]);
      dispatch(resetUserManagememnt());
      if (usrMngmntResponse.buyers[1] !== null && pageCount === 0) {
        setPageCount(Math.ceil(usrMngmntResponse.buyers[1] / itemCount));
      }
    }
  }, [usrMngmntResponse]);

  // const buyerDetails = (buyerId) => {
  //     navigate("/buyer-info", {
  //         replace: true,
  //         state: { buyerId: buyerId },
  //     });
  // }

  const handlePageChange = (selectedObject) => {
    setcurrentPage(selectedObject.selected);
  };
  const handleItemsChange = (event) => {
    setItemCount(event.target.value);
    setcurrentPage(0);
  };
  const handleOpenEdit = (buyerId) => {
    navigate("/add-buyer", {
      replace: true,
      state: { buyerId: buyerId },
    });
  };
  const handleActive = (e, id) => {
    dispatch(updateUserStatus(id));
  };
  const handleShowResetPin = (userdata) => {
    setClickedUser(userdata);
    setShow(true);
  };

  return (
    <>
    
        <div className="search-top">
          <input type="search" name="search" onChange={handleChange} placeholder="Search by name" />
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Buyer name</th>
                <th>POC email</th>
                <th>Contact person groyyo</th>
                <th>Contact number</th>
                <th>GST number</th>
                <th>Nature of business</th>
                {parseInt(userRoleId) == 1 && <th scope="col">Pin</th>}
                {parseInt(userRoleId) == 1 && <th scope="col">Status (Active/Inactive)</th>}
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {buyerList.length > 0 &&
                buyerList.map((item, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <td>{item.buyerName} </td>
                    <td>{item.pocEmail}</td>
                    <td>{item.contactPersonGroyyo}</td>
                    <td>{item.contactNumber}</td>
                    <td>{item.gstNumber}</td>
                    <td>{item.natureOfBusiness}</td>
                    {parseInt(userRoleId) == 1 && <td>
                      {item.User.pin != "" && item.User.pin != null ? (
                        <a
                          className="reset-pin"
                          type="button"
                          onClick={() => {
                            handleShowResetPin(item.User);
                          }}
                        >
                          Reset pin
                        </a>
                      ) : (
                        <a
                          className="reset-pin"
                          type="button"
                          onClick={() => {
                            handleShowResetPin(item.User);
                          }}
                        >
                          Set pin
                        </a>
                      )}
                    </td>}
                    {parseInt(userRoleId) == 1 && <td>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          onChange={(event) => {
                            handleActive(event, item.User.id);
                          }}
                          defaultChecked={item.User.status == 1 ? true : false}
                          id={"active-state" + index}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"active-state" + index}
                        ></label>
                      </div>
                    </td>}
                    <td>
                      <button
                        type="button"
                        className="btn"
                        title="edit"
                        onClick={() => {
                          handleOpenEdit(item.id);
                        }}
                      >
                        <img src={editIcon} alt="edit" />
                      </button>
                    </td>
                  </tr>
                ))}
              {buyerList.length == 0 && (
                <tr>
                  <td colSpan={12} className="text-center">
                    No buyer Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {ttlbuyer > 10 && (
          <nav className="pagination-block">
            <p className="total">{ttlbuyer} items total</p>

            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              forcePage={currentPage}
              onPageChange={handlePageChange}
              containerClassName={"pagination justify-content-center mt-4"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
            <div className="customSelect">
              <label htmlFor="">Items per page :</label>
              <div className="selectBox">
                <select name="" id="" onChange={handleItemsChange}>
                  <option value={CONSTANT.PAGE_LIMIT}>
                    {CONSTANT.PAGE_LIMIT}
                  </option>
                  <option value="15">15</option>

                  <option value="20">20</option>
                </select>
              </div>
            </div>
          </nav>
          )}
        </div>
      <SetPinModal show={show} setShow={setShow} clickedUser={clickedUser} />
    </>
  );
};
