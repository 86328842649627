import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptOrRejectPoDates,
  addDatePoFactoryMilestones,
  addNewDates,
  getPoByIdAndRole,
  releasePo,
  setPoFactoryEditable,
  acceptAllPoMilestonesDates,
} from "../../../../features/po/action";
import { resetPo } from "../../../../features/po/slice";
import moment from "moment";
import { notify } from "../../../../features/notification/notify";
import { CONSTANT } from "../../../../utils/constant";
import { RejectPOModal } from "./rejectPOModal";
import { Accordion } from "react-bootstrap";
import { getDates, isShow } from "./poBasicInfoFunction";
import { confirmAlert } from "react-confirm-alert";
import { POInfoActionBtn } from "./poInfoActionBtn";

export const POBasicInfo = (props) => {
  const { poId, setPoGenerated } = props;
  const dispatch = useDispatch();
  const [poDetail, setPoDetail] = useState();
  const [factMilstn, setFactMilstn] = useState();
  const [showRelaseButton, setShowReleaseButton] = useState(true);
  const [showRejectPOModal, setShowRejectPOModal] = useState(false);
  const [selectedFactory, setSelectedFactory] = useState();
  const [selectedMilestone, setSelectedMilestone] = useState();
  const [rejectType, setRejectType] = useState();
  const [inputName, setInputName] = useState();
  const userId = localStorage.getItem("userId");
  const roleId = localStorage.getItem("roleId");

  useEffect(() => {
    if (poId) callPoDetails(poId);
  }, [poId]);

  const callPoDetails = (poId) => {
    let poPayload = {
      poId: poId,
      userId: userId,
      roleId: roleId,
    };
    dispatch(getPoByIdAndRole(poPayload));
  };

  const poResponse = useSelector((state) => state.po);

  useEffect(() => {
    if (poResponse.actionName == "getPoByIdAndRole" && poResponse.success) {
      setPoDetail(poResponse.poData.poDetail);
      setPoGenerated(poResponse?.poData?.poDetail?.status);
      setFactMilstn(poResponse.poData.factoryMilestone);
      dispatch(resetPo());
    }
    if (
      poResponse.actionName == "addDatePoFactoryMilestones" ||
      poResponse.actionName == "releasePo"
    ) {
      callPoDetails(poId);
      notify(poResponse.message, "success");
      dispatch(resetPo());
    }
    if (poResponse.actionName == "setPoFactoryEditable" && poResponse.success) {
      notify("Factory editable feature updated!", "success");
      dispatch(resetPo());
      callPoDetails(poId);
    }

    if (poResponse.actionName == "addNewDates" && poResponse.success) {
      notify("New dates suggested!", "success");
      callPoDetails(poId);
    }
    if (
      poResponse.actionName == "acceptOrRejectPoDates" &&
      poResponse.success
    ) {
      dispatch(resetPo());
      if (rejectType == "reject") notify(poResponse.message, "success");
      callPoDetails(poId);
    }
    if (
      poResponse.actionName == "acceptAllPoMilestonesDates" &&
      poResponse.success
    ) {
      
      dispatch(resetPo());
      notify(poResponse.message, "success");
      callPoDetails(poId);
    }
  }, [poResponse]);

  useEffect(() => {
    if (factMilstn) {
      if (poDetail.status !== 0) {
        setShowReleaseButton(false);
      } else {
        const hasEmptyDates = factMilstn.some(
          (poFactory) => !poFactory.startDate || !poFactory.endDate
        );

        if (hasEmptyDates) {
          setShowReleaseButton(false);
        } else {
          setShowReleaseButton(true);
        }
      }
    }
  }, [factMilstn]);

  // const handleDateChange = (e, data) => {
  //   let milestoneDatePayload = {};
  //   if (e.target.name == "startDate") {
  //     milestoneDatePayload = {
  //       startDate: e.target.value,
  //       endDate: "",
  //       factoryId: data.poFactoryId,
  //       milestoneId: data.milestoneId,
  //       poFacMilestone: data.id,
  //       createdBy: userId,
  //     };
  //   } else {
  //     milestoneDatePayload = {
  //       startDate: "",
  //       endDate: e.target.value,
  //       factoryId: data.poFactoryId,
  //       milestoneId: data.milestoneId,
  //       poFacMilestone: data.id,
  //       createdBy: userId,
  //     };
  //   }
  //   // console.log("milestoneDatePayload", milestoneDatePayload)
  //   dispatch(addDatePoFactoryMilestones(milestoneDatePayload));
  //   setShowReleaseButton(false);
  // };

  const handleReleasePO = () => {
    dispatch(releasePo({ poId: poId }));
  };

  const handleEditableChange = (e, poFact) => {
    let payload = {
      editable: e.target.checked,
      id: poFact.id,
    };
    dispatch(setPoFactoryEditable(payload));
  };

  const handleAcceptPO = (e, milstn) => {
    e.stopPropagation();
    let payload = {
      accepted: true,
      userId: userId,
      logId: milstn.poFactoryMilestoneLogs[0].id,
    };
    dispatch(acceptOrRejectPoDates(payload));
  };

  const handleDelete = (milstn, type, inpName) => {
    confirmAlert({
      title: "Confirm to Reject",
      message: "Are you sure want to reject this item?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            rejectPo(milstn, type, inpName);
          },
        },
        {
          label: "No",
          onClick: () => {
            if (document.getElementsByName(inpName)[0])
              document.getElementsByName(inpName)[0].checked = false;
          },
        },
      ],
    });
  };

  const handleRejectPO = (e, poFact, milstn, type) => {
    e.stopPropagation();
    setRejectType(type);
    setInputName(e.target.name);
    if (poFact.editable || roleId == CONSTANT.USER_ROLE.ADMIN) {
      setSelectedFactory(poFact);
      setSelectedMilestone(milstn);
      setShowRejectPOModal(true);
    } else {
      handleDelete(milstn, type, e.target.name);
    }
  };

  const closeRejectModal = () => {
    if (document.getElementsByName(inputName)[0])
      document.getElementsByName(inputName)[0].checked = false;
  };

  const rejectPo = (milstn, type) => {
    if (milstn.poFactoryMilestoneLogs.length > 0) {
      let payload = {
        accepted: false,
        userId: userId,
        logId: milstn.poFactoryMilestoneLogs[0].id,
      };
      dispatch(acceptOrRejectPoDates(payload));
    }
  };

  const acceptAll = (id) => {
    
    let data = {
      poFactoryId: id,
      userId: userId,
    }
    dispatch(acceptAllPoMilestonesDates(data))
  };

  return (
    poDetail && (
      <div
        className="tab-pane fade show active"
        id="basic-tab-pane"
        role="tabpanel"
        aria-labelledby="basic-tab"
        tabIndex="0"
      >
        <div className="row row-gap-2 mt-3">
          <div className="col-xxl-8 col-xl-9 col-lg-8 col-sm-12">
            <b className="dvrfaName">PO Name</b> :{" "}
            <b className="rfavalue">{poDetail?.poName} </b>
            <div className="row row-gap-2">
              <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                <label className="form-label">PO Name</label>
                <input
                  type="text"
                  readOnly
                  value={poDetail.poName}
                  className="form-control"
                />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                <label className="form-label">Description</label>
                <input
                  type="text"
                  readOnly
                  value={poDetail.description}
                  className="form-control"
                />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                <label className="form-label">RFQ Name</label>
                <input
                  type="text"
                  readOnly
                  value={poDetail.rfqDetail.rFQName}
                  className="form-control"
                />
              </div>

              <div className="col-xl-3 col-lg-3 col-md-4 col-6">
                <label className="form-label">PO created Date</label>
                <input
                  type="text"
                  readOnly
                  value={moment(poDetail.createdDate).format("YYYY-MM-DD")}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row row-gap-4 mt-5">
          <div className="col-xxl-8 col-xl-9 col-lg-8 col-sm-12">
            <b className="dvrfaName">TNA Template</b> :{" "}
            <b className="rfavalue">{poDetail.tnaTemplate.name} </b>
          </div>
        </div>

        <div className="row row-gap-4 mt-5">
          {poDetail.poFactories.map((poFactories, index) => (
            <div key={index}>
              <div className="row pb-3">
                <div className="col-md-3 col-xxl-3 col-xl-3 col-lg-4 col-sm-12">
                  <b className="dvrfaName">PO Factories</b> :{" "}
                  <b className="rfavalue">{poFactories.factory.name} </b>
                </div>
                {roleId == CONSTANT.USER_ROLE.ADMIN && (
                  <div className="col-md-3  col-xxl-3 col-xl-3 col-lg-4 col-sm-12 checkbox">
                    <b className="form-label b-all-fact pe-3">
                      Allow factory to propose new dates :
                    </b>
                    <input
                      onChange={(e) => handleEditableChange(e, poFactories)}
                      type="checkbox"
                      checked={poFactories.editable}
                      className="form-check-input"
                      value={poFactories.editable}
                    />
                  </div>
                )}
                {roleId == CONSTANT.USER_ROLE.ADMIN && (
                  <div className="col-md-3  col-xxl-3 col-xl-3 col-lg-4 col-sm-12 checkbox">
                    <button
                      className="btn ornge-btn"
                      type="button"
                      onClick={(e)=>acceptAll(poFactories.id)}
                    >
                      Accept all
                    </button>
                  </div>
                )}
              </div>

              <Accordion
                className="factory-milestone-list"
                defaultActiveKey="0"
              >
                {factMilstn
                  .filter((x) => x.poFactoryId == poFactories.id)
                  .map((factoryMilestones, index2) => (
                    <Accordion.Item
                      eventKey={index2}
                      key={index2}
                      className={
                        parseInt(factoryMilestones.sampleDetail.id) % 2 == 1
                          ? "dv-grouped " +
                            (factoryMilestones.poFactoryMilestoneLogs.length ==
                            0
                              ? "disabled"
                              : "")
                          : " " +
                            (factoryMilestones.poFactoryMilestoneLogs.length ==
                            0
                              ? "disabled"
                              : "")
                      }
                    >
                      <Accordion.Header>
                        <table>
                          <tbody>
                            <tr>
                              <td className="pe-5">
                                <label className="form-label">
                                  MileStone Name
                                </label>
                                <div className="form-control">
                                  {factoryMilestones.milestone.name}
                                </div>
                              </td>
                              <td className="pe-3">
                                <label className="form-label">Sample</label>
                                <div className="form-control">
                                  {factoryMilestones.sampleDetail.id
                                    ? factoryMilestones.sampleDetail.sampleName
                                    : ""}
                                </div>
                              </td>
                              <td className="pe-5">
                                <label className="form-label">Quantity</label>
                                <div className="form-control">
                                  {factoryMilestones.quantity
                                    ? factoryMilestones.quantity
                                    : ""}
                                </div>
                              </td>
                              <td className="pe-5">
                                <label className="form-label">Start Date</label>
                                <div className="form-control">
                                  {getDates(factoryMilestones, "start")}
                                </div>
                              </td>
                              <td className="pe-5">
                                <label className="form-label">End Date</label>
                                <div className="form-control ">
                                  {getDates(factoryMilestones, "end")}
                                </div>
                              </td>

                              <POInfoActionBtn
                                isShow={isShow}
                                handleRejectPO={handleRejectPO}
                                poFactories={poFactories}
                                factoryMilestones={factoryMilestones}
                                handleAcceptPO={handleAcceptPO}
                                index2={index2}
                                index={index}
                              />
                            </tr>
                          </tbody>
                        </table>

                        {/* // {!(factoryMilestones.startDate && factoryMilestones.endDate) && roleId == CONSTANT.USER_ROLE.ADMIN && factoryMilestones.poFactoryMilestoneLogs?.length == 0 && */}

                        {/* {roleId == CONSTANT.USER_ROLE.ADMIN && !(factoryMilestones.startDate && factoryMilestones.endDate) && factoryMilestones.poFactoryMilestoneLogs?.length > 0 && !(factoryMilestones.poFactoryMilestoneLogs[factoryMilestones.poFactoryMilestoneLogs?.length - 1]?.isAccepted == null) && !factoryMilestones.poFactoryMilestoneLogs[factoryMilestones.poFactoryMilestoneLogs?.length - 1]?.reason && */}

                        {/* {!(factoryMilestones.startDate && factoryMilestones.endDate) && roleId == CONSTANT.USER_ROLE.ADMIN && factoryMilestones.poFactoryMilestoneLogs?.length > 0 && !(factoryMilestones.poFactoryMilestoneLogs[factoryMilestones.poFactoryMilestoneLogs?.length - 1]?.isAccepted == null) && !factoryMilestones.poFactoryMilestoneLogs[factoryMilestones.poFactoryMilestoneLogs?.length - 1]?.reason && */}

                        {/* {!(factoryMilestones.startDate && factoryMilestones.endDate) && (roleId == CONSTANT.USER_ROLE.ADMIN) && factoryMilestones.poFactoryMilestoneLogs?.length > 0 && (factoryMilestones.poFactoryMilestoneLogs[factoryMilestones.poFactoryMilestoneLogs?.length-1]?.isAccepted == false) && */}

                        {/* {!(factoryMilestones.startDate && factoryMilestones.endDate) && (roleId == CONSTANT.USER_ROLE.ADMIN) && factoryMilestones.poFactoryMilestoneLogs?.length > 0 && (factoryMilestones.poFactoryMilestoneLogs[factoryMilestones.poFactoryMilestoneLogs?.length-1]?.isAccepted == false) && */}

                        {/* -------start FACTORY show button ---------- */}

                        {/* {!(factoryMilestones.startDate && factoryMilestones.endDate) && (roleId != CONSTANT.USER_ROLE.ADMIN) && factoryMilestones.poFactoryMilestoneLogs?.length > 0 && (factoryMilestones.poFactoryMilestoneLogs[factoryMilestones.poFactoryMilestoneLogs?.length-1]?.isAccepted != null && factoryMilestones.poFactoryMilestoneLogs[factoryMilestones.poFactoryMilestoneLogs?.length - 1]?.isAccepted == false) && */}

                        {/* {!(factoryMilestones.startDate && factoryMilestones.endDate) && (roleId != CONSTANT.USER_ROLE.ADMIN) && factoryMilestones.poFactoryMilestoneLogs?.length > 0 && (factoryMilestones.poFactoryMilestoneLogs[factoryMilestones.poFactoryMilestoneLogs?.length - 1]?.isAccepted != null && factoryMilestones.poFactoryMilestoneLogs[factoryMilestones.poFactoryMilestoneLogs?.length -1]?.isAccepted == false) && */}

                        {/* -------start FACTORY show button ---------- */}
                      </Accordion.Header>
                      <Accordion.Body style={{ background: "#f8f9fa" }}>
                        {/* -------start ADMIN show button ---------- */}
                        <table className="mLog_Table">
                          <tbody>
                            {roleId == CONSTANT.USER_ROLE.ADMIN &&
                              factoryMilestones.poFactoryMilestoneLogs.map(
                                (poFacMileLogs, index) => (
                                  <tr key={index}>
                                    <td className="pe-5 td-accrdn"></td>
                                    {poFacMileLogs.createdUser?.Roles[0]
                                      ?.roleType ? (
                                      <td className="pe-5">
                                        <label className="form-label">
                                          Proposed By
                                        </label>
                                        <p className="form-control">
                                          {poFacMileLogs.createdUser?.name
                                            ? poFacMileLogs.createdUser?.name
                                            : poFacMileLogs.createdUser
                                                ?.emailId}
                                        </p>
                                      </td>
                                    ) : (
                                      <td className="pe-5"></td>
                                    )}
                                    <td className="pe-5">
                                      <label className="form-label">
                                        Start Date
                                      </label>
                                      <p className="form-control">
                                        {moment(poFacMileLogs.startDate).format(
                                          "DD-MM-yyyy"
                                        )}
                                      </p>
                                    </td>
                                    <td className="pe-5">
                                      <label className="form-label">
                                        End Date
                                      </label>
                                      <p className="form-control">
                                        {moment(poFacMileLogs.endDate).format(
                                          "DD-MM-yyyy"
                                        )}
                                      </p>
                                    </td>
                                    {poFacMileLogs.reason && (
                                      <td className="pe-5">
                                        <label className="form-label">
                                          Reason
                                        </label>
                                        <p className="form-control">
                                          {poFacMileLogs.reason}
                                        </p>
                                      </td>
                                    )}
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>

                        {/* -------END ADMIN show button ---------- */}

                        {/*------------------------------------------------------------------------------------------------------- */}

                        {/* -------start FACTORY show button ---------- */}
                        <table className="mLog_Table">
                          <tbody>
                            {roleId != CONSTANT.USER_ROLE.ADMIN &&
                              factoryMilestones.poFactoryMilestoneLogs.map(
                                (poFacMileLogs, index) => (
                                  <tr key={index}>
                                    <td className="pe-5 td-accrdn"></td>
                                    {poFacMileLogs.createdUser?.Roles[0]
                                      ?.roleType ? (
                                      <td className="pe-5">
                                        <label className="form-label">
                                          Proposed By
                                        </label>
                                        <p className="form-control">
                                          {poFacMileLogs.createdUser?.Roles[0]
                                            ?.roleType == "Internal"
                                            ? "Groyyo Team's"
                                            : "Factory"}
                                        </p>
                                      </td>
                                    ) : (
                                      <td className="pe-5"></td>
                                    )}
                                    <td className="pe-5">
                                      <label className="form-label">
                                        Start Date
                                      </label>
                                      <p className="form-control">
                                        {moment(poFacMileLogs.startDate).format(
                                          "DD-MM-yyyy"
                                        )}
                                      </p>
                                    </td>
                                    <td className="pe-5">
                                      <label className="form-label">
                                        End Date
                                      </label>
                                      <p className="form-control">
                                        {moment(poFacMileLogs.endDate).format(
                                          "DD-MM-yyyy"
                                        )}
                                      </p>
                                    </td>
                                    {poFacMileLogs.reason && (
                                      <td className="pe-5">
                                        <label className="form-label">
                                          Reason
                                        </label>
                                        <p className="form-control">
                                          {poFacMileLogs.reason}
                                        </p>
                                      </td>
                                    )}
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                        {/* -------END FACTORY show button ---------- */}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </div>
          ))}
          {showRelaseButton && roleId != CONSTANT.USER_ROLE.SUPPLIER && (
            <div className="mt-3">
              <button
                className="btn ornge-brdr-btn float-end"
                onClick={handleReleasePO}
              >
                Release PO
              </button>
            </div>
          )}
        </div>
        <RejectPOModal
          closeRejectModal={closeRejectModal}
          roleId={roleId}
          rejectPo={rejectPo}
          userId={userId}
          getDates={getDates}
          showRejectPOModal={showRejectPOModal}
          setShowRejectPOModal={setShowRejectPOModal}
          selectedFactory={selectedFactory}
          selectedMilestone={selectedMilestone}
        ></RejectPOModal>
      </div>
    )
  );
};
